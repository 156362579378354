import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function InfiniteScrollList({ uiScreen, dataFetcher = () => { }, options = [], optionSelectHandler = () => { }, value = '', label = 'Select' }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [lists, setLists] = useState([]);
  const observer = useRef();

  const loadMoreData = useCallback(async () => {
    setLoading(true); // Set loading state to true
    try {
      const limit = 20; // Number of items to fetch per request
      const skip = page * limit; // Calculate how many items to skip based on current page
      const res = await dispatch(dataFetcher({ skip, limit })).unwrap(); // Fetch data

      // Determine the new posts based on the UI screen state
      const newPosts = uiScreen ? res?.fleetDetails : res?.data;

      // If no new posts, update the hasMore state to false
      if (newPosts?.length === 0) {
        setHasMore(false);
      }
    } catch (err) {
      // Handle error if necessary (e.g., show an error message)
    } finally {
      setLoading(false); // Reset loading state
    }
  }, [dispatch, page]); // Dependencies for useCallback

  // Effect to load more data when there are more items available
  useEffect(() => {
    if (hasMore) {
      loadMoreData(); // Load more data if hasMore is true
    }
  }, [loadMoreData, hasMore]); // Dependencies for useEffect

  // Sets up an Intersection Observer to detect when the last post element is in view.
   
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading || !hasMore) return; // If loading or no more data, do not set up the observer
      if (observer.current) observer.current.disconnect(); // Disconnect previous observer

      // Create a new Intersection Observer
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1); // Increment page to load more data
        }
      });

      // Observe the last post element if it exists
      if (node) observer.current.observe(node);
    },
    [loading, hasMore] // Dependencies for useCallback
  );

  // Effect to update the lists whenever options change
  useEffect(() => {
    setLists([...lists, ...options]); // Append new options to the existing lists
  }, [JSON.stringify(options)]); // Dependencies for useEffect

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => optionSelectHandler(e.target.value)}
        className='form-select'
        MenuProps={{
          style: {
            height: 400
          }
        }}
        label={label}
      >
        {
          lists?.length > 0 && lists?.map(((option, idx) => {
            return <MenuItem
              value={option?.value}
              key={idx + 1}
              ref={lists.length === idx + 1 ? lastPostElementRef : null}
            >
              {  option?.label   }
            </MenuItem>;
          }))
        }
      </Select>
    </FormControl>
  );
}