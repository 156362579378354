import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

export default function DeclineDriverRequestReason({ closeModal }) {
  const { modalData } = useSelector(state => state.modal);
  const { isLoading: loading = false } = useSelector(state => state.driverApprovalSlice);
  const [declineApprovalReason, setDeclineApprovalReason] = useState('');
  const [errors, setErrors] = useState({});
  const { approvalStatusHandler, resetData } = modalData || {};

  const submitHandler = () => {
    if (!declineApprovalReason) {
      setErrors({ declineApprovalReason: 'Reason is required' });
      return;
    }
    setErrors({});
    approvalStatusHandler(declineApprovalReason);
    resetData();
  };

  return (
    <>
      <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'>
        Decline Reason
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box pt={1}>
          <TextField fullWidth className='form-input' multiline rows={3}
            error={Boolean(errors?.declineApprovalReason)}
            helperText={errors?.declineApprovalReason}
            onChange={(e) => setDeclineApprovalReason(e.target.value)} label={'Decline Reason'} name='declineReason' value={declineApprovalReason}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={closeModal} size='small' color='secondary'>
          Cancel
        </MDButton>
        <MDButton onClick={submitHandler} size='small' color='primary'>
          {
            loading ? <CircularProgress size={16} color='light' /> : 'Save'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}