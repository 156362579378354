import { Box, Stack, Typography } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sortAndFilter } from 'components/tables/AgGridTable';
import { formatDate } from 'utilities/common';
import { fetchOrdersLogs } from '../../redux/slices/orders';
import { formatTimestampToDate24Hours } from 'utilities/common';

export default function OrderLogs() {
  const dispatch = useDispatch();
  const { loading = false, logsDetails = {} } = useSelector(state => state.orders);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const pageRef = useRef(0);

  const { orderId = '' } = useParams()

  const columnDefs = [
    {
      headerName: 'Date/Time',
      field: 'createdDate',
      minWidth: 220,
      cellRenderer: useCallback((props) => (
        props?.value ? formatTimestampToDate24Hours(props?.value) : '-'
      ), []),
      flex: 1.3,
    },
    {
      headerName: 'Updated By',
      field: 'updatedBy',
      key: 'fullName',
      suppressMenu: false,
      minWidth: 220,
    },
    {
      headerName: 'Order Id',
      field: 'newChanges',
      suppressMenu: false,
      cellRenderer: (props) => {
        return props?.value?.orderId ? props?.value?.orderId : '';
      },
      minWidth: 220
    },
    {
      headerName: 'Source',
      field: 'source',
      suppressMenu: false,
      minWidth: 220,
    },
    {
      headerName: 'Refund Value',
      field: 'newChanges',
      suppressMenu: false,
      cellRenderer: (props) => {
        return props?.value?.refundValue ? `$${props?.value?.refundValue}` : '';
      },
      minWidth: 220,
      headerClass: 'left-aligned-cell-header',
      // cellStyle: { textAlign: 'right', justifyContent: 'end' },
    },
  ];

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchOrdersLogs({ orderId: orderId, skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current })).unwrap().then(res => {
      const { logs, total } = res;
      const inventoryArrays = logs?.map((item => {
        return { ...item, id: item._id, createdDate: item?.createdDate ? formatDate(new Date(item?.createdDate)) : '-' };
      }));
      const dataAfterSortingAndFiltering = sortAndFilter(
        inventoryArrays,
        params.sortModel,
        params.filterModel
      );
      params.successCallback(dataAfterSortingAndFiltering, total);
      pageRef.current = pageRef.current + 20;
    });
  };
  
  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (logsDetails?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [logsDetails, loading]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
      <Stack direction="row" justifyContent={{ xs: 'start', sm: 'start' }} flexWrap="wrap" alignItems="stretch" gap={2} mb={2}>
        <Typography variant='h4'>Refund Logs</Typography>
      </Stack>
      <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: '70vh' }} />
    </Box>
  );
}