import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addAnnouncementApi, addFailureReasonApi, deleteAnnouncementsApi, deleteFailureReasonApi, editAnnouncementApi, editFailureReasonApi, getAnnouncementDetailsApi, getAnnouncementsApi, getChecklistApi, getCheckoutlistApi, getFailureReasonApi, getFailureReasonDetailsApi, getTransportAPI, updateCheckListStatusAPI, updateCheckOutListStatusAPI, updateTransportStatusAPI } from '../../apis/hyperdrive/setting';

const initialState = {
  transport: [],
  loading: false,
  error: null,
  flavors: [],
  getTransportation: {}
};

export const getTransportation = createAsyncThunk(
  'common/getTransportation',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getTransportAPI(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateTransportStatus = createAsyncThunk(
  'common/updateTransportStatus',
  async ({ data, transportId }, { rejectWithValue }) => {
    try {
      const res = await updateTransportStatusAPI(data, transportId);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getChecklist = createAsyncThunk(
  'common/getChecklist',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getChecklistApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCheckoutlist = createAsyncThunk(
  'common/getCheckoutlist',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getCheckoutlistApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const updateCheckListStatus = createAsyncThunk(
  'common/updateCheckListStatus',
  async ({ data, checklistId }, { rejectWithValue }) => {
    try {
      const res = await updateCheckListStatusAPI(data, checklistId);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateCheckOutListStatus = createAsyncThunk(
  'common/updateCheckOutListStatus',
  async ({ data, checklistId }, { rejectWithValue }) => {
    try {
      const res = await updateCheckOutListStatusAPI(data, checklistId);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const getFailureReason = createAsyncThunk(
  'common/getFailureReason',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getFailureReasonApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFailureReasonDetails = createAsyncThunk(
  'common/getFailureReasonDetails',
  async (reasonId, { rejectWithValue }) => {
    try {
      const res = await getFailureReasonDetailsApi(reasonId)
      return res;
    }
    catch (error) {
      return rejectWithValue(error.response?.data)
    }
  }
)

export const addFailureReason = createAsyncThunk(
  'common/addFailureReason',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await addFailureReasonApi(data);
      return res
    }
    catch (error) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)
export const editFailureReason = createAsyncThunk(
  'common/editFailureReason',
  async ({ data, reasonId }, { rejectWithValue }) => {
    try {
      const res = await editFailureReasonApi(data, reasonId);
      return res
    }
    catch (error) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)

export const deleteFailureReason = createAsyncThunk(
  'common/deleteFailureReason',
  async (deleteFailureReason, { rejectWithValue }) => {
    try {
      const res = await deleteFailureReasonApi(deleteFailureReason);
      return res
    }
    catch (error) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)

export const getAnnouncements = createAsyncThunk(
  'common/getAnnouncements',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getAnnouncementsApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAnnouncementDetails = createAsyncThunk(
  'common/getAnnouncementDetails',
  async (reasonId, { rejectWithValue }) => {
    try {
      const res = await getAnnouncementDetailsApi(reasonId)
      return res;
    }
    catch (error) {
      return rejectWithValue(error.response?.data)
    }
  }
)

export const addAnnouncement = createAsyncThunk(
  'common/addAnnouncement',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await addAnnouncementApi(data);
      return res
    }
    catch (error) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)

export const editAnnouncement = createAsyncThunk(
  'common/editAnnouncement',
  async ({ data, announcementId }, { rejectWithValue }) => {
    try {
      const res = await editAnnouncementApi(data, announcementId);
      return res
    }
    catch (error) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)

export const deleteAnnouncements = createAsyncThunk(
  'common/deleteAnnouncements',
  async (deleteAnnouncements, { rejectWithValue }) => {
    try {
      const res = await deleteAnnouncementsApi(deleteAnnouncements);
      return res
    }
    catch (error) {
      return rejectWithValue(error?.response?.data || error.message)
    }
  }
)

const commonDriveSlice = createSlice({
  name: 'commonHyperDrive',
  initialState,
  reducers: {
    setCommonHyperdrive: (state, action) => {
      return { ...state, singleDrive: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransportation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransportation.fulfilled, (state, action) => {
        state.loading = false;
        state.mainTransportData = action.payload;
      })
      .addCase(getTransportation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(updateTransportStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTransportStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.transportUpdateData = action.payload;
      })
      .addCase(updateTransportStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(getChecklist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getChecklist.fulfilled, (state, action) => {
        state.loading = false;
        state.mainChecklistData = action.payload;
      })
      .addCase(getChecklist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(updateCheckListStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCheckListStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.transportUpdateData = action.payload;
      })
      .addCase(updateCheckListStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(getFailureReason.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFailureReason.fulfilled, (state, action) => {
        state.loading = false;
        state.mainFailureReasonData = action.payload;
      })
      .addCase(getFailureReason.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(addFailureReason.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addFailureReason.fulfilled, (state, action) => {
        state.loading = false;
        state.addMainFailureReasonData = action.payload;
      })
      .addCase(addFailureReason.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(getFailureReasonDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFailureReasonDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.mainFailureReasonDetailsData = action.payload;
      })
      .addCase(getFailureReasonDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(editFailureReason.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editFailureReason.fulfilled, (state, action) => {
        state.loading = false;
        state.mainEditFailureReasonData = action.payload;
      })
      .addCase(editFailureReason.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(deleteFailureReason.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFailureReason.fulfilled, (state, action) => {
        state.loading = false;
        state.mainDeleteFailureReasonData = action.payload;
      })
      .addCase(deleteFailureReason.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(getAnnouncements.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        state.mainAnnouncementsData = action.payload;
      })
      .addCase(getAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      builder
      .addCase(getAnnouncementDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnnouncementDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.mainGetAnnouncementsData = action.payload;
      })
      .addCase(getAnnouncementDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(addAnnouncement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.mainAddAnnouncementData = action.payload;
      })
      .addCase(addAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(editAnnouncement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.mainEditAnnouncementData = action.payload;
      })
      .addCase(editAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
    builder
      .addCase(deleteAnnouncements.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        state.mainDeleteAnnouncementData = action.payload;
      })
      .addCase(deleteAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      builder
      .addCase(getCheckoutlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCheckoutlist.fulfilled, (state, action) => {
        state.loading = false;
        state.getCheckoutlistData = action.payload;
      })
      .addCase(getCheckoutlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      builder
      .addCase(updateCheckOutListStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCheckOutListStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateCheckOutListStatusData = action.payload;
      })
      .addCase(updateCheckOutListStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});


export const { singleDrive } = commonDriveSlice.actions;

export default commonDriveSlice.reducer;