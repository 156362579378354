// import { configureStore } from '@reduxjs/toolkit'
// import msalReducer from './slices/authSlice';
// import { materialUIReducer } from './slices/materialUISlice';
// import { modalReducer } from './slices/modalSlice';
// import membersSlice from './slices/member';
// import productsSlice from './slices/products';
// import commonSlice from './slices/common';
// import brandSlice from './slices/brand';
// import regionsSlice from './slices/regions';
// import categorySlice from './slices/category';
// import inventorySlice from './slices/inventory';
// import ordersSlice from './slices/orders';
// import terpenoidsSlice from './slices/terpenoids';
// import dashboardSlice from './slices/dashboard';
// import strainSlice from './slices/strain';
// import adminSlice from './slices/admin';
// import faqSlice from './slices/faq';
// import promotionSlice from './slices/promotions'
// import membershipsSlice from './slices/memberships'
// import roleAndPermissionsSlice from './slices/roleAndPermissions'
// import retailerSlice from './slices/retailerSlice';
// import retailerProductSlice from './slices/retailerProductSlice';
// import masterCatalogProductsSlice from './slices/masterCatalogSlice';
// import retailerMembersSlice from './slices/retailer/member';
// import approvals from './slices/approvals';
// import notifications from './slices/notifications';
// import adminDriversHemp from './slices/driverHemp';
// import breaksSliceHemp  from './slices/breaks'
// import hyperwolfProductsReducers from './slices/hyperwolf/products';
// import employeeSliceSlice from './slices/hyperwolf/employee';
// import hyperwolfAutheSlice from './slices/hyperwolf/auth';
// import hyperwolfStrainsSlice from './slices/hyperwolf/strains';
// import hyperwolfBannersSlice from './slices/hyperwolf/banners';
// import hyperwolfUserSlice from './slices/hyperwolf/users';
// import cannabinoidSlice from './slices/cannabinoids'
// import driversSlice from './slices/hyperwolf/driver/driver';
// import breaks from './slices/hyperwolf/driver/breaks';
// import driverApprovalSlice from './slices/hyperwolf/driver/approvals';
// import commonHyperDrive from './slices/hyperdrive/setting'
// import driverHyperDrive from './slices/hyperdrive/driverAnalytics'
// import taskHyperDrive from './slices/hyperdrive/createTask'
// import driverSlice from './slices/hyperdrive/drivers'
// import adminDrivers from './slices/hyperwolf/driver/driver'
// import analyzeSlice from './slices/hyperdrive/analyze'
// import hyperwolfOrderSlice from './slices/hyperwolf/orders'
// import hyperwolfMemberSlice from './slices/hyperwolf/members'
// import hyperwolfSettings from './slices/hyperwolf/settings'


// const hyperwolfStoreReducers = {
//     hyperwolfProducts: hyperwolfProductsReducers,
//     employee: employeeSliceSlice,
//     hyperwolfStrains: hyperwolfStrainsSlice,
//     hyperwolfAuth: hyperwolfAutheSlice,
//     hyperwolfBanners: hyperwolfBannersSlice,
//     hyperwolfUser: hyperwolfUserSlice,
//     cannabinoids:cannabinoidSlice,
//     regions: regionsSlice,
//     adminDrivers: adminDrivers,
//     hyperwolfOrderSlice: hyperwolfOrderSlice,
//     hyperwolfMemberSlice: hyperwolfMemberSlice,
//     hyperwolfSettings: hyperwolfSettings,
// }

// const hyperdriveReducers = {
//     drivers:driverSlice,
//     analyze:analyzeSlice
// }


// export const store = configureStore({
//     reducer: {
//         auth: msalReducer,
//         materialUI: materialUIReducer,
//         modal: modalReducer,
//         members: membersSlice,
//         products: productsSlice,
//         common: commonSlice,
//         brands: brandSlice,
//         regions: regionsSlice,
//         category: categorySlice,
//         inventory: inventorySlice,
//         orders: ordersSlice,
//         dashboard: dashboardSlice,
//         terpenoids: terpenoidsSlice,
//         strain: strainSlice,
//         admin: adminSlice,
//         mainFAQ: faqSlice,
//         promotion: promotionSlice,
//         memberships: membershipsSlice,
//         roleAndPermissions: roleAndPermissionsSlice,
//         retailers: retailerSlice,
//         retailerProducts: retailerProductSlice,
//         masterCatalogProducts: masterCatalogProductsSlice,
//         retailerMembersSlice: retailerMembersSlice,
//         approvalSlice: approvals,
//         notifications: notifications,
//         drivers: driversSlice,
//         breaks: breaks,
//         driverApprovalSlice: driverApprovalSlice,
//         commonHyperdrive:commonHyperDrive,
//         driverAnalyticsHyperDrive:driverHyperDrive,
//         taskHyperDrive:taskHyperDrive,
//         adminDriversHemp: adminDriversHemp,
//         breaksSliceHemp : breaksSliceHemp,
//         // hyperwolf store reducers 
//         ...hyperwolfStoreReducers,
//         ...hyperdriveReducers
//     }

// });


import { configureStore } from '@reduxjs/toolkit';
import msalReducer from './slices/authSlice';
import { materialUIReducer } from './slices/materialUISlice';
import { modalReducer } from './slices/modalSlice';
import membersSlice from './slices/member';
import productsSlice from './slices/products';
import commonSlice from './slices/common';
import brandSlice from './slices/brand';
import regionsSlice from './slices/regions';
import categorySlice from './slices/category';
import inventorySlice from './slices/inventory';
import ordersSlice from './slices/orders';
import terpenoidsSlice from './slices/terpenoids';
import dashboardSlice from './slices/dashboard';
import strainSlice from './slices/strain';
import adminSlice from './slices/admin';
import faqSlice from './slices/faq';
import promotionSlice from './slices/promotions';
import membershipsSlice from './slices/memberships';
import roleAndPermissionsSlice from './slices/roleAndPermissions';
import retailerSlice from './slices/retailerSlice';
import retailerProductSlice from './slices/retailerProductSlice';
import masterCatalogProductsSlice from './slices/masterCatalogSlice';
import retailerMembersSlice from './slices/retailer/member';
import approvals from './slices/approvals';
import notifications from './slices/notifications';
import adminDriversHemp from './slices/driverHemp';
import breaksSliceHemp from './slices/breaks';
import hyperwolfProductsReducers from './slices/hyperwolf/products';
import employeeSliceSlice from './slices/hyperwolf/employee';
import hyperwolfAutheSlice from './slices/hyperwolf/auth';
import hyperwolfStrainsSlice from './slices/hyperwolf/strains';
import hyperwolfBannersSlice from './slices/hyperwolf/banners';
import hyperwolfUserSlice from './slices/hyperwolf/users';
import cannabinoidSlice from './slices/cannabinoids';
import driversSlice from './slices/hyperwolf/driver/driver';
import breaks from './slices/hyperwolf/driver/breaks';
import driverApprovalSlice from './slices/hyperwolf/driver/approvals';
import commonHyperDrive from './slices/hyperdrive/setting';
import driverHyperDrive from './slices/hyperdrive/driverAnalytics';
import taskHyperDrive from './slices/hyperdrive/createTask';
import driverSlice from './slices/hyperdrive/drivers';
import adminDrivers from './slices/hyperwolf/driver/driver';
import analyzeSlice from './slices/hyperdrive/analyze';
import hyperwolfOrderSlice from './slices/hyperwolf/orders';
import hyperwolfMemberSlice from './slices/hyperwolf/members';
import hyperwolfSettings from './slices/hyperwolf/settings';
import { userApi } from 'Rtk/Services/user';

// ** Importing the `userApi`** from your Services folder (or wherever it's defined)

const hyperwolfStoreReducers = {
    hyperwolfProducts: hyperwolfProductsReducers,
    employee: employeeSliceSlice,
    hyperwolfStrains: hyperwolfStrainsSlice,
    hyperwolfAuth: hyperwolfAutheSlice,
    hyperwolfBanners: hyperwolfBannersSlice,
    hyperwolfUser: hyperwolfUserSlice,
    cannabinoids: cannabinoidSlice,
    regions: regionsSlice,
    adminDrivers: adminDrivers,
    hyperwolfOrderSlice: hyperwolfOrderSlice,
    hyperwolfMemberSlice: hyperwolfMemberSlice,
    hyperwolfSettings: hyperwolfSettings,
};

const hyperdriveReducers = {
    drivers: driverSlice,
    analyze: analyzeSlice,
};

// ** Combine all your reducers, including the `userApi` reducer**
const rootReducer = {
    auth: msalReducer,
    materialUI: materialUIReducer,
    modal: modalReducer,
    members: membersSlice,
    products: productsSlice,
    common: commonSlice,
    brands: brandSlice,
    regions: regionsSlice,
    category: categorySlice,
    inventory: inventorySlice,
    orders: ordersSlice,
    dashboard: dashboardSlice,
    terpenoids: terpenoidsSlice,
    strain: strainSlice,
    admin: adminSlice,
    mainFAQ: faqSlice,
    promotion: promotionSlice,
    memberships: membershipsSlice,
    roleAndPermissions: roleAndPermissionsSlice,
    retailers: retailerSlice,
    retailerProducts: retailerProductSlice,
    masterCatalogProducts: masterCatalogProductsSlice,
    retailerMembersSlice: retailerMembersSlice,
    approvalSlice: approvals,
    notifications: notifications,
    drivers: driversSlice,
    breaks: breaks,
    driverApprovalSlice: driverApprovalSlice,
    commonHyperdrive: commonHyperDrive,
    driverAnalyticsHyperDrive: driverHyperDrive,
    taskHyperDrive: taskHyperDrive,
    adminDriversHemp: adminDriversHemp,
    breaksSliceHemp: breaksSliceHemp,
    // hyperwolf store reducers
    ...hyperwolfStoreReducers,
    ...hyperdriveReducers,
    // ** Add the `userApi` reducer here**
    [userApi.reducerPath]: userApi.reducer,  // This is the added API reducer
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(userApi.middleware),  // This is the added API middleware
});
