
// @mui material components
import Grid from '@mui/material/Grid';
import { Box, Card, CardContent, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import DotIndicatorIcon from 'common/customIcons/dot_indicator';
import { DoughnutChart } from 'common/charts/DoghnutChart';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { totalProductsSoldStats, totalSoldCategories } from '../../redux/slices/dashboard';
import { isEmptyObject } from 'utilities/common';
import FilterMenu from 'common/FilterMenu';
import { FilterAltOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

let colors = ['#24CA49', '#FE7E07', '#01CAA6', '#A24FFC', '#F3A557', '#ED63F7', '#838FED', '#64C9F3'];

const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
      display: false
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#fdfdfd',
      bodyColor: '#16161E',
      titleColor: '#16161E',
      borderWidth: 1,
      borderColor: '#F1F1F1',
      padding: 8
    },
  },
};


export default function ProductsAndCategoriesSoldStats() {
  const dashboardData = useSelector(state => state.dashboard);
  const { totalSoldCategoriesData = {} } = dashboardData || {};
  const dispatch = useDispatch();
  const [topProductFilter, setTopProductFilter] = useState({
    topProductWeekMonthToday: { dashboardQuery: 'month' },
    topCategoriesWeekMonthToday: { dashboardQuery: 'month' },
    topProductPageCount: 1
  });

  const [topProductFilterOpen, setTopProductFilterOpen] = useState(false);
  const [soldProductFilterOpen, SetsoldProductFilterOpen] = useState(false);

  const memoizetotalSoldCategoriesData = useMemo(() => {
    return totalSoldCategoriesData?.categoriesSold?.[0]?.webCategoryTotals;
  }, [totalSoldCategoriesData]);

  const topProductSoldData = useMemo(() => {
    let data = {
      labels: ['Child Mode', 'Pain Reli', 'Sleepy', 'Elevated', 'Munchies', 'Child Mode', 'Pain Reli', 'Sleepy', 'Elevated', 'Munchies'],
      datasets: [
        {
          // label: '# of Votes',
          data: [],
          backgroundColor: '#FE7E07',
          borderColor: '#FE7E07',
          borderWidth: 0,
          borderRadius: 4,

        },
      ],
    };
    data.labels = dashboardData?.totalProductsSoldStatsData?.topSellingProducts?.map((product => product?.productName));
    data.datasets[0].data = dashboardData?.totalProductsSoldStatsData?.topSellingProducts?.map((product => product?.qty));

    return data;
  }, [dashboardData?.totalProductsSoldStatsData?.topSellingProducts]);


  const topCategoriesSoldChartData = useMemo(() => {
    let data = {
      labels: ['Flower', 'Vapes', 'Concentrates', 'Edibles', 'Prerolls', 'Wellness'],
      datasets: [
        {
          data: [],
          borderWidth: 1,
          backgroundColor: colors,
          fill: true,
          cutout: '90%',
        },
      ],
    };
    if (!isEmptyObject(totalSoldCategoriesData?.categoriesSold?.[0])) {
      data.labels = Object.keys(totalSoldCategoriesData?.categoriesSold?.[0]?.webCategoryTotals)?.map(((soldCategoryKey) => {
        const label = soldCategoryKey?.trim();
        return label;
      }));
      data.datasets[0].data = Object.keys(totalSoldCategoriesData?.categoriesSold?.[0]?.webCategoryTotals)?.map(((soldCategoryKey) => {
        const value = totalSoldCategoriesData?.categoriesSold?.[0]?.webCategoryTotals[soldCategoryKey];
        return value;
      }));
    }
    return data;
  }, [totalSoldCategoriesData]);

  const productInnerContent = useMemo(() => {
    return <Paper sx={{
      width: 150, // Set the width of the circle
      height: 150, // Set the height of the circle
      borderRadius: '50%', // Make it a circle by setting borderRadius to 50%
      backgroundColor: '#ffffff',
      boxShadow: '0px 0px 2.214px rgba(138, 154, 197, 0.02), 0px 0px 5.32px rgba(138, 154, 197, 0.03), 0px 0px 10.017px rgba(138, 154, 197, 0.04), 0px 0px 17.869px rgba(138, 154, 197, 0.04), 0px 0px 33.422px rgba(138, 154, 197, 0.05), 0px 0px 80px rgba(138, 154, 197, 0.07)', // Add a shadow with color rgba(0, 0, 0, 0.5)
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
        <Typography variant='h5' lineHeight={1.5} mb={.5}>{totalSoldCategoriesData?.total || 0}</Typography>
        <Typography variant='caption' fontWeight='medium' color='secondary' lineHeight={1.5}>Total Product</Typography>
        <Typography variant='caption' fontWeight='medium' color='secondary' lineHeight={1.5}>Categories Sold  {topProductFilter?.topCategoriesWeekMonthToday?.dashboardQuery &&  topProductFilter?.topCategoriesWeekMonthToday?.dashboardQuery !== 'today' && 'in'} </Typography>
        <Typography variant='caption' fontWeight='medium' color='secondary' lineHeight={1.5}> {topProductFilter?.topCategoriesWeekMonthToday?.dashboardQuery ?  topProductFilter?.topCategoriesWeekMonthToday?.dashboardQuery === 'today' ? 'today' : `this ${topProductFilter?.topCategoriesWeekMonthToday?.dashboardQuery}` :  topProductFilter?.topCategoriesWeekMonthToday?.startDate && topProductFilter?.topCategoriesWeekMonthToday?.endDate   ? <>
        From {' '}
        {dayjs(topProductFilter?.topCategoriesWeekMonthToday?.startDate)?.format('DD MMM, YYYY')}  to <br/>  {dayjs(topProductFilter?.topCategoriesWeekMonthToday?.endDate)?.format('DD MMM, YYYY')}
        {/* `${dayjs(topProductFilter?.topCategoriesWeekMonthToday?.startDate)?.format('DD-MMM-YYYY')} - ${dayjs(topProductFilter?.topCategoriesWeekMonthToday?.endDate)?.format('DD-MMM-YYYY')}` */}
        </>   : '' }  </Typography>
      </Box>
    </Paper>;

  }, [totalSoldCategoriesData, topProductFilter?.topCategoriesWeekMonthToday]);

  const totalCategoriesSoldCount = useCallback(() => {
    return <>
      {
        !isEmptyObject(memoizetotalSoldCategoriesData) && Object.keys(memoizetotalSoldCategoriesData)?.map(((soldCategoryKey, idx) => {
          const label = soldCategoryKey?.trim();
          const value = memoizetotalSoldCategoriesData[soldCategoryKey];
          return <Box key={idx} display='inline-flex' alignItems='center' gap={1} minWidth={{ xs: 80, xxl: 100, xxxl: 140 }}>
            <DotIndicatorIcon style={{ width: '6px', height: '6px', color: colors[idx], marginTop: '-1px' }} />
            <Typography variant='body2'>{label}</Typography>
            <Typography variant='body2' fontWeight='semibold'>{value}</Typography>
          </Box>;
        }))
      }
    </>;
  }, [memoizetotalSoldCategoriesData]);

  useEffect(() => {
    dispatch(totalProductsSoldStats({ ...topProductFilter?.topProductWeekMonthToday, page: topProductFilter?.topProductPageCount }));
  }, [topProductFilter?.topProductWeekMonthToday, topProductFilter?.topProductPageCount]);

  useEffect(() => {
    dispatch(totalSoldCategories({ ...topProductFilter?.topCategoriesWeekMonthToday }));
  }, [topProductFilter?.topCategoriesWeekMonthToday]);
  return (
    <>
      <Grid item xs={12} md={6} xxl={7.5} xxxl={8.55}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ p: 2.5 }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' gap={3} mb={4}>
              <Typography variant='h5' lineHeight={1.25}>Top 25 Products Sold</Typography>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => setTopProductFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu showCustomOption selectedMenu={topProductFilter?.topProductWeekMonthToday?.dashboardQuery} filter={topProductFilterOpen} handleFilter={setTopProductFilterOpen} applyFilterHandler={(value) => setTopProductFilter({ ...topProductFilter, topProductWeekMonthToday: value })} />
              {/* filter ui end  */}
             
            </Stack>
            <Box height={500}>
              <Bar data={topProductSoldData} options={{
                ...options, maintainAspectRatio: false, responsive: true, scales: {
                  y: {
                    grid: {
                      display: false,
                    },
                    ticks: {
                      font: {
                        size: 12, // Set the font size for Y-axis labels
                      },
                      color: '#2E2E3A'
                    }
                  },
                  x: {
                    display: true,
                  },

                }
              }} />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} xxl={4.5} xxxl={3.45}>
        <Card variant='dashboardCard' sx={{ height: '100%', overflow: 'hidden' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 2.5 }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' gap={3} mb={4}>
              <Typography variant='h5' lineHeight={1.25}>Product Categories Sold</Typography>

              {/* filter ui  */}
              <IconButton variant='btn-filter' color='secondary' onClick={(e) => SetsoldProductFilterOpen(e.currentTarget)}>
                <FilterAltOutlined fontSize='medium' color='secondary' />
              </IconButton>
              <FilterMenu showCustomOption selectedMenu={topProductFilter?.topCategoriesWeekMonthToday?.dashboardQuery} filter={soldProductFilterOpen} handleFilter={SetsoldProductFilterOpen} applyFilterHandler={(value) => setTopProductFilter({ ...topProductFilter, topCategoriesWeekMonthToday: value })} />
              {/* filter ui end  */}
             
            </Stack>
            <Stack alignItems='center'>
              <Box sx={{ height: 250, width: 250 }}>
                <DoughnutChart data={topCategoriesSoldChartData} chartInnerContent={productInnerContent} />
              </Box>
            </Stack>
            <Stack direction='row' flexWrap='wrap' gap={2} mt={3}>
              {totalCategoriesSoldCount()}
            </Stack>
          </CardContent>
        </Card>
      </Grid>

    </>
  );
}