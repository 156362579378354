const { isEmptyObject } = require('utilities/common');

export function retailerValidation(fields) {

    const errors = {};

    if (!fields.email || !isValidEmail(fields.email)) {
        errors.email = 'Enter a valid email address';
    }

    if (!fields.phone) {
        errors.phone = 'Enter a valid phone number';
    }

    if(fields?.phone && !isPhoneNumberValid(fields?.phone)){
        errors.phone = 'Phone number must be 10 digits';
    }

    if (!fields.firstName || fields.firstName.trim() === '') {
        errors.firstName = 'First name is required';
    }

    if (!fields.lastName || fields.lastName.trim() === '') {
        errors.lastName = 'Last name is required';
    }

    if (!fields.retailerName || fields.retailerName.trim() === '') {
        errors.retailerName = 'Retailer name is required';
    }

    if (!fields.address) {
        errors.address = 'Address is required';
    }

    if (!fields.subscription || typeof fields.subscription !== 'string') {
        errors.subscription = 'Subscription information is required';
    }
   
    if (!fields.isLifeTime) {
    if (!fields.valiDateFrom || !fields.valiDateTo) {
        if (fields.subscription !== 'Unpaid' )
        errors.validUpto = 'Valid upto is required';
    }
}

    return { errors, isValid: isEmptyObject(errors) };
}

export function isValidZipcode(zipcode) {
    const zipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipcodeRegex.test(zipcode);
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidPhone(phone) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
}



function isPhoneNumberValid(phoneNumber) {
    // Remove all non-digit characters
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Check if the cleaned phone number has a length of 10
    return cleanedPhoneNumber.length === 10;
  }