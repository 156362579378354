import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchDriversTerminalsAPI } from '../../../apis/hyperwolf/Driver/driverTerminal'

export const fetchDriversTerminals = createAsyncThunk(
    'drivers/fetchDriverRegions',
    async( filters, {rejectWithValue}) => {
        try {
            const res = await fetchDriversTerminalsAPI(filters)
            return res
        } catch(error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)