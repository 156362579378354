import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getPackageTimeAPI, getProcessTimeAPI, getServiceTimeAPI, updatePackageTimeAPI, updateProcessTimeAPI, updateServiceTimeAPI } from '../../apis/hyperwolf/settings'


export const getServiceTime = createAsyncThunk(
    'drivers/getServiceTime', 
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getServiceTimeAPI(filters)
            return res 
        }  catch (error ) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateServiceTime = createAsyncThunk(
    'drivers/updateServiceTime',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateServiceTimeAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const getProcessTime = createAsyncThunk(
    'drivers/getProcessTime', 
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getProcessTimeAPI(filters)
            return res 
        }  catch (error ) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const getPackageTime = createAsyncThunk(
    'drivers/getPackageTime', 
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getPackageTimeAPI(filters)
            return res 
        }  catch (error ) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateProcessTime = createAsyncThunk(
    'drivers/updateProcessTime',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateProcessTimeAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updatePackageTime = createAsyncThunk(
    'drivers/updatePackageTime',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updatePackageTimeAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


const hyperwolfSettings = createSlice({
    name:' hyperwolfSettings',
    initialState: {
        isLoading : false,
        serviceTime : {},
        processTime: {},
        packageTime: {},
        error: null,
    },
    reducers:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(getServiceTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getServiceTime.fulfilled, (state, action) => {
            state.isLoading = false;
            state.serviceTime = action.payload;
        })
        .addCase(getServiceTime.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })   
        .addCase(updateServiceTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(updateServiceTime.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updateServiceTime.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })


        builder
        .addCase(getProcessTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getProcessTime.fulfilled, (state, action) => {
            state.isLoading = false;
            state.processTime = action.payload;
        })
        .addCase(getProcessTime.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(updateProcessTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(updateProcessTime.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updateProcessTime.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })

        

        builder
        .addCase(getPackageTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getPackageTime.fulfilled, (state, action) => {
            state.isLoading = false;
            state.packageTime = action.payload;
        })
        .addCase(getPackageTime.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(updatePackageTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(updatePackageTime.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updatePackageTime.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })
    }
})

export const {   } =  hyperwolfSettings.actions;

export default hyperwolfSettings.reducer;