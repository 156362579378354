import { Box, Button, ButtonGroup, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';

const salePriceUnitLabel = {
  salePricePercentage: 'Sale Percentage (%)',
  salePrice: 'Sale Price ($)'
}

export default function BulkSalePriceUpdate({ closeModal }) {
  const { modalData, isLoading = false } = useSelector(state => state.modal);
  const { isDeleting = false } = useSelector(state => state.common);
  const [salePrice, setSalePrice] = useState('');
  const [errors, setErrors] = useState({});
  const [salePriceUnitType, setSalePriceUnitType] = useState('salePrice')

  const { bulkProductIds, resetData = () => { } } = modalData;
  const dispatch = useDispatch();

  const handleBulkUpdate = () => {
    if (!salePrice) {
      setErrors({ salePrice: `${salePriceUnitLabel[salePriceUnitType]} should not be empty` });
      return;
    }
    if (+salePrice <= 0) {
      setErrors({ salePrice: `${salePriceUnitLabel[salePriceUnitType]} should not be zero` });
      return;
    }
    setErrors({});
    dispatch(bulkProductUpdate({ productIds: bulkProductIds, [salePriceUnitType]: salePrice })).unwrap().then((res => {
      dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }));
      dispatch(closeCustomModalDialog({}));
      resetData();
    })).catch((err => {
      dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }));
    }));
  };

  return (
    <>
      <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'>
        Update Sale Price
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box my={2}>
          <div className='w-100 d-flex align-items-center sale-field-sec'>
            <div className='code-inner-field'>
              <TextField error={Boolean(errors?.salePrice)} helperText={errors?.salePrice} fullWidth value={salePrice} name='salePrice' placeholder={salePriceUnitLabel[salePriceUnitType]} onChange={(e) => {
                let numberRegex = /^[0-9]*\.?[0-9]*$/;
                if (e.target.value?.toString() && !numberRegex.test(e.target.value)) return;
                if (e.target.value && salePriceUnitType === 'salePricePercentage' && e.target.value > 100) return;
                setSalePrice(e.target.value);
              }} />
              <ButtonGroup className="dolar-percentage-btns" variant="contained" aria-label="">
                <Button onClick={() => {
                  setSalePrice('')
                  setSalePriceUnitType('salePrice')
                }} className={salePriceUnitType === 'salePrice' ? 'active' : ''}>$</Button>
                <Button className={salePriceUnitType === 'salePricePercentage' ? 'active' : ''} onClick={() => {
                    setSalePrice('')
                  setSalePriceUnitType('salePricePercentage')
                }}>%</Button>
              </ButtonGroup>
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={closeModal} size='small' color='secondary'>
          Cancel
        </MDButton>
        <MDButton onClick={handleBulkUpdate} size='small' color='error'>
          {
            isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : ' Update'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}