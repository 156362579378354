import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import MDButton from 'components/MDButton';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Checks if an object has any non-empty values.
 * @param {Object} obj - The object to check.
 * @returns {boolean} - True if the object has any non-empty values, false otherwise.
 */
function hasAnyValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (
      (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
      (typeof obj[key] !== 'object' && obj[key] !== '')
    )
    ) {
      return true;
    }
  }
  return false;
}

/**
 * FilterOrders component for filtering orders based on platform and status.
 * @param {Object} props - The component props.
 * @param {Function} props.applyOrderFilter - Function to apply the order filter.
 * @param {Function} [props.getQuryValues] - Function to get query values.
 * @param {Function} [props.setIsLoading] - Function to set loading state.
 * @returns {JSX.Element} - The rendered component.
 */
export default function FilterOrders(props = {}) {
  const navigate = useNavigate();
  const { retailerId } = useParams();
  const [formData, setFormData] = useState({ orderPlatform: '', status: '' });
  const { applyOrderFilter } = props;
  /**
     * Handles the application of filters for orders.
     * Constructs a query payload based on the form data and updates the URL search parameters.
     * Calls the applyOrderFilter function with the constructed query payload.
     */
  const applyFiltersHandler = () => {
    const { orderPlatform, status } = formData || {};
    let queryPayload = {
      orderPlatform: orderPlatform === 'all' ? '' : orderPlatform,
      status: status === 'all' ? '' : status
    };

    const searchParams = new URLSearchParams();
    let filterValue = { ...queryPayload, filter: 'retailerOrders' } || {};
    Object.keys(filterValue).forEach(key => {
      searchParams.append(key, filterValue[key]);
    });

    navigate({ search: searchParams.toString() });
    applyOrderFilter({ queryPayload });
  };

  /**
     * Clears all filters and resets the form data.
     * Navigates to the retailer's detail page with the default filter.
     * Calls the applyOrderFilter function with an empty object.
     */
  const clearAllHandler = () => {
    setFormData({ orderPlatform: '', status: '' });
    navigate(`/retailers/detail/${retailerId}?filter=retailerOrders`);
    applyOrderFilter({});
  };

  /**
     * Determines whether the apply button should be shown based on the form data.
     * @returns {boolean} - True if the form data has any non-empty values, false otherwise.
     */
  const showApplyButton = useMemo(() => {
    return hasAnyValue(formData);
  }, [formData]);

  return (
    <Box className='cus-filter-sec' mb={1}>
      <Grid className='filter-box' container alignItems='center' spacing={1.5}>
        <Grid item xs={12} sm={12} md={12} xxl={12}>
          <Box className='filter-price-unit'>
            <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className='filter-pro-status'>
                  <FormControl fullWidth>
                    <InputLabel>Available</InputLabel>
                    <Select size='small' value={formData?.orderPlatform} onChange={(e) => setFormData({ ...formData, orderPlatform: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='ecommerce'>Ecommerce</MenuItem>
                      <MenuItem value='store'>Store</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className='filter-pro-status'>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select size='small' value={formData?.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='awaiting_shipment'>In-progress</MenuItem>
                      <MenuItem value='shipped'>Shipped</MenuItem>
                      <MenuItem value='delivered'>Delivered</MenuItem>
                      <MenuItem value='cancelled'>Cancelled</MenuItem>
                      <MenuItem value='warranty'>Warranty</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4} xxl={1.5}>
                <Box className='filter-btns'>
                  <Stack direction='row' alignItems='center' gap={1} className='w-100'>
                    <Button className='filter-clear-all' onClick={clearAllHandler} size='small' variant='text' color='secondary' style={{ width: '100%' }}>
                      Clear All
                    </Button>
                    <MDButton disabled={!showApplyButton} className='filter-apply' onClick={applyFiltersHandler} size='small' variant='contained' color='primary' style={{ width: '100%' }}>
                      Apply
                    </MDButton>
                  </Stack>
                </Box>
              </Grid>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}