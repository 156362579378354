import React from 'react';
import { DialogContent, DialogContentText, DialogTitle, IconButton, Typography, Box, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import iconImportDriver from 'assets/images/iocn-import-driver.svg';
import { openSnackbar } from '../../../redux/slices/common';
// import { readString } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import { importFleets } from '../../../redux/slices/hyperwolf/driver/driver';
import { closeCustomModalDialog } from '../../../redux/slices/modalSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ImportDriversModal({ closeModal }) {
  // const [csvData, setCsvData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalData } = useSelector(state => state.modal);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      // const text = e.target.result;
      // const result = await readString(text, {
      //   header: true,
      //   skipEmptyLines: true,
      // });
      // const parsedData = result?.data;
      // setCsvData(parsedData);
      const formData = new FormData();
      formData.append('file', file);
      await dispatch(importFleets(formData)).unwrap()
        .then(res => {
          showResponseMessage(res.data.message, 'success');
          modalData.resetData();
        });
    };
    dispatch(closeCustomModalDialog({}));
    navigate('/hyperwolf/driver?driverFilter=');
    reader.readAsText(file);
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  return (
    <div className='import-driver-modal'>
      <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'>
            Import Drivers
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>

      <DialogContent className='text-center'>
        <Stack direction='row' flexWrap='wrap' gap={1.25} justifyContent='center' alignItems='center' mt={2}>
          <label htmlFor='driver-image-input' className='cur-pointer'>
            <Box bgcolor='#F1F1F1' display='flex' alignItems='center' justifyContent='center' flexDirection='column' minWidth='125px' minHeight='125px' borderRadius='20px' p={2}>
              <img src={iconImportDriver} />
            </Box>
          </label>
          <TextField
            id='driver-image-input'
            type='file'
            name='driverImageFile'
            sx={{ display: 'none' }}
            onChange={handleFileUpload}
            inputProps={{ accept: '.csv' }}
          />
        </Stack>
        <Typography variant='h6' fontWeight='semibold' mt={1} mb={2}>Choose CSV File</Typography>
        <DialogContentText className='text-14' mb={2}>View instructions and driver import template file <span className='text-color-primary'>here</span></DialogContentText>
      </DialogContent>
    </div>
  );
}