import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addDriverHempAPI, bulkDeleteDriverHempAPI, deleteDriverHempAPI, fetchDriverDetailsHempAPI, getDriversHempAPI, getTransportationsHempAPI, updateDriverHempAPI } from '../apis/driverHemp'

export const getDriversHemp = createAsyncThunk(
    'hempDrivers/fetchDrivers',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getDriversHempAPI(filters)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const addDriverHemp = createAsyncThunk(
    'hempDrivers/addDriver',
    async(data, { rejectWithValue }) => {
        try {
            const res = await addDriverHempAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchDriverDetailsHemp = createAsyncThunk(
    'hempDriver/details',
    async(fleetId, { rejectWithValue }) => {
        try {
            const res = await fetchDriverDetailsHempAPI(fleetId)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchTransportationsHemp = createAsyncThunk(
    'hempDriver/fetchTransportations',
    async( filters, {rejectWithValue}) => {
        try {
            const res = await getTransportationsHempAPI()
            return res
        } catch(error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateDriverHemp = createAsyncThunk(
    'hempDriver/updateDriver',
    async(data, {rejectWithValue}) => {
        try {
            const res = await updateDriverHempAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteDriverHemp = createAsyncThunk(
    'hempDriver/deleteDriver', 
    async(data, { rejectWithValue }) => {
        try {
            const res = await deleteDriverHempAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const bulkDeleteDriverHemp = createAsyncThunk(
    'drivers/bulkDeleteDriver', 
    async(data, { rejectWithValue }) => {
        try {
            const res = await bulkDeleteDriverHempAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const adminDriversHemp = createSlice({
    name: 'adminDriversHemp',
    initialState: {
        adminDrivers: [],
        driverDetails: {},
        loading: false,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getDriversHemp.fulfilled, (state, action) => {
            state.loading = false;
            state.adminDrivers = action.payload;
            state.driverDetails = {}
            state.error = null;
        })
        .addCase(getDriversHemp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(getDriversHemp.pending, (state) => {
            state.loading = true;
        })

        builder.
        addCase(addDriverHemp.pending, (state) => {
            state.loading = true;
            state.error = null
        })
        .addCase(addDriverHemp.fulfilled, (state, action) => {
            state.loading = false;
            state.addedDriver = action.payload;
            state.error = null;
        })
        .addCase(addDriverHemp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder.
        addCase(fetchDriverDetailsHemp.fulfilled, (state,action) =>{
            state.loading = false;
            state.driverDetails = action.payload;
            state.error = null;
        })

        builder.addCase(fetchDriverDetailsHemp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(fetchDriverDetailsHemp.pending, (state) =>{
            state.loading = true;
        })

        builder.
        addCase(updateDriverHemp.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateDriverHemp.fulfilled, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        .addCase(updateDriverHemp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const { /* Any additional actions */ } = adminDriversHemp.actions

export default adminDriversHemp.reducer;