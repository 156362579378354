import { Close } from '@mui/icons-material';
import { Box, Stack, Switch, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion';
import { fetchHyperWolfFirstRender, fetchHyperwolfTasksRegions, updateTaskRegionInformation } from '../../../redux/slices/hyperdrive/createTask';
import { formatAddress } from 'utilities/common';
import { isEmptyObject } from 'utilities/common';
import Loader from 'common/Loader';
import noDataFound from '../../../assets/images/nothing-found.svg';
import { useLayoutEffect } from 'react';
import { openSnackbar } from '../../../redux/slices/common';

export default function StartTask({ closeModal }) {
  const dispatch = useDispatch();
  const addressError = useRef(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const googleAddressErrorHandler = (status = false) => {
    addressError.current = status;
  };

  function throttle(fn, wait) {
    let timeout = null;
    return function (...args) {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
        }, wait);
        fn(...args);
      }
    };
  }

  const handleChangeRegionsFields = (value, fieldName = '', fieldIndx, fieldClear) => {
    const address = fieldClear === 'clear' ? {} : {
      address1: value?.address || '',
      address: value?.address || '',
      city: value?.city || '',
      state: value?.state || '',
      zipcode: value?.zipcode || '',
      country: value?.country || '',
      latitude: value?.lat || '',
      longitude: value?.long || '',
    };

    let field = dataSource[fieldIndx];
    field = {
      ...field,
      [fieldName]: fieldName === 'location' ? address : value
    };
    let newRegionsData = [...dataSource];
    newRegionsData[fieldIndx] = field;
    setDataSource(newRegionsData);
    throtallUpdateRegionInfo(field, field?.regionId);
  };

  const throtallUpdateRegionInfo = throttle((field) => updateRegionInformation(field, field?.regionId), 500);
  const updateRegionInformation = (regionData) => {
    dispatch(updateTaskRegionInformation(regionData, regionData?.regionId)).unwrap().then((res => {
      showResponseMessage(res?.message, 'success');
    })).catch(err => {
      showResponseMessage(err?.message, 'error');
    });
  };
    
  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  useEffect(() => {
    dispatch(fetchHyperwolfTasksRegions({ limit: 100, skip: 0 })).unwrap().then((res => {
      const { startTask = [] } = res || {};
      setDataSource(startTask);
      setLoading(false);
    })).catch(() => {
      setLoading(false);
    });
  }, []);

  useLayoutEffect(() => {
    dispatch(fetchHyperWolfFirstRender()).unwrap().then((() => {
    })).catch(() => {
    });
  }, []);

  return (
    <Box className='cus-wrapper-drawer'>
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2} style={{ borderBottom: '1px solid  #1E1E24' }} className='drawer-head-sec'>
        <Typography className='text-14 text-white-700'>Create Start Task</Typography>
        <Close onClick={closeModal} style={{ cursor: 'pointer', color: '#FFFFFF' }} />
      </Box>
      <Box className='drawer-scroll-sec drawer-scroll-without-footer'>
        <Box px={2} py={4}>
          <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Region List</Typography>
          {loading ?
            <Box className='w-100 d-flex align-item-center jc-center h-100 inner-scroll'>
              <Loader />
            </Box> :
            <>
              {
                dataSource?.length === 0 ?
                  <Box className='w-100 d-flex flex-column align-item-center jc-center h-100 no-data-found'>
                    <img style={{ maxWidth: '120px' }} src={noDataFound} alt='No Results Found' />
                    <p className='text-12'><strong>No Region Found</strong></p>
                  </Box>
                  :
                  (dataSource?.map((data, fieldIndx) => {
                    return (
                      <Box key={data?._id} display='flex' alignItems='start' flexDirection='column' justifyContent='space-between' className='region-listing' gap={1}>
                        <Box display='flex' alignItems='center' className='region-txt'>
                          <Switch checked={data?.isEnabled} onChange={(e) => handleChangeRegionsFields(e.target.checked, 'isEnabled', fieldIndx)} />
                          <Typography className='text-14 text-white-700'>{data?.regionName}</Typography>
                        </Box>
                        <Stack gap={2} className='dark-field hd-google-suggestions'>
                          <>
                            <GoogleSuggestion addressValue={!isEmptyObject(data?.location) ? formatAddress(data?.location) : ''}
                              istrue={data?.isEnabled ? 'yes' : 'no'}
                              googleAddressErrorHandler={googleAddressErrorHandler} onClearAddress={(address) => handleChangeRegionsFields(address, 'location', fieldIndx, 'clear')}
                              addressChangeHandler={(address) => handleChangeRegionsFields(address, 'location', fieldIndx)} />
                          </>
                        </Stack>
                      </Box>
                    );
                  }))
              }
            </>
          }
        </Box>
      </Box>
    </Box>
  );
}