import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getData } from 'utilities/common';
const user = getData('login-user-info')
const parseUser = JSON.parse(user)
const baseUrl = process.env.REACT_APP_BASE_URL_HYPER_DRIVE
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, {  }) => {        
            headers.set('Authorization', `Bearer ${parseUser?.access_token}`);
            return headers;
        },
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    return result;
};

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUserData: builder.query({
            query: ({ skip, limit }) => ({
                url: `api/v1/admin/analytics/list?skip=${skip}&limit=${limit}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
    })
});

export const {
    useGetUserDataQuery
} = userApi;
