import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addCommonReasonAPI, BulkProductUpdateAPI, deleteReasonApi, editRefundReasonApi, fetchAllRegionsAPI, fetchInfoEffectsAPI, fetchProductCategoriesAPI, fetchRefundListAPI, fetchRefundStatusAPI, fetchRefundTableAPI, getPaymentPlatformStatusAPI, getProductDisclaimersAPI, getSignupStatusAPI, updateDisclaimerAPI, updatePaymentPlatformStatusAPI, updateRefundDataAPI, updateSignupStatusAPI, uploadImageAPI, uploadProductImageAPI } from '../apis/common'
import { deleteBannerAPI, getBannersListsAPI, getsingleBannerAPI, updateBannerListAPI } from "../../redux/apis/banners";
import { setData } from "utilities/common";

export const uploadImage = createAsyncThunk(
    'common/uploadImage',
    async (data, { rejectWithValue }) => {
        try {
            const res = await uploadImageAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const uploadProductImage = createAsyncThunk(
    'common/uploadProductImage',
    async (data, { rejectWithValue }) => {
        try {
            const res = await uploadProductImageAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const fetchInfoEffect = createAsyncThunk(
    'common/fetchInfoEffect',
    async (data, { rejectWithValue }) => {
        try {
            const res = await fetchInfoEffectsAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const fetchCategories = createAsyncThunk(
    'common/fetchCategories',
    async (filters = { skip: 0, limit: 1000, userType: 'admin' }, { rejectWithValue }) => {
        try {
            const res = await fetchProductCategoriesAPI(filters);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const fetchAllRegions = createAsyncThunk(
    'common/fetchAllRegions',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchAllRegionsAPI(filters);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const bulkProductUpdate = createAsyncThunk(
    'common/bulkProductUpdate',
    async (data, { rejectWithValue }) => {
        try {
            const res = await BulkProductUpdateAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const fetchSignupStatus = createAsyncThunk(
    'common/fetchSIngupStatus',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getSignupStatusAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)
export const fetchRefundStatus = createAsyncThunk(
    'common/fetchRefundStatus',
    async (data, { rejectWithValue }) => {
        try {
            const res = await fetchRefundStatusAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)
export const updateRefundData = createAsyncThunk(
    'common/updateRefundData',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateRefundDataAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const updateSignupStatus = createAsyncThunk(
    'common/updateSignupStatus',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateSignupStatusAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const fetchPaymentPlatformStatus = createAsyncThunk(
    'common/fetchPaymentPlatformStatus',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getPaymentPlatformStatusAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const updatePaymentPlatformStatus = createAsyncThunk(
    'common/updatePaymentPlatformStatus',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updatePaymentPlatformStatusAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const getHomepageBanners = createAsyncThunk(
    'common/getHomepageBanners',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getBannersListsAPI(data);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const updateHomePageBanners = createAsyncThunk(
    'common/updateHomePageBanners',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const res = await updateBannerListAPI(data, id);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const getSingleBannerAPI = createAsyncThunk(
    'common/getSingleBannerAPI',
    async (id, { rejectWithValue }) => {
        try {
            const res = await getsingleBannerAPI(id);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const deleteBanner = createAsyncThunk(
    'common/deleteBanner',
    async (id, { rejectWithValue }) => {
        try {
            const res = await deleteBannerAPI(id);
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const getProductDisclaimers = createAsyncThunk(
    'common/getProductDisclaimers',
    async (_, { rejectWithValue }) => {
        try {
            const res = await getProductDisclaimersAPI();
            return res
        } catch (error) {

            return rejectWithValue(error.response?.data)
        }
    }
)

export const updateDisclaimer = createAsyncThunk(
    'common/updateDisclaimer',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateDisclaimerAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const fetchRefundList = createAsyncThunk(
    'common/fetchRefundList',
    async (data, { rejectWithValue }) => {
        try {
            const res = await fetchRefundListAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

// export const fetchRefundTable = createAsyncThunk(
//     'common/fetchRefundTable',
//     async ({data}, { rejectWithValue }) => {
//         try {
//             const res = await fetchRefundTableAPI(data);
//             return res
//         } catch (error) {             
//             return rejectWithValue(error.response?.data)
//         }
//     }
// )
export const fetchRefundTable = createAsyncThunk(
    'common/fetchRefundTable',
    async (data, { rejectWithValue }) => {
        try {
            const res = await fetchRefundTableAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const addCommonReason = createAsyncThunk(
    'common/addCommonReason',
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await addCommonReasonAPI(data);
            return res
        } catch (error) {
            return rejectWithValue(error.response?.data)
        }
    }
)

export const editRefundReason = createAsyncThunk(
    'common/editRefundReason',
    async ({ data, reasonId }, { rejectWithValue }) => {
        try {
            const res = await editRefundReasonApi(data, reasonId);
            return res
        }
        catch (error) {
            return rejectWithValue(error?.response?.data || error.message)
        }
    }
)
export const deleteReason = createAsyncThunk(
    'common/deleteReason',
    async (reasonId, { rejectWithValue }) => {
        try {
            const res = await deleteReasonApi(reasonId);
            return res
        }
        catch (error) {
            return rejectWithValue(error?.response?.data || error.message)
        }
    }
)

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        // Your initial state here
        isLoading: false,
        infoEffectData: [],
        categoriesData: [],
        regions: [],
        fetchCategoriesError: null,
        isFetchingCategories: false,
        snackbar: {
            isOpen: false,
            color: '',
            icon: '',
            content: '',
            bgWhite: false,
        },
        isUploading: false,
        banners: {},
        paymentPlatformStatus: {},
        singleBanner: {},
        adminsMode: 'hemp',
        isFullScreen: false,
        isGetLoading: false,
        isBtnLoading: false,
        loading:false
    },
    reducers: {
        setFullScreen: (state, action) => {
            state.isFullScreen = action.payload
        },
        openSnackbar: (state, action) => {
            state.snackbar.isOpen = true;
            state.snackbar.color = action.payload.color;
            state.snackbar.icon = action.payload.icon;
            state.snackbar.content = action.payload.content;
            // state.snackbar.bgWhite =( action.payload.color === 'error' || action.payload.color === 'success') ? false : true 
        },
        closeSnackbar: (state) => {
            state.snackbar.isOpen = false;
            // state.snackbar.color = '';
            // state.snackbar.icon = '';
            // state.snackbar.content = '';
            state.snackbar.bgWhite = false
        },
        setAdminsMode: (state, action) => {
            state.adminsMode = action.payload
            setData('adminsMode', action.payload)
        }
        // Any other reducers you have
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInfoEffect.pending, (state, action) => {
            // Handle fetch errors
            state.isLoading = true
            state.fetchInfoEffectError = action.payload;
        });
        builder.addCase(fetchInfoEffect.fulfilled, (state, action) => {
            // Handle successful fetch of info effects
            state.infoEffectData = action.payload;
            state.isLoading = false
            state.fetchInfoEffectError = null;
        });
        builder.addCase(fetchInfoEffect.rejected, (state, action) => {
            // Handle fetch errors
            state.isLoading = false
            state.fetchInfoEffectError = action.payload;
        });

        builder.addCase(fetchCategories.pending, (state) => {
            // Set the pending state to true
            state.isFetchingCategories = true;
            state.fetchCategoriesError = null; // Reset any previous errors
        });
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            // Handle successful fetch of categories
            state.categoriesData = action.payload;
            state.fetchCategoriesError = null;
            state.isFetchingCategories = false; // Reset the pending state
        });
        builder.addCase(fetchCategories.rejected, (state, action) => {
            // Handle fetch errors
            state.fetchCategoriesError = action.payload;
            state.isFetchingCategories = false; // Reset the pending state
        });

        builder.addCase(fetchAllRegions.fulfilled, (state, action) => {
            state.isLoading = false;
            state.regions = action.payload; // Update state with the received data
            state.error = null;
        });

        builder.addCase(fetchAllRegions.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload; // Update state with the error message
        });

        builder.addCase(fetchAllRegions.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(uploadImage.pending, (state) => {
            state.isUploading = true;
            state.error = null;
        })
            .addCase(uploadImage.fulfilled, (state, action) => {
                state.isUploading = false;
                // Assuming your API response contains the uploaded image URL
                state.imageUrl = action.payload;
            })
            .addCase(uploadImage.rejected, (state, action) => {
                state.isUploading = false;
                state.error = action.payload;
            })

        builder.addCase(uploadProductImage.pending, (state) => {
            state.isUploading = true;
            state.error = null;
        })
            .addCase(uploadProductImage.fulfilled, (state, action) => {
                state.isUploading = false;
                // Assuming your API response contains the uploaded image URL
                state.imageUrl = action.payload;
            })
            .addCase(uploadProductImage.rejected, (state, action) => {
                state.isUploading = false;
                state.error = action.payload;
            })

        builder.addCase(bulkProductUpdate.pending, (state) => {
            state.isDeleting = true;
            state.error = null;
        })
            .addCase(bulkProductUpdate.fulfilled, (state, action) => {
                state.isDeleting = false;
                // Assuming your API response contains the uploaded image URL
            })
            .addCase(bulkProductUpdate.rejected, (state, action) => {
                state.isDeleting = false;
                state.error = action.payload;
            })

        builder
            .addCase(fetchSignupStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSignupStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.signupStatus = action.payload;
            })
            .addCase(fetchSignupStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(fetchRefundStatus.pending, (state) => {
                state.isGetLoading = true;
                state.error = null;
            })
            .addCase(fetchRefundStatus.fulfilled, (state, action) => {
                state.isGetLoading = false;
                state.fetchRefund = action.payload;
            })
            .addCase(fetchRefundStatus.rejected, (state, action) => {
                state.isGetLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(updateRefundData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateRefundData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.updateRefundData = action.payload;
            })
            .addCase(updateRefundData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(updateSignupStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateSignupStatus.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateSignupStatus.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getHomepageBanners.pending, (state) => {
                state.isLoading = true;
                state.singleBanner = {}
                state.error = null;
            })
        builder
            .addCase(getHomepageBanners.fulfilled, (state, action) => {
                state.isLoading = false;
                state.banners = action.payload;
            })
            .addCase(getHomepageBanners.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(updatePaymentPlatformStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updatePaymentPlatformStatus.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updatePaymentPlatformStatus.rejected, (state, action) => {
                state.isLoading = false;
            })

        builder
            .addCase(fetchPaymentPlatformStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchPaymentPlatformStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.paymentPlatformStatus = action.payload;
            })
            .addCase(fetchPaymentPlatformStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(getSingleBannerAPI.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getSingleBannerAPI.fulfilled, (state, action) => {
                state.isLoading = false;
                state.singleBanner = action.payload;
            })
            .addCase(getSingleBannerAPI.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(deleteBanner.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteBanner.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteBanner.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(getProductDisclaimers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getProductDisclaimers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.disclaimers = action.payload;
            })
            .addCase(getProductDisclaimers.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Something went wrong';
            });
        builder
            .addCase(updateDisclaimer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateDisclaimer.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateDisclaimer.rejected, (state, action) => {
                state.isLoading = false;
            });
        builder
            .addCase(fetchRefundList.pending, (state) => {
                state.isLoading = true;
                state.refundList = [];
            })
            .addCase(fetchRefundList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.refundList = action.payload;
            })
            .addCase(fetchRefundList.rejected, (state, action) => {
                state.isLoading = false;
                state.refundList = [];
            });
        builder
            .addCase(fetchRefundTable.pending, (state) => {
                state.tableloading = true;
                state.refundList = {};
            })
            .addCase(fetchRefundTable.fulfilled, (state, action) => {
                state.tableloading = false;
                state.refundList = action.payload;
            })
            .addCase(fetchRefundTable.rejected, (state, action) => {
                state.tableloading = false;
                state.refundList = {};
            });
        builder
            .addCase(addCommonReason.pending, (state) => {
                state.isBtnLoading = true;
            })
            .addCase(addCommonReason.fulfilled, (state, action) => {
                state.isBtnLoading = false;
            })
            .addCase(addCommonReason.rejected, (state, action) => {
                state.isBtnLoading = false;
            });
        builder
            .addCase(editRefundReason.pending, (state) => {
                state.isBtnLoading = true;
            })
            .addCase(editRefundReason.fulfilled, (state, action) => {
                state.isBtnLoading = false;
            })
            .addCase(editRefundReason.rejected, (state, action) => {
                state.isBtnLoading = false;
            });
            builder
            .addCase(deleteReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteReason.rejected, (state, action) => {
                state.loading = false;
            });

    },
});

// Other slice configuration code...
export const { openSnackbar, closeSnackbar, setAdminsMode, setFullScreen } = commonSlice.actions;
export default commonSlice.reducer;