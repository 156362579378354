import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function CustomSelect({ label = 'Label', name = '', errors = '', onChange = () => {}, value = '', menuItem = [{ title: 'Title1', value: 'value1' }, { title: 'Title2', value: 'value2' }], ...rest }) {
    const classes = useStyles();
    return (
        <>
            <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
                <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={value}
                    onChange={onChange}
                    label={label}
                    name={name}
                    error={Boolean(errors)}
                    {...rest}
                >
                    {
                        menuItem && menuItem.map((val, index) => (
                            <MenuItem value={val?.value || ''} key={index}>
                                {val?.title || ''}
                            </MenuItem>
                        ))
                    }
                </Select>
                {errors && <span className='error'>{errors}</span>}
            </FormControl>
        </>
    );
}

// Set display name for debugging
export default CustomSelect;
