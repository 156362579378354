import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeCustomDrawer } from '../../redux/slices/modalSlice';
import PropTypes from 'prop-types';
import CustomDrawer from './Drawer';
import FilterProducts from './products/FilterProducts';
import PickupTaskDrawer from './hyperdrive/PickupTaskDrawer';
import DropOffTaskDrawer from './hyperdrive/DropOffTaskDrawer';
import StartTaskDrawer from './hyperdrive/StartTaskDrawer';
import BreakTaskDrawer from './hyperdrive/BreakTaskDrawer';
import ReturnToHQDrawer from './hyperdrive/ReturnToHQDrawer';
import TaskDrawer from './hyperdrive/TaskDrawer';
import CheckInOut from './hyperdrive/CheckinOut';

export default function CommonDrawer() {
  const dispatch = useDispatch();
  const { drawer } = useSelector(state => state.modal);
  const closeModal = () => {
    dispatch(closeCustomDrawer());
  };

  switch (drawer) {

  case 'FILTER_PRODUCTS':
    return <RenderModalWithComponent component={FilterProducts} customFunction={closeModal} className='' />;

  case 'CHECKIN_DETAIL':
    return <RenderModalWithComponent component={CheckInOut} customFunction={closeModal} className='task-details-drawer check-in-out-drawer' />;

  case 'TASK_DETAIL':
    return <RenderModalWithComponent component={TaskDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  case 'TASK_DETAIL_ROUTE':
    return <RenderModalWithComponent component={TaskDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  case 'PICKUP_TASK':
    return <RenderModalWithComponent component={PickupTaskDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  case 'DROP_OFF_TASK':
    return <RenderModalWithComponent component={DropOffTaskDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  case 'START_TASK':
    return <RenderModalWithComponent component={StartTaskDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  case 'BREAK_TASK':
    return <RenderModalWithComponent component={BreakTaskDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  case 'RETURN_HQ_TASK':
    return <RenderModalWithComponent component={ReturnToHQDrawer} customFunction={closeModal} className='task-details-drawer multi-drawer' />;

  default:
    return null;
  }
}

const RenderModalWithComponent = ({ component: Component,  className, customFunction, ...rest }) => {
  return (
    <>
      <CustomDrawer fullWidth customFunction={customFunction} className={className}>
        <Component {...rest} closeModal={customFunction} />
      </CustomDrawer>
    </>
  );
};
RenderModalWithComponent.propTypes = {
  component: PropTypes.elementType.isRequired, // expects a React component type
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  customFunction: PropTypes.func,
  // Add any other PropTypes you need for your component's props
};
