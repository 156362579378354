import React from 'react';

export default function ClaimWhiteIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13123_15980)">
                <g clipPath="url(#clip1_13123_15980)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.72188 1.04773L1.04785 3.72176H2.50383C2.82702 3.72176 3.13681 3.59346 3.36499 3.36487C3.59358 3.13669 3.72188 2.8269 3.72188 2.50371V1.04773Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.8724 10.218C15.8891 10.218 17.5266 11.8555 17.5266 13.8722C17.5266 15.8889 15.8891 17.5263 13.8724 17.5263C11.8557 17.5263 10.2183 15.8889 10.2183 13.8722C10.2183 11.8555 11.8557 10.218 13.8724 10.218ZM11.8626 14.1592L13.0113 15.3079C13.1696 15.4662 13.4266 15.4662 13.5854 15.3079L15.8822 13.011C16.0406 12.8523 16.0406 12.5952 15.8822 12.4365C15.7235 12.2781 15.4664 12.2781 15.3081 12.4365L13.2983 14.4463L12.4367 13.5851C12.2784 13.4268 12.0214 13.4268 11.8626 13.5851C11.7043 13.7435 11.7043 14.0009 11.8626 14.1592Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.2782 9.42429V1.69169C14.2782 1.3685 14.1499 1.0587 13.9213 0.830521C13.6932 0.601933 13.3834 0.473633 13.0602 0.473633C10.7053 0.473633 6.44493 0.473633 4.80056 0.473633C4.71002 0.473633 4.6207 0.483782 4.53381 0.503271V2.50372C4.53381 3.0421 4.31984 3.55856 3.9394 3.9394C3.55855 4.31984 3.0421 4.53381 2.50372 4.53381H0.503271C0.483782 4.6207 0.473633 4.71002 0.473633 4.80057V16.3083C0.473633 16.6315 0.601933 16.9413 0.830521 17.1695C1.0587 17.3981 1.3685 17.5264 1.69169 17.5264H11.305C10.1567 16.718 9.40602 15.3822 9.40602 13.8722C9.40602 11.4073 11.4073 9.40602 13.8722 9.40602C14.009 9.40602 14.1442 9.41211 14.2782 9.42429ZM3.31576 15.0903H8.18797C8.41209 15.0903 8.59398 14.9084 8.59398 14.6842C8.59398 14.4601 8.41209 14.2782 8.18797 14.2782H3.31576C3.09163 14.2782 2.90974 14.4601 2.90974 14.6842C2.90974 14.9084 3.09163 15.0903 3.31576 15.0903ZM3.31576 12.6542H8.18797C8.41209 12.6542 8.59398 12.4723 8.59398 12.2481C8.59398 12.024 8.41209 11.8421 8.18797 11.8421H3.31576C3.09163 11.8421 2.90974 12.024 2.90974 12.2481C2.90974 12.4723 3.09163 12.6542 3.31576 12.6542ZM3.31576 10.2181H9C9.22412 10.2181 9.40602 10.0362 9.40602 9.81203C9.40602 9.58791 9.22412 9.40602 9 9.40602H3.31576C3.09163 9.40602 2.90974 9.58791 2.90974 9.81203C2.90974 10.0362 3.09163 10.2181 3.31576 10.2181ZM3.31576 7.78195H11.4361C11.6602 7.78195 11.8421 7.60005 11.8421 7.37593C11.8421 7.15181 11.6602 6.96991 11.4361 6.96991H3.31576C3.09163 6.96991 2.90974 7.15181 2.90974 7.37593C2.90974 7.60005 3.09163 7.78195 3.31576 7.78195Z" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_13123_15980">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
                <clipPath id="clip1_13123_15980">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
}