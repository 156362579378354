import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import colors from 'assets/theme/base/colors';
import { useState } from 'react';
import { Box, FormControl } from '@mui/material';
import DateRange from 'components/dateRangePicker/DateRange';
import dayjs from 'dayjs';

export default function FilterMenu({ filter = null, handleFilter = () => { }, applyFilterHandler = () => { }, selectedMenu = '', showCustomOption = false }) {
  const { primary } = colors;
  const [isCustomDate, setIsCustomDate] = useState(false)
  const [dateFilter, setDateFilter] = useState([null, null])
  const handleClose = () => {
    handleFilter(null);
  };

  const setDateRange = (dates) => {
    if (dates?.every(date => date !== null)) {
      setDateFilter(dates)
      applyFilterHandler({ startDate: dayjs(dates[0]).valueOf(), endDate: dayjs(dates[1]).valueOf() })
      handleFilter(null);
      return;
    }
    if (dates && dates.length === 2) {
      setDateFilter(dates)
    }
  }

  const handleClick = (filterValue) => {
    if (showCustomOption) {
      applyFilterHandler({ dashboardQuery: filterValue })
      setDateFilter([null,null])
      setIsCustomDate(false)
    } else {
      applyFilterHandler(filterValue);
    }
    handleFilter(null);
  };

  const activeMenuItem = {
    color: `${primary.main} !important`,
    fontWeight: 600,
  };


  return (
    <Menu
      id=''
      anchorEl={filter}
      open={Boolean(filter)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      TransitionComponent={Fade}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px -1px 4px rgba(0,0,0,0.05))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          }
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => handleClick('today')} value="today" sx={selectedMenu === 'today' ? activeMenuItem : ''}>Today</MenuItem>
      <MenuItem onClick={() => handleClick('week')} value="week" sx={selectedMenu === 'week' ? activeMenuItem : ''}>Week</MenuItem>
      <MenuItem onClick={() => handleClick('month')} value="month" sx={selectedMenu === 'month' ? activeMenuItem : ''}>Month</MenuItem>
      {
        showCustomOption && <MenuItem onClick={(e) => {
          e.stopPropagation()
          setIsCustomDate(true)
        }}>
          {
            isCustomDate ? <Box className="filter-date-range" style={{ minWidth: "260px" }}>
              <FormControl fullWidth>
                <DateRange
                showIcon
                  setDateRange={setDateRange}
                  name='range'
                  dateRange={dateFilter}
                  title='Select Date'
                  key='products-sold'
                />
              </FormControl>
            </Box> : 'Custom'
          }

        </MenuItem>
      }

    </Menu>
  );
}