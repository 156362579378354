import { Box, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../redux/slices/common';
import { isNumber } from 'utilities/common';
import { createMemberShip, updateMemberShip } from '../../redux/slices/memberships';
import { isEmptyObject } from 'utilities/common';

export default function AddMemberShipForm() {
  const { loading, memberShipDetails } = useSelector(state => state.memberships);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { memberShipId } = useParams();
  const [memberShip, setMemberShip] = useState({
    promoName: '', status: true, promoDescription: '', discountType: '', discountAmount: '', membershipGroupType: ''
  });

  const membershipChangeHandler = (event) => {
    if (event?.target?.name === 'discountAmount' && event.target.value && !isNumber(event.target.value)) return;
    if(event?.target?.name === 'discountAmount' && memberShip?.discountType === 'Percentage' && +event?.target?.value > 100) return;
    setMemberShip({ ...memberShip, [event?.target?.name]: event?.target.value });

  };

  const submitHandler = () => {
    if (memberShipId) {
      dispatch(updateMemberShip({ data:memberShip, memberShipId })).unwrap().then(res => {
        showResponseMessage(res?.message, 'success');
        navigate('/memberships');
      }).catch(err => {
        showResponseMessage(err?.message, 'error');
      });
      return;
    }
    dispatch(createMemberShip(memberShip)).unwrap().then(res => {
      showResponseMessage(res?.message, 'success');
      navigate('/memberships');
    }).catch(err => {
      showResponseMessage(err?.message, 'error');
    });
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  useEffect(() => {
    if (memberShipId && !isEmptyObject(memberShipDetails)) {
      setMemberShip(memberShipDetails);
    }
  }, [memberShipId, memberShipDetails]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight="semibold" color="secondary">{memberShipId ? 'Update' : 'Add'} Membership</Typography>
          <Box display="flex" alignItems="center" gap={1.25}>
            <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
            <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                color={memberShip?.status ? 'success' : 'dark'}
                variant={memberShip?.status ? 'contained' : 'text'}
                size="small"
                onClick={() => setMemberShip({ ...memberShip, status: true })}
              >
                                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={!memberShip?.status ? 'contained' : 'text'}
                color={!memberShip?.status ? 'error' : 'dark'}
                size="small"
                onClick={() => setMemberShip({ ...memberShip, status: false })}
              >
                                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>

        <Grid container spacing={2.5} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField className="form-input" fullWidth label="Promotion Name" name="promoName" value={memberShip?.promoName || ''} onChange={(e) => membershipChangeHandler(e, 'promotionInfo', 'promoName')} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Discount Type</InputLabel>
              <Select
                className="form-select"
                labelId="user-type-label"
                id="user-type-select"
                label="Discount Type"
                name='discountType'
                value={memberShip?.discountType || ''}
                onChange={(e) => membershipChangeHandler(e)}
              >
                <MenuItem value={'Cash'}>Cash</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>

              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextField className="form-input" fullWidth label="Discount Amount" name="discountAmount" value={memberShip?.discountAmount}
              onChange={(e) => membershipChangeHandler(e)} />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="option-label">Membership Group</InputLabel>
              <Select
                className="form-select"
                labelId="option-label"
                id="option-select"
                value={memberShip?.membershipGroupType}
                label="Membership Group"
                onChange={(e) => membershipChangeHandler(e)}
                name='membershipGroupType'
              >
                <MenuItem value={'Delivery'}>Delivery</MenuItem>
                <MenuItem value={'Seniors'}>Seniors</MenuItem>
                <MenuItem value={'State Rec-card'}>State Rec-card</MenuItem>
                <MenuItem value={'Veteran'}>Veteran</MenuItem>
                <MenuItem value={'Walk-In'}>Walk-In</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextField rows={4} multiline className="form-input" fullWidth label="Promo Description..." name="promoDescription" value={memberShip?.promoDescription} onChange={(e) => membershipChangeHandler(e)} />
          </Grid>

        </Grid>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={2} mb={2}>
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='contained'
            color='primary'
            onClick={submitHandler}
            loading={loading}
            disabled={loading}
          >
                        Save
          </MDButton>
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={()=>navigate('/memberships')}
          >
                        Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box>
  );
}