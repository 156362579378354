import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, Tab } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const MenuTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation()
  const navigate = useNavigate()
  let roleTab = searchParams.get('roleTab')
  const fullRoleTab = useMemo(() => searchParams.get('roleTab'), [searchParams]);
  const tabValue = useMemo(() => {
    if (fullRoleTab) {
      const segments = fullRoleTab.split('/');
      return segments[0];
    }
    return 'map';
  }, [fullRoleTab]);

  useEffect(() => {
    if (!roleTab) {
      setSearchParams({ roleTab: 'map' })
    }
  }, [roleTab])

  return (
    <Box className='hd-tabs'>
      <TabContext value={tabValue}>
        <TabList
          onChange={(e, value) => {
            if (pathname.includes('site-setting')) {
              navigate(`/${process.env.REACT_APP_MODE}/hyperdrive/home?roleTab=${value}`)
              return;
            }
            setSearchParams({ roleTab: value })
          }}
        >
          <Tab label='Map' value='map' />
          <Tab label='Table' value='table' className={roleTab === 'table' ? 'table' : 'archive'} />
          <Tab label='Analyze' value='analyze' />
          <Tab label='Route Analytics' value='route_analytics' />
          <Tab label='Driver Analytics' value='driver_analytics' />
        </TabList>
      </TabContext>
    </Box>
  )
}

export default MenuTabs