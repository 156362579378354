import React, { useEffect, useState } from 'react'
import { Typography, List, ListItem, FormControl, FormControlLabel, Box, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import { useSelector } from 'react-redux';
import { fetchRegionList } from '../../../redux/slices/hyperdrive/driverAnalytics';
import Loader from 'common/Loader';
import { regionDataCustom } from '../../../redux/slices/modalSlice';

const RegionListDrawer = ({ sendDataToParent = () => { }, onValueChange }) => {
    const dispatch = useDispatch();
    const { regionData } = useSelector(state => state.modal);
    const [selectedValueBreaks, setSelectedValueBreaks] = useState(regionData);
    const { regionList = {}, loading: loading = false } = useSelector(state => state.driverAnalyticsHyperDrive)

    useEffect(() => {
        dispatch(fetchRegionList())
    }, [])

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }

    const handleChangeTaskBreaks = (event, id) => {
        if (event.target.checked) {
            setSelectedValueBreaks(prev => [...prev, id]);
        } else {
            setSelectedValueBreaks(prev => prev.filter(value => value !== id));
        }
    };
    
    useEffect(() => {
        onValueChange(selectedValueBreaks);
        dispatch(regionDataCustom({
            region: 'REGION_DATA',
            regionData: selectedValueBreaks
        }))
    }, [selectedValueBreaks])

    return (
        <div className='next-drawer'>
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Region List</Typography>
                </div>
                <Typography style={{ cursor: 'pointer' }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>
            {
                loading ? <Box className='drawer-scroll-sec'>
                    <div className='d-flex align-item-center jc-center h-100'><Loader /></div></Box> :
                    <Box className='drawer-scroll-sec'>
                        <Box p={2} className='hd-drivers-listing'>
                            <List>
                                {regionList?.regions?.map((data, idx) => (
                                    <React.Fragment key={idx}>
                                        <ListItem className='d-flex align-item-center jc-s-btwn gap-2'>
                                            <div className='d-flex align-item-center flex-row gap-2'>
                                                <div className='d-flex radio-select'>
                                                    <FormControl>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={selectedValueBreaks.includes(data?.id)} onChange={(e) => handleChangeTaskBreaks(e, data?.id)} />}
                                                            label='' className='mr-0' />
                                                    </FormControl>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <Typography variant='h6' className='text-14 text-white-700'>
                                                        {data?.name}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                        {loading && <p>Loading...</p>}
                                    </React.Fragment>
                                ))}
                            </List>
                        </Box>
                    </Box>
            }
        </div>
    );
};

export default RegionListDrawer;