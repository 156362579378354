import React from 'react'
import { Riple } from 'react-loading-indicators'

const Loader = () => {
    return (
        <div className='loading-Spinner'>
            <Riple color='#3135cc' size='medium' text='' textColor='' />
        </div>
    )
}

export default Loader
