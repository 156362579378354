export function validateOrderData({ products: orderArray, email, address }) {
    let orderError = {}
    const fieldError = {}
    let isValid = true;

    for (let i = 0; i < orderArray.length; i++) {
        const orderObj = orderArray[i];
        const { errors: objectErrors, isValid: objectIsValid } = validateOrder(orderObj, i);

        if (!objectIsValid) {
            orderError = { ...orderError, ...objectErrors }
            isValid = false;
        }
    }
    if (!email) {
        fieldError.email = 'Email is required';
        isValid = false;
    }

    if (typeof email !== 'string' || !validateEmail(email)) {
        fieldError.email = 'Enter valid email';
        isValid = false;
    }
    if (!address || address.trim() === '') {
        fieldError.address = 'Address is required';
        isValid = false;
    }
    return { errors: [], orderError, fieldError, isValid };
}


function validateOrder(orderObj, index) {
    const errors = {};
    let isValid = true;

    // Validate terpeneName
    if (!orderObj?.productId || orderObj?.productId?.trim() === '') {
        errors.productId = 'Product  is required.';
        isValid = false;
    }
    // uValidate terpeneValue
    if (!orderObj?.quantity) {
        errors.quantity = 'Quantity is required.';
        isValid = false;
    }
    return { errors: { [index]: { ...errors } }, isValid };
}

function validateEmail(email) {
    const pattern = /^\S+@\S+\.\S+$/;
    return pattern.test(email);
}