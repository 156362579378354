import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  Card,
  Box,
  Stack,
  FormHelperText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MDButton from 'components/MDButton';
import { addInventoryItem, deleteInventoryItem, updateInventoryItem } from '../../redux/slices/inventory';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'utilities/common';
import { openSnackbar } from '../../redux/slices/common';
import { validateRequiredFields } from 'validations/inventory';
import { closeCustomModalDialog, openCustomModalDialog } from '../../redux/slices/modalSlice';
import { fetchAllRegions } from '../../redux/slices/common';

const AddInventoryForm = () => {
  const dispatch = useDispatch()
  const { inventoryId = '' } = useParams()
  const { regions = [] } = useSelector(state => state.common)
  const { inventoryDetails = [], loading = false } = useSelector(state => state.inventory)
  const [inventoryName, setInventoryName] = useState('');
  const [status, setStatus] = useState('Active');
  const [regionId, setRegionId] = useState('');
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const handleInventoryNameChange = (event) => {
    setInventoryName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleRegionIdChange = (event) => {
    setRegionId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, error } = validateRequiredFields({ inventoryName, status, regionId })
    if (!isValid) {
      setErrors(error)
      return;
    }
    setErrors({})
    const payload = {
      inventoryName,
      status,
      regionId
    }
    if (inventoryId) {
      dispatch(updateInventoryItem({ data: payload, inventoryId })).unwrap().then((res) => {
        showError(res?.message, 'success')
        navigate('/inventories')
      }).catch((err) => {
        showError(err?.message, 'error')
      })
    } else {
      dispatch(addInventoryItem(payload)).unwrap().then((res) => {
        showError(res?.message, 'success')
        navigate('/inventories')
      }).catch((err) => {
        showError(err?.message, 'error')
      })
    }
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const deleteHandler = () => {
    dispatch(setModalLoader(true))
    dispatch(deleteInventoryItem(inventoryId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      navigate('/inventories')
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  useEffect(() => {
    if (inventoryDetails && !isEmptyObject(inventoryDetails) && inventoryId) {
      const { inventoryName, regionId, status } = inventoryDetails || {}
      setInventoryName(inventoryName)
      setRegionId(regionId)
      setStatus(status)
    }
  }, [inventoryDetails])

  useEffect(() => {
    dispatch(fetchAllRegions())
  }, [])

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
        <Typography variant='h4' fontWeight='semibold' color='secondary' mb={4.5}>Add Inventory</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2.5} mb={4.5}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                className='form-input'
                label='Inventory Name*'
                variant='outlined'
                fullWidth
                value={inventoryName}
                error={Boolean(errors?.inventoryName)}
                helperText={errors?.inventoryName}
                onChange={handleInventoryNameChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel>Region*</InputLabel>
                <Select
                  className='form-select'
                  sx={{ height: '45px' }}
                  value={regionId}
                  onChange={handleRegionIdChange}
                  label='Region*'
                  error={Boolean(errors?.regionId)}
                >
                  {
                    regions?.regions?.map((region => {
                      return <MenuItem key={region.regionId} value={region.regionId}>{region.regionName}</MenuItem>
                    }))
                  }
                </Select>
                {Boolean(errors?.regionId) && <FormHelperText error>{errors?.regionId}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  className='form-select'
                  sx={{ height: '45px' }}
                  value={status}
                  onChange={handleStatusChange}
                  label='Status'
                  error={Boolean(errors?.status)}
                >
                  <MenuItem value='Active'>Active</MenuItem>
                  <MenuItem value='Inactive'>Inactive</MenuItem>
                  {/* Add other status options */}
                </Select>
                {Boolean(errors?.status) && <FormHelperText error>{errors?.status}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
            <MDButton
              sx={{ minWidth: '140px' }}
              type='submit'
              variant='contained'
              color='primary'
              loading={loading}
            >
              {inventoryId ? 'Update' : 'Save'}
            </MDButton>
            {
              inventoryId && <MDButton
                sx={{ minWidth: '140px', backgroundColor: '#F1F1F1', color: '#FD4438' }}
                type='button'
                variant='contained'
                color='error'
                onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: inventoryId }, modal: 'DELETE_MODAL' }))}
              >
                Delete
              </MDButton>
            }
            <MDButton
              sx={{ minWidth: '140px' }}
              type='button'
              variant='outlined'
              color='dark'
              onClick={() => navigate('/inventories')}
            >
              Cancel
            </MDButton>
          </Stack>
        </form>
      </Card>
    </Box>
  );
};

export default AddInventoryForm;