import { isEmptyObject } from 'utilities/common';

export function driverValidationHemp(fields) {

    const errors = {};
    if (!fields.fleetName) {
        errors.fleetName = 'Driver name is required'
    }

    if (!fields.fleetPhone) {
        errors.fleetPhone = 'Driver phone number is required'
    }

    if (!fields.fleetEmail) {
        errors.fleetEmail = 'Driver email is required'
    }

    if (!fields.fleetTransportationTypeId) {
        errors.fleetTransportationTypeId = 'Transportation type is required'
    }
    return { errors, isValid: isEmptyObject(errors) };
}
