export function validateRequiredFields(fieldObj) {
    const requiredFields = ['inventoryName', 'status', 'regionId'];
    const errors = {};

    // Check if all required fields are present
    for (const field of requiredFields) {
        if (!fieldObj.hasOwnProperty(field) || fieldObj[field] === null || fieldObj[field] === undefined || fieldObj[field] === '') {
            errors[field] = `${field === 'regionId' ? 'Region' : field === 'inventoryName' ? 'Inventory Name' : field} is required`;
        }
    }

    // If there are errors, return the error object with isValid set to false
    if (Object.keys(errors).length > 0) {
        return { isValid: false, error: errors };
    }

    // If all required fields are present, return the result object with isValid set to true
    return { isValid: true, error: null };
}


export function validateProductInventory(fieldObj) {
    // Define the required keys
    const requiredFields = ['fromInventory', 'toInventory', 'quantity'];

    const errors = {};

    // Check if all required fields are present
    for (const field of requiredFields) {
        if (!fieldObj.hasOwnProperty(field) || fieldObj[field] === null || fieldObj[field] === undefined || fieldObj[field] === '') {
            // errors[field] = (field==='fromInventory' || field==='toInventory' ) ? 'This field is required': `${field} is required`;
            errors[field] = 'This field is required'

        }
        if(field==='quantity' &&  fieldObj[field] === '0'){
            errors[field] = 'Quantity should be greater than 0'
        }
    }

    // If there are errors, return the error object with isValid set to false
    if (Object.keys(errors).length > 0) {
        return { isValid: false, error: errors };
    }

    // If all required fields are present, return the result object with isValid set to true
    return { isValid: true, error: null };
}
