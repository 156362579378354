import { Navigate } from 'react-router-dom';
import { getData } from 'utilities/common';
import { isLoggedIn } from 'utilities/common';
import { HYPERDRIVE } from 'utilities/constants';
import { HYPERWOLF } from 'utilities/constants';

export const PublicRoutes = ({ children }) => {
  // Check if the user is logged in
  let user = isLoggedIn('userData');
  let localUser = getData('login-user-info');
  if (localUser) {
    localUser = JSON.parse(localUser);
  }
  let { isAuthenticated } = user;
  // Render children if not authenticated, otherwise redirect to the dashboard
  return !isAuthenticated ? children : <Navigate to={JSON.parse(getData('adminsMode')) === HYPERDRIVE ? `/${process.env.REACT_APP_MODE}/hyperdrive/home?roleTab=map` : JSON.parse(getData('adminsMode')) === HYPERWOLF ? '/manage-cannabinoids?cannabinoidTab=main_cannabinoid' : localUser?.userRoles?.includes('Super Admin') || localUser?.userRoles?.includes('Developer') ? '/dashboard' : localUser?.userRoles?.includes('Sales Associate') || localUser?.userRoles?.includes('Customer Support') ? '/orders?orderFilter=' : localUser?.userRoles?.includes('Sales Associate') || localUser?.userRoles?.includes('Developer') ? '/dashboard' : '/products'} />;
};