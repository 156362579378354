import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import successMarker from '../../../assets/images/icons/marker/redmark.svg';
import returnToHqMarker from '../../../assets/images/icons/marker/return-to-head.svg';
import unassignedMarker from '../../../assets/images/icons/marker/greymark.svg';
import breakTaskMarker from '../../../assets/images/icons/marker/break_task.svg';
import darkMarker from '../../../assets/images/icons/marker/greenmark.svg';
import notStartedMarker from '../../../assets/images/icons/marker/yellowmarker.svg';
import inTransitMarker from '../../../assets/images/icons/marker/purpulemark.svg';
import driverIdle from '../../../assets/images/icons/driversMarker/driver-idle.svg';
import driverOffDuty from '../../../assets/images/icons/driversMarker/off-duty.svg';
// import onTransit from '../../../assets/images/icons/driversMarker/on-transit.svg';
// import onBreak from '../../../assets/images/icons/driversMarker/on-break.svg';
import { useDispatch } from 'react-redux';
import { openCustomDrawer } from '../../../redux/slices/modalSlice';
import { fetchAllDriversLocations, fetchLiveDriversLocations } from '../../../redux/slices/hyperdrive/drivers';
import { useSelector } from 'react-redux';
import H from '@here/maps-api-for-javascript';
import { formatTimestampToMapOld } from 'utilities/common';
import { formatTimestampTo12HourTimeUtc } from 'utilities/common';
import arrowIcon from '../../../assets/images/icons/marker/arrowIcon.svg';
import onBreak from '../../../assets/images/break.svg';
import onTransit from '../../../assets/images/inProgress.svg';
import { getUniqueByLatLng } from 'utilities/common';

// Function to get the appropriate icon based on the task status
const getIconbyStatus = (status) => {
  switch (status) {
    case 'completed':
      return darkMarker; // Icon for completed tasks
    case 'in_progress':
      return inTransitMarker; // Icon for in-progress tasks
    case 'not_started':
      return notStartedMarker; // Icon for pending tasks
    case 'cancelled':
      return successMarker; // Icon for cancelled tasks
    case 'startTask':
      return inTransitMarker; // Icon for start tasks
    case 'breakTask':
      return breakTaskMarker; // Icon for break tasks
    case 'returnToHeadquarterTask':
      return returnToHqMarker; // Icon for return to HQ tasks
    case 'unassigned':
      return unassignedMarker; // Icon for unassigned tasks
    default:
      return darkMarker; // Default icon if no status is available
  }
};

// Function to create a DOM icon with a count overlay
function getDomIcon(count, marker) {
  var outerElement = document.createElement('div'),
    imageElement = document.createElement('img'),
    countElement = document.createElement('div');

  outerElement.style.position = 'relative';  // Relative position for correct count placement
  outerElement.style.userSelect = 'none';
  outerElement.style.webkitUserSelect = 'none';
  outerElement.style.msUserSelect = 'none';
  outerElement.style.mozUserSelect = 'none';
  outerElement.style.cursor = 'pointer';

  // Configure the image element (marker image)
  imageElement.src = marker;

  // Configure the count element (text over marker)
  countElement.style.color = '#ffffff';
  countElement.style.borderRadius = '50%';
  countElement.style.fontSize = '12px';
  countElement.style.fontWeight = 'bold';
  countElement.style.textAlign = 'center';
  countElement.style.width = '18px';
  countElement.style.height = '18px';
  countElement.style.lineHeight = '18px';
  countElement.style.position = 'absolute';
  countElement.style.left = '50%';
  countElement.style.top = '4px';
  countElement.style.transform = 'translateX(-50%)';

  // Set the count text
  countElement.innerHTML = count > 9 ? '9+' : count;

  // Append the image and count to the outer container
  outerElement.appendChild(imageElement);
  if (count > 1) {
    outerElement.appendChild(countElement);
  }
  return outerElement;
}

// Main component for the map
const MapDrive = ({ dataPassComponent, driverZoomOnMapRef = {} }) => {
  const dispatch = useDispatch();
  const { driversLocation = {}, loading = false, liveDriverLocations = {}, isFetchingLiveDrivers = false } = useSelector(state => state.drivers);

  const mapRef = useRef(null);
  const mapKey = process.env.REACT_APP_MAP_KEY;
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const mapUIRef = useRef(null);
  const defaultLayersRef = useRef(null);
  const [taskDetailFetch, setTaskDetailFetch] = useState('');
  const selectedPopup = useRef({});
  const clusterLayerRef = useRef([]);
  const platformRef = useRef(null);
  const bubblesRef = useRef([]);
  const [bubbles, setBubbles] = useState('');
  const polylineRef = useRef('');
  const polyLineMarkerRef = useRef([])

  // Function to remove existing info windows
  const removeExistingInfoWindow = () => {
    if (bubblesRef?.current.length > 0) {
      bubblesRef?.current?.forEach((bubble) => {
        mapUIRef.current.removeBubble(bubble);
      });
      if (polylineRef.current) {
        polylineRef.current.setVisibility(false);
      }
      bubblesRef.current = [];
    }
    if (typeof selectedPopup?.current?.removeBubbleHanlder === 'function') {
      selectedPopup.current?.removeBubbleHanlder();
    }
  };

  async function addPolylineToMap(map, driverLocations) {
    let lineString = new H.geo.LineString();

    // Add the driver's location as the starting point of the polyline
    lineString.pushPoint({ ...driverLocations?.driverLocation });

    // Add each task location to the polyline
    driverLocations?.taskLocations?.forEach((taskLocation) => {
      lineString.pushPoint({ ...taskLocation });
    });


    let points = [driverLocations?.driverLocation, ...driverLocations?.taskLocations]
    polylineRef.current = new H.map.Polyline(
      lineString,
      {
        style: {
          lineWidth: 4,
          strokeColor: '#818183',
        },

      }
    );

    // Add the polyline to the map
    map.addObject(polylineRef.current);

    function createRotatedArrowIcon(angle) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const image = new Image();
      image.src = arrowIcon; // Replace with your arrow icon image URL

      return new Promise((resolve) => {
        image.onload = () => {
          const width = image.width;
          const height = image.height;

          canvas.width = width;
          canvas.height = height;

          // Translate to the center of the canvas
          ctx.translate(width / 2, height / 2);
          // Rotate the canvas to the desired angle (bearing)
          ctx.rotate(toRadians(angle - 90)); // Subtract 90 to rotate the arrow correctly
          // Draw the image (rotated)
          ctx.drawImage(image, -width / 2, -height / 2);

          // Create a new icon from the canvas
          const icon = new H.map.Icon(canvas);
          resolve(icon);
        };
      });
    }

    // Function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Function to calculate bearing between two points
    function calculateBearing(first, second) {
      const startLat = toRadians(first.lat);
      const startLng = toRadians(first.lng);
      const endLat = toRadians(second.lat);
      const endLng = toRadians(second.lng);

      const deltaLng = endLng - startLng;

      const y = Math.sin(deltaLng) * Math.cos(endLat);
      const x = Math.cos(startLat) * Math.sin(endLat) -
        Math.sin(startLat) * Math.cos(endLat) * Math.cos(deltaLng);

      const bearing = Math.atan2(y, x);
      return (toDegrees(bearing) + 360) % 360;
    }
    // Add arrows (markers) along the polyline
    for (let i = 0; i < points.length - 1; i++) {
      let first = points[i];
      let second = points[i + 1];
      let center = {
        lat: (first.lat + second.lat) / 2,
        lng: (first.lng + second.lng) / 2
      };

      let bearing = (calculateBearing(first, second)) - 90;
      const newIcon =  createRotatedArrowIcon(bearing);

      // Create the marker and set its rotation
      let marker = new H.map.Marker(center, { icon: newIcon });
      polyLineMarkerRef.current.push(marker)

      // Add the marker to the map
      map.addObject(marker);
    }
    // addArrowMarkers(lineString, map);
  }

  // Memoized function to get driver locations
  const memoizeDriversLocation = useMemo(() => {
    const locations = liveDriverLocations?.length > 0 ? liveDriverLocations?.map((driver => {
      return {
        lat: driver.latitude || 33.8254462,
        lng: driver.longitude || -117.5636722,
        status: driver?.driverTaskStatus === 'in_progress' ? '1' : driver?.driverTaskStatus === 'offduty' ? '2' : driver?.driverTaskStatus === 'onBreak' ? '4' : '3',
        fleetId: driver.fleetId,
        driverName: driver.fleetName,
        fleetVehicleDetails: driver?.fleetVehicleDetails,
      };
    })) || [] : [];
    return locations;
  }, [liveDriverLocations]);

  // Function to filter tasks by location
  const filterTaskbyLocation = (location = {}) => {
    const taskLists = [];
    driversLocation?.data?.length > 0 ? driversLocation?.data?.forEach((driver => {
      driver?.tasks?.forEach((_tasks => {
        taskLists.push(_tasks);
      }));
    })) : [];
    let fitlerTasks = [];
    driversLocation?.data?.filter((driver => driver?.tasks))?.forEach((_tasks => {
      _tasks?.tasks?.forEach((taskLocation => {
        if (taskLocation?.taskType === 'breakTask') {
          fitlerTasks?.push(taskLocation);
        } else {
          if (Number(taskLocation?.address?.latitude)?.toFixed(5) === Number(location?.lat)?.toFixed(5) && Number(taskLocation?.address?.longitude)?.toFixed(5) === Number(location?.lng)?.toFixed(5)) {
            fitlerTasks?.push(taskLocation);
          }
        }

      }));
    }));
    return fitlerTasks;
  };

  // Memoized function to get driver task locations
  const memoizeDriversTasksLocation = useMemo(() => {
    const taskLists = [];
    driversLocation?.data?.length > 0 ? driversLocation?.data?.forEach((driver => {
      driver?.tasks?.forEach((_tasks => {
        taskLists.push(_tasks);
      }));
    })) : [];
    let taskPoints = taskLists?.map((_taskItem => {
      return {
        lat: _taskItem?.address?.latitude || '',
        lng: _taskItem?.address?.longitude || '',
        status: _taskItem?.taskStatus,
        taskType: _taskItem?.taskType,
        fleetId: _taskItem?.fleetId
      };
    }));
    return taskPoints;
  }, [driversLocation?.data]);

  // Effect to initialize the map
  useEffect(() => {
    // Check if the HERE Maps API is available
    if (!H) return;

    // Initialize the HERE Maps platform with the provided API key
    const platform = new H.service.Platform({
      apikey: mapKey,
    });
    platformRef.current = platform;

    // Configure the raster tile service for the map
    const rasterTileService = platform.getRasterTileService({
      queryParams: {
        'style': 'explore.night', // Night mode for the map
        'features': 'vehicle_restrictions:disabled,pois:disabled', // Disable vehicle restrictions and points of interest
        size: 256, // Size of the tile
      }
    });
    const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
    const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

    // Create default layers for the map
    const defaultLayers = platform.createDefaultLayers();
    defaultLayersRef.current = defaultLayers;

    // Initialize the map with the raster tile layer and default settings
    mapInstance.current = new H.Map(
      mapRef.current,
      rasterTileLayer,
      {
        zoom: 9.5, // Initial zoom level
        center: { lat: 34.0549, lng: -118.2426 }, // Initial center of the map
        // center: { lat: 30.7333, lng: 76.7794 }, // Initial center of the map
        pixelRatio: window.devicePixelRatio || 1 // Adjust pixel ratio for high DPI screens
      }
    );
    rasterTileLayer.setMin(3); // Set the minimum zoom level for the raster tile layer

    // Enable map events
    new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

    // Create the UI for the map
    const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers);
    mapUIRef.current = ui;
    ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL); // Set the unit system to Imperial
    ui.removeControl('mapsettings'); // Remove the map settings control

    // Event listener for map view change end
    // mapInstance.current.addEventListener('mapviewchangeend', () => {
    //   // Forces the map to redraw its viewport
    // });

    // Event listener for map tap
    mapInstance.current.addEventListener('tap', () => {
      removeBubble(); // Remove any existing bubble on map tap
    });

    // Cleanup function to dispose of the map instance
    return () => {
      mapInstance.current.dispose();
    };
  }, [mapKey]);

  useEffect(() => {
    driverZoomOnMapRef.current.zoomOnDriver = (driverInfo) => {
      const { latitude: lat, longitude: lng } = driverInfo || {};
      // Center the map at the specified location
      mapInstance.current.setCenter({ lat, lng });
      // Adjust the zoom level
      mapInstance.current.setZoom(14);
      showDriverInfo({ driverName: driverInfo?.driverName, lat, lng });
    };
  }, []);

  // Function to remove markers from the map
  const removeMarkers = () => {
    markersRef?.current?.length > 0 && markersRef?.current?.forEach((marker) => {
      mapInstance.current.removeObject(marker);
    });
    markersRef.current = [];
  };

  const removePolylineMarkers = () => {
    polyLineMarkerRef?.current?.length > 0 && polyLineMarkerRef?.current?.forEach((marker) => {
      mapInstance.current.removeObject(marker);
    });
    polyLineMarkerRef.current = [];
  };
  // Function to remove bubbles from the map
  const removeBubble = useCallback(() => {
    if (bubbles?.length > 0) {
      bubbles.forEach((bubble => {
        mapUIRef.current.removeBubble(bubble);
      }));
    }
  }, [bubbles]);

  // Function to get a custom cluster theme
  const getCustomClusterTheme = () => {
    return {
      /**
       * This function generates a marker for a cluster of points on the map.
       * It iterates through each point in the cluster to determine the appropriate icon based on the task status.
       * The icon is then used to create a DOM icon, which is in turn used to create a DOM marker.
       * The marker is positioned at the cluster's position and its zoom levels are set based on the cluster's minimum and maximum zoom levels.
       * The cluster data is attached to the marker.
       * 
       * @param {Object} cluster - The cluster of points for which to generate a marker.
       * @returns {Object} - The DOM marker representing the cluster.
       */
      getClusterPresentation: function (cluster) {
        let markerIconToUse = '';
        let isCompletedTask = false;
        let isCancelledTask = false;
        let isnotStartedTask = false;
        let taskStatus = [];
        cluster.forEachDataPoint((point) => {
          taskStatus.push(point?.getData()?.taskStatus);
        });
        isCompletedTask = taskStatus.every((task => task === 'completed'));
        isCancelledTask = taskStatus.every((task => task === 'cancelled'));
        isnotStartedTask = taskStatus.every((task => task === 'not_started'));
        cluster.forEachDataPoint((point) => {
          const {  taskType = '' } = point.getData();
          markerIconToUse = getIconbyStatus((taskType !== 'returnToHeadquarterTask' || taskType !== 'breakTask') ? (isCompletedTask ? 'completed' : isCancelledTask ? 'cancelled' : isnotStartedTask ? 'not_started' : !isCompletedTask && !isCancelledTask && !isnotStartedTask ? 'unassigned' : null) : taskType);
        });

        let domIcon = new H.map.DomIcon(getDomIcon(cluster?.getWeight(), markerIconToUse));

        let dataPointMarker = new H.map.DomMarker(cluster.getPosition(), {
          icon: domIcon,
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        });
        dataPointMarker.setData(cluster);
        return dataPointMarker;
      },
      /**
       * This function generates a marker for a noise point on the map.
       * It determines the appropriate icon based on the task status or task type of the noise point.
       * The icon is then used to create a DOM icon, which is in turn used to create a DOM marker.
       * The marker is positioned at the noise point's position and its minimum zoom level is set.
       * The noise point data is attached to the marker.
       * 
       * @param {Object} noisePoint - The noise point for which to generate a marker.
       * @returns {Object} - The DOM marker representing the noise point.
       */
      getNoisePresentation: function (noisePoint) {
        const { taskStatus, taskType = '' } = noisePoint.getData();
        let markerIconToUse = getIconbyStatus((taskType !== 'returnToHeadquarterTask' || taskType !== 'breakTask') ? taskStatus : taskType);
        let domIcon = new H.map.DomIcon(getDomIcon(noisePoint?.getWeight(), markerIconToUse));

        let clusterMarker = new H.map.DomMarker(noisePoint.getPosition(), {
          icon: domIcon,
          min: noisePoint.getMinZoom(),
        });
        clusterMarker.setData(noisePoint);

        return clusterMarker;
      }
    };
  };

  // Function to create and add a clustered layer
  const addClusteredLayer = (locations, theme, mapInstance, taskStatus = 1) => {
    // Create data points from the locations array
    let dataPoints = []
    locations?.forEach(location => {
      if (location?.lat && location?.lng) {
        dataPoints?.push(new H.clustering.DataPoint(
          Number(location?.lat)?.toFixed(5),
          Number(location?.lng)?.toFixed(5),
          null,
          { taskStatus: location?.status, taskType: location?.taskType }
        ))
      }
    }
    );

    // Create a clustered data provider with custom clustering options and theme
    let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 20, // Maximum distance between points to be considered a cluster
        minWeight: 1, // Minimum number of points to form a cluster
      },
      theme: getCustomClusterTheme() // Custom theme for the clusters
    });

    // Create a layer for the clustered data and add it to the map
    let layer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    clusterLayerRef.current.push(layer);
    mapInstance.current.addLayer(layer);

    // Add event listener for 'tap' events on the clustered data provider
    clusteredDataProvider.addEventListener('tap', (e) => {
      var marker = e.target;
      var point = marker.getData();
      var isCluster = point.isCluster();

      if (isCluster) {
        // If the tapped point is a cluster, gather task locations and show info message
        let taskLocations = [];
        point.forEachDataPoint((dataPoint) => {
          taskLocations?.push(filterTaskbyLocation(dataPoint.getPosition()));
        });
        showInfoMessage(e.target.getGeometry(), [...new Set([].concat(...taskLocations))]);
      } else {
        // If the tapped point is a noise point, show info message for the specific location
        showInfoMessage(e.target.getGeometry(), filterTaskbyLocation(point.getPosition(), taskStatus));
      }
    });
  };

  // Function to show driver locations on the map
  const showDriverLocations = () => {
    if (!H) return;
    removeMarkers();
    const driverIdleIcon = new H.map.Icon(driverIdle);
    const driverOffDutyIcon = new H.map.Icon(driverOffDuty);
    const onBreakIcon = new H.map.Icon(onBreak);
    const onTransitDriver = new H.map.Icon(onTransit);
    memoizeDriversLocation?.forEach(markerData => {
      if (markerData?.lat && markerData?.lng) {
        const marker = new H.map.Marker(
          { lat: markerData?.lat, lng: markerData?.lng },
          { icon: markerData?.status === '1' ? onTransitDriver : markerData?.status === '2' ? driverOffDutyIcon : markerData?.status === '4' ? onBreakIcon : driverIdleIcon }
        );
        marker.addEventListener('pointerenter', () => {
          const driver = driversLocation?.data?.filter((driver => driver?.fleetId === markerData?.fleetId));
          if (driver?.length > 0) {
            const driverTasks = driver?.find((driverItem => driverItem?.tasks.find((driverTask) => ((driverTask?.taskStatus === 'in_progress' || driverTask?.taskType === 'returnToHeadquarterTask')))));
            const inProgressTask = driverTasks?.tasks?.filter((driverItem => (driverItem?.taskType === 'deliveryTask' && driverItem?.taskStatus === 'in_progress'))) || [];
            let notStartedTask = driverTasks?.tasks?.filter((driverItem => driverItem?.taskType === 'deliveryTask' && driverItem?.taskStatus === 'not_started')) || [];
            let returnToHQ = driverTasks?.tasks?.filter((driverItem => driverItem?.taskStatus === 'returnToHeadquarterTask')) || [];
            let combineTasks = [...inProgressTask, ...notStartedTask, ...returnToHQ];
            let uniqueTasks = getUniqueByLatLng(combineTasks)

            if (uniqueTasks?.length > 0) {
              const allTasks = uniqueTasks?.map((taskItem => {
                return {
                  lat: taskItem?.address?.latitude,
                  lng: taskItem?.address?.longitude,
                };
              }));
              addPolylineToMap(mapInstance.current, { driverLocation: { lat: markerData?.lat, lng: markerData?.lng }, taskLocations: allTasks, taskType: '' });
            }
          }
        });
        marker.addEventListener('pointerleave', () => {
          if (polylineRef.current) {
            polylineRef.current.setVisibility(false);
          }
          if (polyLineMarkerRef.current.length > 0) {
            removePolylineMarkers()
          }
        });

        markersRef.current.push(marker);
        marker.addEventListener('tap', () => {
          showDriverInfo(markerData);
        }, false);
        mapInstance.current.addObject(marker);
      }
    });

    // Function to show driver info in an info bubble

  };

  const showDriverInfo = (driverInfo = {}) => {
    removeExistingInfoWindow();
    let content = `<div class='tasks-modal map-driver-info'>
      <Table class='hw-table' border='0' cellspacing='0' cellpadding='0'>
        <thead>
          <tr>
            <th> 
              <Typography class='w-100 d-inline-block text-black-400 text-12 text-left box-sizing-border'>Driver Name</Typography>
            </th>
            <th>
              <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Latitude</Typography>
            </th>
            <th>
              <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Longitude</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Typography class='w-100 ellipsis-one text-white-700 text-12' style='max-width: 200px'>${driverInfo?.driverName || '-'}</Typography>
            </td>
            <td>
              <Typography class='w-100 text-white-700 text-12'>${driverInfo?.lat || '-'}</Typography>
            </td>
            <td>
              <Typography class='w-100 text-white-700 text-12'>${driverInfo?.lng || '-'}</Typography>
            </td>
          </tr>
        </tbody>
      </Table>
     </div>`;
    const infoBubble = new H.ui.InfoBubble(driverInfo, {
      content
    });
    mapUIRef.current.addBubble(infoBubble);
    bubblesRef.current = [infoBubble];
    selectedPopup.current.removeBubbleHanlder = () => {
      mapUIRef.current.removeBubble(infoBubble);
    };
  };

  // Function to show task locations on the map
  const showTaskLocations = () => {
    if (!H) return;
    addClusteredLayer(memoizeDriversTasksLocation, null, mapInstance);
  };

  // Effect to handle window resize
  useEffect(() => {
    const resize = () => {
      if (mapInstance.current) {
        mapInstance.current.getViewPort().resize();
      }
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [loading]);

  // Effect to show driver locations when live driver data is fetched
  useEffect(() => {
    if (isFetchingLiveDrivers) return;
    showDriverLocations();
  }, [memoizeDriversLocation, isFetchingLiveDrivers, driversLocation?.data]);

  // Effect to show task locations when driver task data is fetched
  useEffect(() => {
    if (loading) return;
    if (mapInstance?.current && clusterLayerRef.current?.length > 0) {
      clusterLayerRef.current.forEach((cluster) => {
        mapInstance.current.removeLayer(cluster);
      });
    }
    showTaskLocations();
  }, [memoizeDriversTasksLocation, loading]);

  // Function to show info message in an info bubble
  const showInfoMessage = (markerData, tasks = []) => {
    removeExistingInfoWindow();
    // const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayersRef.current);

    const generateTableContent = (tasks) => {
      return tasks?.map(task => {
        const { recipientDetails = {}, taskType = '', taskStatus = '', address = {}, fleet = {}, taskName = '', taskStartTime, taskEndTime } = task || {};
        let taskAddress = address?.address1 || '-';

        return `
        <tr data-destination='${task?._id}' class='clickable-row'>
          <td>
            <Typography class='w-100 ellipsis-one text-white-700 text-12 d-flex align-items-center' style='max-width: 200px'>
              <img class='mr-2' src=${getIconbyStatus(taskType !== 'returnToHeadquarterTask' ? taskStatus : taskType)} /> 
              <span class='text-truncate'>${taskAddress}</span>
            </Typography>
          </td>
           <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${(taskType === 'breakTask' || taskType === 'returnToHeadquarterTask') ? taskName : taskType === 'startTask' ? 'Start Task' : recipientDetails?.name || '-'}</Typography></td>      
          <td><Typography class='w-100 ellipsis-one text-white-700 text-12'>${fleet?.fleetName || '-'}</Typography></td>
          <td><Typography class='w-100 d-inline-block text-white-700 text-12'>
          ${taskStatus === 'completed' ? `${`${taskStartTime ? formatTimestampTo12HourTimeUtc(taskStartTime) : '-'} - ${taskEndTime ? formatTimestampTo12HourTimeUtc(taskEndTime) : '-'}`}` :
            taskStatus === 'not_started' ? '-' :
              `${`${taskStartTime ? formatTimestampTo12HourTimeUtc(taskStartTime) : '-'} - ${taskStatus !== 'in_progress' ? taskEndTime ? formatTimestampTo12HourTimeUtc(taskEndTime) : '-' : '-'}`}`
          }</Typography></td>
          </tr>
          `;
      }).join('');
    };

    const content = `
      <div class='tasks-modal'>
        <Typography variant='h6' class='w-100 d-flex font-w-600 text-black-400 text-14 text-left px-3 py-2'>${tasks?.length || 0}  Tasks</Typography>
        <Table class='hw-table' border='0' cellspacing='0' cellpadding='0'>
          <thead>
            <tr>
              <th> 
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left box-sizing-border'>Destination</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Recipient</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Driver</Typography>
              </th>
              <th>
                <Typography class='w-100 d-inline-block text-black-400 text-12 text-left'>Date/Time</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            ${generateTableContent(tasks)}
          </tbody>
        </Table>
      </div>`;

    const infoBubble = new H.ui.InfoBubble(markerData, {
      content
    });

    mapUIRef.current.addBubble(infoBubble);
    setBubbles([infoBubble]);
    selectedPopup.current.removeBubbleHanlder = () => {
      mapUIRef.current.removeBubble(infoBubble);
    };

    const clickableRows = document.querySelectorAll('.clickable-row');
    clickableRows.forEach(row => {
      row.addEventListener('click', (event) => {
        const destination = event.currentTarget.getAttribute('data-destination');
        setTaskDetailFetch(destination);
      });
    });
  };

  // Effect to open custom drawer when task detail is fetched
  useEffect(() => {
    if (taskDetailFetch) {
      dispatch(openCustomDrawer({
        drawer: 'TASK_DETAIL',
        drawerData: taskDetailFetch
      }));
      setTaskDetailFetch('');
    }
  }, [taskDetailFetch]);
  
  // Function to fetch driver tasks lists
  const fetchDriverTasksLists = useCallback(() => {
    if (!dataPassComponent?.endDate && !dataPassComponent?.startDate) return;
    dispatch(fetchAllDriversLocations({
      startDate: dataPassComponent?.startDate ? formatTimestampToMapOld(dataPassComponent?.startDate) : '',
      endDate: dataPassComponent?.endDate ? formatTimestampToMapOld(dataPassComponent?.endDate) : '',
      taskOrderDistance: 'asc',
      taskStatus: dataPassComponent?.taskStatus ? dataPassComponent?.taskStatus : 'not_started,in_progress,unassigned,completed,cancelled',
    })).unwrap().then(() => {
    }).catch(() => {
    });
  }, [dataPassComponent]);

  // Function to fetch live drivers locations
  const fetchDriversLocationList = useCallback(() => {
    if (!dataPassComponent?.endDate && !dataPassComponent?.startDate) return;
    dispatch(fetchLiveDriversLocations({
      driverStatus: dataPassComponent?.driverStatus ? dataPassComponent?.driverStatus : 'intransit,idle,onbreak',
    })).unwrap().then(() => {
    }).catch(() => {
    });
  }, [dataPassComponent]);

  // Effect to fetch driver tasks and live driver locations
  useEffect(() => {
    fetchDriverTasksLists();
    fetchDriversLocationList();
  }, [dataPassComponent]);

  // Effect to set interval for fetching live driver locations
  useEffect(() => {
    let interval = setInterval(() => {
      fetchDriversLocationList();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, [dataPassComponent]);

  return (
    <>
      <div className='hd-left-wrapper'>
        <div id='#mapContainer' ref={mapRef} style={{ width: '100%', height: '100%', backgroundColor: '#0F0F13', paddingLeft: '16px', paddingBottom: '16px' }}></div>
      </div>
    </>
  );
};
export default React.memo(MapDrive);
