import { isEmptyObject } from 'utilities/common';
export function validateCatalogProducts(products) {
    let errors = {};
    let isError = false;
    products.forEach((product, index) => {
        let errorMessages = {};
        // Validate totalQuantity if productBatches is empty
        if (product.productBatches.length === 0 && !Number(product.totalQuantity) ) {
            errorMessages = { ...errorMessages, totalQuantity: 'TotalQuantity is required.' }
        }
        // Validate productBatches if totalQuantity is empty
        if (!Number(product.totalQuantity) && product.productBatches.length === 0) {
            errorMessages = { ...errorMessages, totalQuantity: 'TotalQuantity is required.' }
        }
        // Validate purchasePrice
        if (product.purchasePrice === undefined || product.purchasePrice === '' || !product.purchasePrice) {
            errorMessages = { ...errorMessages, purchasePrice: 'PurchasePrice is required.' }
        }
        // Validate margin
        if (product.margin === undefined || product.margin === '' || !product.margin) {
            errorMessages = { ...errorMessages, margin: 'Margin is required.' }
        }
        // Validate salePrice
        if (product.salePrice === undefined || product.salePrice === '' || !product.salePrice) {
            errorMessages = { ...errorMessages, salePrice: 'SalePrice is required.' }
        }
        if (!isEmptyObject(errorMessages)) {
            errors[index] = errorMessages;
            isError = true;
        }
    });
    return { isError, errors };
}