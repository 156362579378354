import { Box, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography, Divider, IconButton } from '@mui/material'
import MDButton from 'components/MDButton'
import React, {  useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
import NoDataFound from 'common/component/NoDataFound'
import { fetchMembersDetails, updateMember } from '../../redux/slices/member'
import { closeCustomModalDialog } from '../../redux/slices/modalSlice'
import { openSnackbar } from '../../redux/slices/common'

export default function HotNotesModal({ closeModal }) {
    const { modalData = {} } = useSelector(state => state.modal)
    const { memberPayload = {}, memberId, allowAdd = true } = modalData
    const { loading = {} } = useSelector(state => state.members)

    const [isEditHotNotes, setisEditHotNotes] = useState(false)
    const [hotNotes, setHotNotes] = useState('')
    const dispatch = useDispatch()
    const [error, setError] = useState({})
    const { user = {} } = useSelector(state => state.auth)

    const orderNotsHandler = () => {
        if (!hotNotes) {
            setError({ hotNotes: 'Note is required' })
            return;
        }
        setError({})
        const payload = {
            ...memberPayload,
            hotNotes: [...(memberPayload?.hotNotes || []), {
                createdDate: new Date().valueOf(),
                note: hotNotes,
                userId: user?._id,
                userName: user?.name
            }]
        }
        dispatch(updateMember({ data: payload, memberId })).unwrap().then(() => {
            showResponseMessage("Note added successfully", 'success');
            dispatch(fetchMembersDetails(memberId))
            dispatch(closeCustomModalDialog({}))
        }).catch((err) => {
            showError(err?.message, 'error');
        });
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    return (
        <>
            <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between" className='hot-notes-modal'>
                Hot Notes
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    isEditHotNotes && <div className="modal-field-sec">
                        <TextField
                            className="form-input"
                            name='notes'
                            placeholder='Write Order Notes'
                            multiline
                            rows={4}
                            fullWidth
                            value={hotNotes}
                            onChange={(e) => setHotNotes(e.target.value)}
                            error={Boolean(error?.hotNotes)}
                            helperText={error?.hotNotes}
                        />
                    </div>
                }
                {
                    !isEditHotNotes && <>
                        <Box>
                            {
                                memberPayload?.hotNotes?.length > 0 ? <>
                                    {
                                        memberPayload?.hotNotes?.map(((noteItem, noteItemIdx) => {
                                            return <>
                                                <Box>
                                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                                        <Stack direction='row' gap={1}>
                                                            <Typography className='fs-small'>Name:</Typography>
                                                            <Typography className='fs-small' variant='' fontWeight="bold">{noteItem?.userName}</Typography>
                                                        </Stack>
                                                        <Stack direction='row' alignItems='center' gap={1}>
                                                            <Typography className='fs-small'>Date:</Typography>
                                                            <Typography className='fs-small' variant='' fontWeight="bold">{moment(noteItem?.createdDate)?.format('DD-MMM-YYYY')}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Typography className='fs-small line-height-1-5 text-grey-500 word-break-all' mt={1}>{noteItem?.note}</Typography>
                                                </Box>
                                                {
                                                    noteItemIdx !== (memberPayload?.hotNotes?.length - 1) && <Divider />
                                                }
                                            </>
                                        }))
                                    }
                                </>
                                    : <div className='no-data-found-modal'><NoDataFound text='No Notes Found' /></div>
                            }


                            {/* <Box>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Stack>
                    <Typography>Retailer ID</Typography>
                    <Typography>345543535</Typography>
                  </Stack>

                  <Stack>
                    <Typography>Date</Typography>
                    <Typography>2 Aug 2024</Typography>
                  </Stack>
                </Stack>
                <Typography>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur porro quisquam numquam eum ullam dolores at esse aut repudiandae. Quaerat.</Typography>
              </Box> */}
                        </Box>
                    </>
                }

            </DialogContent>
            {
                allowAdd && <DialogActions>
                    {
                        !isEditHotNotes && <Stack direction='row' justifyContent='center' alignItems='center' className="w-100" pt={2}>
                            <MDButton onClick={() => setisEditHotNotes(true)} color='primary' variant='outlined' style={{ width: 200 }}>
                                + Add Hot Note
                            </MDButton>
                        </Stack>
                    }

                    {
                        isEditHotNotes && <Stack direction="row" alignItems="center" justifyContent="center" gap="20px" className="w-100">
                            <MDButton variant='contained' color='primary' style={{ width: 190 }} onClick={() => orderNotsHandler()} loading={loading}>Add</MDButton>
                            <MDButton variant='outlined' color='primary' onClick={() => {
                                setisEditHotNotes(false)
                                setError({})
                                setHotNotes('')
                            }} style={{ width: 190 }} className="dark-outlined-btn">Cancel</MDButton>

                        </Stack>
                    }
                </DialogActions>
            }

        </>
    )
}