import React, { useEffect, useRef, useState } from 'react';
import { Card, Grid, TextField, Typography, FormHelperText, Stack, Badge, CircularProgress, InputAdornment, Button, Divider, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { createCategory, deleteCategory, updateCategory } from '../../redux/slices/category';
import { useNavigate, useParams } from 'react-router';
import { isEmptyObject } from 'utilities/common';
import { categoryValidations } from '../../validations/category';
import defaultImage from 'assets/images/placeholder-image.png';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import CommonQuillEditor from 'common/editor';
import { grey } from '@mui/material/colors';
import { AccountCircle, RemoveCircle } from '@mui/icons-material';
import { fetchCategories } from '../../redux/slices/common';

const initialState = {
  categoryName: '',
  status: 'active',
  description: '',
  productInstructions: '',
  categoryImage: '',
  metaTitle: '',
  metaDescription: '',
  canonical: '',
  heading: '',
  createdBy: '',
  bottomText: '',
  bannerImage: '',
  faq: []
};
const AddCategoryForm = () => {
  const { user } = useSelector(state => state.auth);
  const [formData, setFormData] = useState({ ...initialState, createdBy: user?.name });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryId = '' } = useParams();
  const { singleCategory = {}, loading = false } = useSelector((state => state.category));
  const { isUploading = false } = useSelector((state => state.common));
  const uploadedImageRef = useRef();
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const productImageUploadHandler = async (e, imageType) => {
    if (isUploading) return;
    uploadedImageRef.current = imageType;
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData();
      body.append('filePath', file);
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormData({ ...formData, [imageType]: res.fileUrl });
      }));
    }
  };

  const onSubmit = () => {
    const { isValid, errors } = categoryValidations(formData);
    if (!isValid) {
      setErrors(errors);
      return;
    }
    setErrors({});
    if (categoryId) {
      dispatch(updateCategory({ data: formData, categoryID: categoryId })).unwrap().then((res) => {
        showError(res?.message, 'success');
        dispatch(fetchCategories({ limit: 1000, skip: 0 }));
        navigate('/categories');
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    } else {
      dispatch(createCategory(formData)).unwrap().then((res) => {
        showError(res?.message, 'success');
        dispatch(fetchCategories({ limit: 1000, skip: 0 }));
        navigate('/categories');
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    }
  };

  const handleChangeFAQ = (value, fieldType, idx) => {
    let FAQArray = [...formData?.faq];
    let faqItem = FAQArray?.find(((faq, _idx) => _idx === idx));
    if (faqItem) {
      const faqItemIdx = FAQArray.indexOf(faqItem);
      faqItem = {
        ...faqItem,
        [fieldType]: value
      };
      FAQArray[faqItemIdx] = faqItem;
      setFormData({ ...formData, faq: FAQArray });
    }
  };

  const handleRemoveFAQ = (faQIdx) => {
    let FAQArray = [...formData?.faq];
    FAQArray = FAQArray?.filter(((faq, _idx) => _idx !== faQIdx));
    setFormData({ ...formData, faq: FAQArray });
  };
  const addFAQHandler = () => {
    let newFAQ = [...formData?.faq];
    let faqItem = {
      question: '',
      answer: ''
    };
    newFAQ = [...newFAQ, faqItem];
    setFormData({ ...formData, faq: newFAQ });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const deleteHandler = (categoryId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteCategory(categoryId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(fetchCategories({ limit: 1000, skip: 0 }));
      dispatch(closeCustomModalDialog());
      navigate('/categories');
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  useEffect(() => {
    if (!isEmptyObject(singleCategory)) {
      setFormData({ ...formData, ...singleCategory });
    }
  }, [singleCategory]);

  const uploadThumbnailImage = {
    borderRadius: '20px',
    width: '160px',
    height: '160px',
    objectFit: 'cover',
    boxShadow: '0 0 1px rgb(0 0 0 / 20%)'
  };
  const badgeCircularProgressIcon = {
    backgroundColor: '#fff',
    borderRadius: 10,
    p: .5,
    mr: 14,
    mt: 14
  };

  useEffect(() => {
    setFormData({ ...formData, createdBy: user?.name });
  }, [user]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: 'none' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent='space-between' gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight='semibold' color='secondary'>{!categoryId ? 'Add' : 'Update'} Sub Category</Typography>
          <Box display='flex' alignItems='center' gap={1.25}>
            <Typography variant='h5' fontWeight='semibold' color='secondary'>Status</Typography>
            <Stack direction='row' alignItems='center' gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor='#F2F3F7'>
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status === 'active' ? 'contained' : 'text'}
                color={formData?.status === 'active' ? 'success' : 'dark'}
                size='small'
                onClick={() => setFormData({ ...formData, status: 'active' })}
              >
                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status !== 'active' ? 'contained' : 'text'}
                color={formData?.status !== 'active' ? 'error' : 'dark'}
                size='small'
                onClick={() => setFormData({ ...formData, status: 'inactive' })}
              >
                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>
        <Typography variant='h5' fontWeight='semibold' mb={2}>Category Image*</Typography>
        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={1.25} mb={4.5}>
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
            badgeContent={
              isUploading && uploadedImageRef.current === 'categoryImage' ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ''
            }
          >
            <img style={uploadThumbnailImage} src={formData?.categoryImage || defaultImage} alt='documentId' />
          </Badge>
          <label htmlFor='categoryImage-input'>
            <MDButton
              sx={{ minWidth: '180px' }}
              type='button'
              variant='outlined'
              color='secondary'
              component='span'
              disabled={isUploading}
            >
              {formData?.categoryImage ? 'Change Image' : 'Add Image'}
            </MDButton>
          </label>
          <MDInput
            id='categoryImage-input'
            type='file'
            name='categoryImage'
            onChange={(e) => productImageUploadHandler(e, 'categoryImage')}
            fullWidth
            disabled={isUploading}
            style={{ display: 'none' }}
          />
          {Boolean(errors?.categoryImage) && <FormHelperText sx={{ width: '100%', ml: 0, mt: -.5 }} error>{errors?.categoryImage}</FormHelperText>}
        </Stack>

        <Typography variant='h5' fontWeight='semibold' mb={2}>Banner Image</Typography>
        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={1.25} mb={4.5}>
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
            badgeContent={
              isUploading && uploadedImageRef.current === 'bannerImage' ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ''
            }
          >
            <img style={uploadThumbnailImage} src={formData?.bannerImage || defaultImage} alt='documentId' />
          </Badge>
          <label htmlFor='bannerImage-input'>
            <MDButton
              sx={{ minWidth: '180px' }}
              type='button'
              variant='outlined'
              color='secondary'
              component='span'
              disabled={isUploading}
            >
              { formData?.bannerImage ? 'Change Image' : 'Add Image' }
            </MDButton>
          </label>
          <MDInput
            id='bannerImage-input'
            type='file'
            name='bannerImage'
            onChange={(e) => productImageUploadHandler(e, 'bannerImage')}
            fullWidth
            disabled={isUploading}
            style={{ display: 'none' }}
          />
          {Boolean(errors?.bannerImage) && <FormHelperText sx={{ width: '100%', ml: 0, mt: -.5 }} error>{errors?.bannerImage}</FormHelperText>}
        </Stack>
        <Typography variant='h5' fontWeight='semibold' mb={2}>Category Info</Typography>
        <Grid container spacing={2.5} mb={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Category Name*'
              value={formData.categoryName}
              fullWidth
              variant='outlined'
              onChange={handleInputChange}
              name='categoryName'
              className='form-input'
              error={Boolean(errors?.categoryName)}
              helperText={errors?.categoryName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' fontWeight='medium' color={grey[600]} mb={1}>Description*</Typography>
            <CommonQuillEditor
              value={formData?.description || ''}
              onChange={(value) => setFormData({ ...formData, description: value })}
            />
            {Boolean(errors?.description) && <FormHelperText error>{errors?.description}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' fontWeight='medium' color={grey[600]} mb={1}>Instructions*</Typography>
            <CommonQuillEditor
              value={formData?.productInstructions || ''}
              onChange={(value) => setFormData({ ...formData, productInstructions: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' fontWeight='medium' color={grey[600]} mb={1}>Bottom Text</Typography>
            <CommonQuillEditor
              value={formData?.bottomText || ''}
              onChange={(value) => setFormData({ ...formData, bottomText: value })}
            />
            {Boolean(errors?.bottomText) && <FormHelperText error>{errors?.bottomText}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} >
            <Box display='flex' justifyContent='space-between' alignItems={'center'}>
              <Typography variant='subtitle2' fontWeight='medium' color={grey[600]} mb={2}>FAQ</Typography>
              {
                formData?.faq?.length > 0 && <Button
                  sx={{ minWidth: '140px' }}
                  type='button'
                  onClick={addFAQHandler}
                  variant='contained'
                  color='primary'
                >Add More</Button>
              }
            </Box>
            {
              formData?.faq?.length > 0 ? <>
                {
                  formData?.faq?.map(((faq, idx) => {
                    return <Grid key={idx} item xs={12} sm={12} lg={12} my={2}>
                      <Grid container alignItems='flex-start'>
                        <Grid item xs={12} lg={6} md={6}>
                          <Box display='flex' justifyContent='center' alignItems={'flex-start'}>
                            <Box width={'100%'}>
                              <Box width={'100%'}>
                                <Typography variant='subtitle2' fontWeight='medium' color={grey[600]} mb={1}>Question</Typography>
                                <CommonQuillEditor
                                  allowImage={false}
                                  value={faq?.question || ''}
                                  onChange={(value) => handleChangeFAQ(value, 'question', idx)}
                                />
                              </Box>
                            </Box>
                            <Box>
                              <IconButton onClick={() => handleRemoveFAQ(idx)}>
                                <RemoveCircle color='error' />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container alignItems='flex-start' mt={2}>
                        <Grid item xs={12} lg={6} md={6}>
                          <Typography variant='subtitle2' fontWeight='medium' color={grey[600]} mb={1}>Answer</Typography>
                          <CommonQuillEditor
                            allowImage={false}
                            value={faq?.answer || ''}
                            onChange={(value) => handleChangeFAQ(value, 'answer', idx)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>;
                  }))
                }
              </> : <Box display='flex' justifyContent='space-between' alignItems={'center'} my={3}>
                <Button
                  sx={{ minWidth: '140px' }}
                  type='button'
                  onClick={addFAQHandler}
                  variant='contained'
                  color='primary'
                >Add FAQ</Button>
              </Box>
            }
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              label='Meta Title*'
              value={formData.metaTitle}
              fullWidth
              variant='outlined'
              onChange={handleInputChange}
              name='metaTitle'
              error={Boolean(errors?.metaTitle)}
              helperText={errors?.metaTitle}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              className='form-input created-by'
              label='Created By'
              value={formData.createdBy}
              fullWidth
              disabled
              variant='outlined'
              onChange={handleInputChange}
              name='createdBy'
              error={Boolean(errors?.createdBy)}
              helperText={errors?.createdBy}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle fontSize='medium' color='secondary' />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Meta Description'
              value={formData.metaDescription}
              fullWidth
              multiline
              rows={4}
              variant='outlined'
              onChange={handleInputChange}
              name='metaDescription'
              error={Boolean(errors?.metaDescription)}
              helperText={errors?.metaDescription}
              className='form-input'
            />
          </Grid>
        </Grid>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            onClick={onSubmit}
            variant='contained'
            color='primary'
            disabled={loading || isUploading}
            loading={loading || isUploading}
          >
            { categoryId ? 'Update' : 'Save'}
          </MDButton>
          {
            categoryId && <MDButton
              sx={{ minWidth: '140px', backgroundColor: '#F1F1F1', color: '#FD4438' }}
              type='button'
              variant='contained'
              color='error'
              onClick={() => {
                dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: categoryId }, modal: 'DELETE_MODAL' }));
              }}
            >
              Delete
            </MDButton>
          }
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => navigate('/categories')}
          >
            Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box >
  );
};

export default AddCategoryForm;