import React from 'react'
import { Riple } from 'react-loading-indicators'
import { Backdrop } from '@mui/material'

const HyperdriveLoading = () => {
    return (
        <div className='loading-Spinner'>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={true}
            >
                <Riple color='#3135cc' size='medium' text='' textColor='' />
            </Backdrop>
        </div>
    )
}

export default HyperdriveLoading