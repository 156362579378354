import { Autocomplete, Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';
import { overrideApproval } from '../../redux/slices/approvals';
import { fetchProducts } from '../../redux/slices/products';
import { useLocation, useNavigate } from 'react-router-dom';

export default function OverrideApproval({ closeModal }) {
  const { modalData,  isLoading = false } = useSelector(state => state.modal);
  const { isDeleting = false } = useSelector(state => state.common);
  const [allProductsData, setAllProductsData] = useState([]);
  const [errors, setErrors] = useState({});
  const { approvalId, retailerId, productId } = modalData;
  const searchFn = modalData.searchFn;
  const approvalRef = modalData.approvalRef;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedOption, setSelectedOption] = useState('');
  const route = pathname.split('/').slice(1)[0];

  useEffect(() => {
    dispatch(fetchProducts({ filters: { skip: 0, limit: 1000, userType: 'admin' } })).unwrap().then(res => {
      const { filteredProducts } = res;
      setAllProductsData(filteredProducts);
    });
  }, []);

  typeof (approvalRef), 'type o fapprovalREf';

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const handleOverrideApproval = () => {
    if (!selectedOption) {
      setErrors({ productName: 'Product Name should not be empty' });
      return;
    }
    setErrors({});
    dispatch(overrideApproval({ approvalId, data: { retailerId: retailerId, productId: productId, masterProductId: selectedOption.productId } })).unwrap()
      .then((res => {
        showResponseMessage(res?.message, 'success');
        dispatch(closeCustomModalDialog({}));
        if (typeof searchFn === 'function') {
          searchFn();
          approvalRef.current();
        }
        else if (route === 'approvals') {
          navigate('/approvals');
        }
        else {
          navigate(-1);
        }
      })).catch((err => {
        showResponseMessage(err?.message, 'error');
      }));
  };

  return (
    <>
      <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'  >
        Override Product
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent  >
        <Box mt={1}>
          <Autocomplete
            id='tags-outlined'
            options={allProductsData?.length > 0 ? allProductsData?.filter((productItem => productItem?.status === 'active')) : []}
            getOptionLabel={(option) => option?.productName}
            filterSelectedOptions
            onChange={(event, selectedOption) => {
              setSelectedOption(selectedOption); // Update the state with the selected option
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors?.errors?.[orderIdx]?.productId)}
                helperText={errors?.errors?.[orderIdx]?.productId}
                label='Select Product'
              />
            )}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={closeModal} size='small' color='secondary'>
          Cancel
        </MDButton>
        <MDButton onClick={handleOverrideApproval} size='small' color='error'>
          {
            isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : ' Update'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}