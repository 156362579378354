import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getSinglePromotionAPI, deletePromotionAPI, fetchPromotionListingAPI, updatePromotionAPI, createPromotionAPI, getPromotionProductRulesByTypeAPI, getPromotionCartRulesByTypeAPI, downloadPromotionAsPdfAPI } from "../../redux/apis/promotion";


export const createPromotion = createAsyncThunk(
    'promotion/createPromotion',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createPromotionAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);

export const updatePromotion = createAsyncThunk(
    'promotion/updatePromotion',
    async ({ data, promotionId }, { rejectWithValue }) => {
        try {
            const res = await updatePromotionAPI(data, promotionId);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);

export const downloadPromotionAsPdf = createAsyncThunk(
    'promotion/downloadPromotionAsPdf',
    async ( promotionId, { rejectWithValue }) => {
        try {
            const res = await downloadPromotionAsPdfAPI(promotionId);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);



export const fetchAllPromotion = createAsyncThunk(
    'promotion/fetchAllPromotion',
    async (data, { rejectWithValue }) => {
        try {
            const res = await fetchPromotionListingAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);


export const fetchSinglePromotion = createAsyncThunk(
    'promotion/fetchSinglePromotion',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getSinglePromotionAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);


export const deletePromotion = createAsyncThunk(
    'promotion/deletePromotion',
    async (data, { rejectWithValue }) => {
        try {
            const res = await deletePromotionAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);

export const getPromotionCartRulesByType = createAsyncThunk(
    'promotion/getPromotionCartRulesByType',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getPromotionCartRulesByTypeAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);

export const getPromotionProductRulesByType = createAsyncThunk(
    'promotion/getPromotionProductRulesByType',
    async (data, { rejectWithValue }) => {
        try {
            const res = await getPromotionProductRulesByTypeAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
);


// Create your promotionSlice
const promotionSlice = createSlice({
    name: 'promotion',
    initialState: {
        // Your initial state here
        isLoading: false,
        promotionRules: [],
        promotionDetail: {},
        error: null,
        promotions: {}

    },
    reducers: {
        // Other reducers if any
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPromotionCartRulesByType.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getPromotionCartRulesByType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.promotionRules = action.payload;
            })
            .addCase(getPromotionCartRulesByType.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Something went wrong';
            });

        builder
            .addCase(getPromotionProductRulesByType.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getPromotionProductRulesByType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.promotionRules = action.payload;
            })
            .addCase(getPromotionProductRulesByType.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Something went wrong';
            });
        builder
            .addCase(fetchAllPromotion.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllPromotion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.promotions = action.payload?.promotions;
                state.promotionDetail = {}
            })
            .addCase(fetchAllPromotion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Something went wrong';
            });
        builder
            .addCase(fetchSinglePromotion.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSinglePromotion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.promotionDetail = action.payload;
            })
            .addCase(fetchSinglePromotion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(createPromotion.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createPromotion.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(createPromotion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder
            .addCase(updatePromotion.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updatePromotion.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updatePromotion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export default promotionSlice.reducer;
