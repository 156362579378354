import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

export default function CancelOrderReason({ closeModal }) {
  const { modalData } = useSelector(state => state.modal);
  const { isLoading: submittingOrder = false } = useSelector(state => state.orders);

  const [cancelOrderReason, setCancelOrderReason] = useState('');
  const [errors, setErrors] = useState({});
  const { cancelOrder, cancelType = '' } = modalData || {};

  const submitHandler = () => {
    if (!cancelOrderReason) {
      setErrors({ cancelOrderReason: 'Reason is required' });
      return;
    }
    setErrors({});
    cancelOrder(cancelOrderReason, cancelType);
  };
  return (
    <>
      <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'>
        {cancelType !== 'cancelled' ? "Non-Returnable" : 'Cancellation'}  Reason
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box pt={1}>
          <TextField fullWidth className='form-input' multiline rows={3}
            error={Boolean(errors?.cancelOrderReason)}
            helperText={errors?.cancelOrderReason}
            onChange={(e) => setCancelOrderReason(e.target.value)} label={`${cancelType !== 'cancelled' ? "Non-Returnable" : 'Cancellation'} Reason`  } name='cancelOrderReason' value={cancelOrderReason}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={closeModal} size='small' color='secondary'>
          Cancel
        </MDButton>
        <MDButton onClick={submitHandler} size='small' color='primary'>
          {
            submittingOrder ? <CircularProgress size={16} color='light' /> : 'Save'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}