import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getProductDisclaimers, openSnackbar, updateDisclaimer } from '../../redux/slices/common';
import { Box, Card, Stack, Typography } from '@mui/material';
import EditIcon from 'common/customIcons/edit';
import MDButton from 'components/MDButton';
import CommonQuillEditor from 'common/editor';
import { grey } from '@mui/material/colors';

export default function ManageDisclaimers() {
  const { disclaimers, isLoading = false } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [disclaimersValue, setDisclaimers] = useState('');

  useEffect(() => {
    if (disclaimers?.productDisclaimer || disclaimers?.data?.productDisclaimer) {
      setDisclaimers(disclaimers?.productDisclaimer || disclaimers?.productDisclaimer || disclaimers?.data?.productDisclaimer);
    }
  }, [disclaimers]);

  const disclaimerUpdateHandler = () => {
    dispatch(updateDisclaimer({ productDisclaimer: disclaimersValue })).unwrap().then((res) => {
      showResponseMessage(res?.message || res?.data?.message, 'success');
      dispatch(getProductDisclaimers());
      setIsEdit(false);
    });
  };


  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };


  useEffect(() => {
    dispatch(getProductDisclaimers());
  }, []);
  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
          {
            isEdit ? <>
              <Stack direction={{ xs: 'column', sm: 'column' }} justifyContent="space-between" gap={2} mb={4.5}>
                <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Edit Disclaimer</Typography>
                <CommonQuillEditor
                  value={disclaimersValue || ''}
                  onChange={(value) => setDisclaimers(value)}
                />

              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} mb={4.5}>
                <MDButton
                  type='button'
                  variant='contained'
                  size="small"
                  color='primary'
                  style={{ width: 150, padding: 10 }}
                  onClick={disclaimerUpdateHandler}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Save
                </MDButton>

                <MDButton
                  type='button'
                  variant='outlined'
                  size="small"
                  color='primary'
                  style={{ width: 150, padding: 10 }}
                  onClick={() => {
                    setDisclaimers(disclaimers?.productDisclaimer || disclaimers?.productDisclaimer || disclaimers?.data?.productDisclaimer);
                    setIsEdit(false)
                  }}
                >
                  Cancel
                </MDButton>
              </Stack>

            </> : <>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
                <Typography variant='h4' fontWeight="semibold" color="secondary">Disclaimer</Typography>
                <MDButton
                  type='button'
                  variant='outlined'
                  color='dark'
                  size="small"
                  startIcon={<EditIcon width='15px' height='15px' />}
                  onClick={() => setIsEdit(true)}

                >
                  Edit
                </MDButton>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
                <div dangerouslySetInnerHTML={{ __html: disclaimersValue }}></div>
              </Stack>
            </>
          }

        </Card>
      </Box>
    </CommonLayout>
  );
}