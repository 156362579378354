import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrands } from '../../../redux/slices/brand';
import MDButton from 'components/MDButton';
import { isNumber } from 'utilities/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function hasAnyValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (
      (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
      (typeof obj[key] !== 'object' && obj[key] !== '')
    )
    ) {
      return true;
    }
  }
  return false;
}

export default function FilterRetailerProducts(props = {}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { retailerId } = useParams();
  const [formData, setFormData] = useState({ adminCategories: [], brands: [], activeProducts: 'all', price: { min: '', max: '' }, quantity: { min: '', max: '' } });
  const { categoriesData = [] } = useSelector(state => state.common);
  const { brandsData = {} } = useSelector(state => state.brands);
  const { applyProductFilter, getQuryValues = {}, setIsLoading = () => { } } = props;
  const isAllSelected = useMemo(() => {
    return categoriesData?.categories?.length > 0 && formData?.adminCategories.length === categoriesData?.categories?.length;
  }, [formData?.adminCategories, categoriesData?.categories]);

  const isAllBrandSelected = useMemo(() => {
    return brandsData?.brands?.length > 0 && formData?.brands.length === brandsData?.brands?.length;
  }, [brandsData?.brands?.length, formData?.brands.length]);

  const dispatch = useDispatch();

  const applyFiltersHandler = () => {
    const { adminCategories, brands, activeProducts, price, quantity } = formData || {};
    const categoryIds = adminCategories?.map((adminCate => categoriesData?.categories?.find((category => category?.categoryName === adminCate))?.categoryId)) || [];
    const brandSlug = brands?.map((brandValue => brandsData?.brands?.find((brand => brand?.brandName === brandValue))?.brandSlug)) || [];
    let queryPayload = {
      status: activeProducts,
      maxPrice: +price?.max,
      minPrice: +price?.min,
      maxQuantity: +quantity.max,
      minQuantity: +quantity.min
    };
    if (!queryPayload.maxPrice) {
      delete queryPayload.maxPrice;
    }
    if (!queryPayload.minPrice) {
      delete queryPayload.minPrice;
    }
    if (!queryPayload.maxQuantity) {
      delete queryPayload.maxQuantity;
    }
    if (!queryPayload.minQuantity) {
      delete queryPayload.minQuantity;
    }
    if (queryPayload.status === 'all') {
      queryPayload = { ...queryPayload, status: '' };
    }
    const payload = { categoryId: categoryIds, brandSlug };
    const searchParams = new URLSearchParams();
    let filterValue = { ...payload, ...queryPayload, filter: 'retailerProducts' } || {};
    Object.keys(filterValue).forEach((key => {
      if (key === 'filter' || key === 'status') {
        searchParams.append(key, filterValue[key]);
      }
      else {
        searchParams.append(key, JSON.stringify(filterValue[key]));
      }
    }));
    navigate({ search: searchParams.toString() });
    applyProductFilter({ queryPayload, payload });
  };

  useEffect(() => {
    if (location.search && categoriesData?.categories?.length && brandsData?.brands?.length) {
      const searchParams = new URLSearchParams(location.search);
      let queryPayload = {
        status: searchParams?.get('status') || '',
        maxPrice: searchParams?.get('maxPrice') ? Number(searchParams?.get('maxPrice')) : 0,
        minPrice: searchParams?.get('minPrice') ? Number(searchParams?.get('minPrice')) : 0,
        maxQuantity: searchParams?.get('maxQuantity') ? Number(searchParams?.get('maxQuantity')) : 0,
        minQuantity: searchParams?.get('minQuantity') ? Number(searchParams?.get('minQuantity')) : 0
      };
      if (!queryPayload.maxPrice) {
        delete queryPayload.maxPrice;
      }
      if (!queryPayload.minPrice) {
        delete queryPayload.minPrice;
      }
      if (!queryPayload.maxQuantity) {
        delete queryPayload.maxQuantity;
      }
      if (!queryPayload.minQuantity) {
        delete queryPayload.minQuantity;
      }
      let categoryIds = [];
      let categoryName = [];

      if (searchParams?.get('categoryId')) {
        categoryName = JSON.parse(searchParams?.get('categoryId'))?.map((adminCate => categoriesData?.categories?.find((category => category?.categoryId === adminCate))?.categoryName)) || [];
        categoryIds = JSON.parse(searchParams?.get('categoryId'))?.map((adminCate => categoriesData?.categories?.find((category => category?.categoryId === adminCate))?.categoryId)) || [];
      }
      let brandSlug = [];
      let brandName = [];

      if (searchParams?.get('categoryId')) {
        brandName = JSON.parse(searchParams?.get('brandSlug'))?.map((brandValue => brandsData?.brands?.find((brand => brand?.brandSlug === brandValue))?.brandName)) || [];
        brandSlug = JSON.parse(searchParams?.get('brandSlug'))?.map((brandValue => brandsData?.brands?.find((brand => brand?.brandSlug === brandValue))?.brandSlug)) || [];
      }
      const payload = { categoryId: categoryIds, brandSlug };
      setFormData({ adminCategories: categoryName, brands: brandName, activeProducts: queryPayload.status, price: { min: queryPayload?.minPrice, max: queryPayload?.maxPrice }, quantity: { min: queryPayload?.minQuantity, max: queryPayload?.maxQuantity } });

      getQuryValues({ queryPayload, payload });
    }

    if (!location.search) {
      setIsLoading(false);
    }

  }, [location.search, categoriesData?.categories, brandsData?.brands]);


  const clearAllHandler = () => {
    setFormData({ adminCategories: [], brands: [], activeProducts: '', price: { min: '', max: '' }, quantity: { min: '', max: '' } });
    navigate(`/retailers/detail/${retailerId}?filter=retailerProducts`);
    applyProductFilter({});
  };

  const showApplyButton = useMemo(() => {
    return hasAnyValue(formData);
  }, [formData]);

  useEffect(() => {
    dispatch(fetchBrands({ limit: 100, skip: 0 }));
  }, []);

  const selectAll = {
    m: 0,
    mb: 0.5,
    padding: '4.5px 8px',
    borderRadius: '0.375rem',
    transition: 'background-color 300ms ease, color 300ms ease',
    ':hover': {
      backgroundColor: '#f0f2f5'
    }
  };

  return (
    <Box className='cus-filter-sec'>
      <Grid className='filter-box' container alignItems='center' spacing={1.5}>
        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className='filter-price-unit'>
            <FormControl fullWidth>
              <InputLabel id='demo-multiple-checkbox-label'>Categories</InputLabel>
              <Select size='small'
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={formData?.adminCategories || []}
                fullWidth
                label='Categories'
                className='form-select'
                onChange={(event) => {
                  setFormData({ ...formData, adminCategories: event.target.value });
                }}
                renderValue={(selected) => isAllSelected ? 'All' : selected.join(', ')}
              >
                <FormControlLabel sx={{ ...selectAll, backgroundColor: isAllSelected ? '#f0f2f5' : '' }} control={<Checkbox sx={{ p: 0.4, mr: 0.5 }}
                  checked={isAllSelected}
                  indeterminate={
                    categoriesData?.categories?.length > 0 && formData?.adminCategories.length === categoriesData?.categories?.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormData({ ...formData, adminCategories: categoriesData?.categories?.map((item => item?.categoryName)) });
                    } else {
                      setFormData({ ...formData, adminCategories: [] });
                    }
                  }}
                />}
                  label='Select All'
                >
                </FormControlLabel>

                {categoriesData?.categories?.map((category) => (
                  <MenuItem sx={{ px: 1 }} key={category.categoryId} value={category?.categoryName}>
                    <Checkbox sx={{ p: 0.4, mr: 1 }} checked={formData?.adminCategories.indexOf(category?.categoryName) > -1} />
                    {category?.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className='filter-brands'>
            <FormControl fullWidth>
              <InputLabel id='demo-multiple-checkbox-label'>Brands</InputLabel>
              <Select size='small'
                className='form-select'
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={formData?.brands || []}
                fullWidth
                onChange={(event) => {
                  setFormData({ ...formData, brands: event.target.value });
                }}
                input={<OutlinedInput label='Brands' />}
                renderValue={(selected) => isAllBrandSelected ? 'All' : selected.join(', ')}
              >
                <FormControlLabel sx={{ ...selectAll, backgroundColor: isAllBrandSelected ? '#f0f2f5' : '' }} checked={isAllBrandSelected} control={<Checkbox sx={{ p: 0.4, mr: 0.5 }}
                  indeterminate={
                    brandsData?.brands?.length > 0 && formData?.brands.length === brandsData?.brands?.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormData({ ...formData, brands: brandsData?.brands?.map((item => item?.brandName)) });
                    } else {
                      setFormData({ ...formData, brands: [] });
                    }
                  }}
                />}
                  label='Select All'
                >
                </FormControlLabel>

                {brandsData?.brands?.map((brand) => (
                  <MenuItem sx={{ px: 1 }} key={brand.brandId} value={brand?.brandName}>
                    <Checkbox sx={{ p: 0.4, mr: 1 }} checked={formData?.brands.indexOf(brand?.brandName) > -1} />
                    {brand?.brandName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className='filter-pro-status'>
            <FormControl fullWidth>
              <InputLabel>Product Status</InputLabel>
              <Select size='small' value={formData?.activeProducts} onChange={(e) => setFormData({ ...formData, activeProducts: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2.25}>
          <Box className='filter-price'>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography variant='body2' color='secondary' whiteSpace='nowrap'>Price ($)</Typography>
              <TextField
                size='small'
                name='min'
                label='Min'
                fullWidth
                value={formData?.price?.min}
                onChange={(e) => {
                  if (e.target.value && !isNumber(e.target.value)) return;
                  // if (formData?.price?.max && (+formData?.price?.max < +e.target.value)) return;
                  setFormData({ ...formData, price: { ...formData?.price, min: e.target.value } });
                }}
              />
              <TextField
                size='small'
                name='max'
                label='Max'
                fullWidth
                value={formData?.price?.max}
                onChange={(e) => {
                  if (e.target.value && !isNumber(e.target.value)) return;
                  setFormData({ ...formData, price: { ...formData?.price, max: e.target.value } });
                }}
              />
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2.25}>
          <Box className='filter-qty'>
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography variant='body2' color='secondary'>Quantity</Typography>
              <TextField
                size='small'
                name='min'
                label='Min'
                fullWidth
                value={formData?.quantity?.min}
                onChange={(e) => {
                  if (e.target.value && !isNumber(e.target.value)) return;
                  setFormData({ ...formData, quantity: { ...formData?.quantity, min: e.target.value } });
                }}
              />
              <TextField
                size='small'
                name='max'
                label='Max'
                fullWidth
                value={formData?.quantity?.max}
                onChange={(e) => {
                  if (e.target.value && !isNumber(e.target.value)) return;
                  setFormData({ ...formData, quantity: { ...formData?.quantity, max: e.target.value } });
                }}
              />
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={1.5}>
          <Box className='filter-btns'>
            <Stack direction='row' alignItems='center' gap={1} className='w-100'>
              <Button className='filter-clear-all' onClick={clearAllHandler} size='small' variant='text' color='secondary' style={{ width: '100%' }}>
                Clear All
              </Button>
              <MDButton disabled={!showApplyButton} className='filter-apply' onClick={applyFiltersHandler} size='small' variant='contained' color='primary' style={{ width: '100%' }}>
                Apply
              </MDButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}