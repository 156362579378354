import React from 'react';

export default function FailedIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13123_15945)">
                <g clipPath="url(#clip1_13123_15945)">
                    <path d="M13.2352 4.23529C13.2352 1.89621 11.339 0 8.99994 0C6.66086 0 4.76465 1.89621 4.76465 4.23529C4.76465 6.57438 6.66086 8.47059 8.99994 8.47059C11.339 8.47059 13.2352 6.57438 13.2352 4.23529ZM10.8717 5.35835L10.123 6.10705L8.99998 4.98399L7.87692 6.10705L7.12823 5.35835L8.25128 4.23529L7.12823 3.11224L7.87692 2.36354L8.99998 3.4866L10.123 2.36354L10.8717 3.11224L9.74864 4.23529L10.8717 5.35835Z" fill="#FD4438" />
                    <path d="M2.11768 12.8565V15.0139H8.55147V11.6233L7.87514 12.8565H2.11768Z" fill="#FD4438" />
                    <path d="M9.42203 11.6233V14.9717H15.8826V12.8565H10.1251L9.42203 11.6233Z" fill="#FD4438" />
                    <path d="M9.43506 15.8823H15.8823V18H9.43506V15.8823Z" fill="#FD4438" />
                    <path d="M2.11719 15.8823H8.57788V18H2.11719V15.8823Z" fill="#FD4438" />
                    <path d="M0.123535 12.1765H7.21035L8.99979 8.47058H2.34706L0.123535 12.1765Z" fill="#FD4438" />
                    <path d="M17.8762 12.1765L15.6527 8.47058H9L10.7894 12.1765H17.8762Z" fill="#FD4438" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_13123_15945">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
                <clipPath id="clip1_13123_15945">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}