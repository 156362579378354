import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addTerpenoidAPI, addTerpenoidToProductAPI, deleteMainTerpenoidAPI, deleteTerpenoidAPI, fetchTerpenoidDetailsAPI, fetchTerpenoidsAPI, updateTerpenoidAPI, updateTerpenoidToProductAPI } from '../../redux/apis/terpenoids';

export const fetchTerpenoids = createAsyncThunk(
  'terpenoids/fetchTerpenoids',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchTerpenoidsAPI(data);
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const createTerpenoid = createAsyncThunk(
  'terpenoids/createTerpenoid',
  async ({data}, { rejectWithValue }) => {
    try {
      const res = await addTerpenoidAPI(data);
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const updateTerpenoid = createAsyncThunk(
  'terpenoids/updateTerpenoid',
  async ({data,terpenoidId}, { rejectWithValue }) => {
    try {
      const res = await updateTerpenoidAPI(data,terpenoidId);
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const deleteTerpenoid = createAsyncThunk(
  'terpenoids/deleteTerpenoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteTerpenoidAPI(data);
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const deleteMainTerpenoid = createAsyncThunk(
  'terpenoids/deleteMainTerpenoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteMainTerpenoidAPI(data);
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchSingleTerpenoid = createAsyncThunk(
  'terpenoids/fetchSingleTerpenoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchTerpenoidDetailsAPI(data);
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const assignTerpenoidToProduct = createAsyncThunk(
  'terpenoids/assignTerpenoidToProduct',
  async ({data,productId}, { rejectWithValue }) => {
    try {
      const res = await addTerpenoidToProductAPI(data,productId);
      return res
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const updateTerpenoidToProduct = createAsyncThunk(
  'terpenoids/updateTerpenoidToProduct',
  async ({data,productId}, { rejectWithValue }) => {
    try {
      const res = await updateTerpenoidToProductAPI(data,productId);
      return res
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

const initialState = {
  isLoading: false, // or 'loading', 'succeeded', 'failed'
  error: null,
  isDeleting: false,
  terpenoidsList: [],
  isFetching: false,
  terpeneDetails: {}
};

const terpenoidsSlice = createSlice({
  name: 'terpenoids',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerpenoids.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTerpenoids.fulfilled, (state, action) => {
        state.isLoading = false
        state.terpenoidsList = action.payload;
        state.terpeneDetails = {};

      })
      .addCase(fetchTerpenoids.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload;
      });

    builder
      .addCase(createTerpenoid.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createTerpenoid.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createTerpenoid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(deleteTerpenoid.pending, (state) => {
        state.isDeleting = true;
        state.error = null;
      })
      .addCase(deleteTerpenoid.fulfilled, (state, action) => {
        state.isDeleting = false;
        state.deletedTerpenoidId = action.payload;
      })
      .addCase(deleteTerpenoid.rejected, (state, action) => {
        state.isDeleting = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchSingleTerpenoid.pending, (state) => {
        state.isFetching = true;
        state.error = null;
        state.terpeneDetails = {};
      })
      .addCase(fetchSingleTerpenoid.fulfilled, (state, action) => {
        state.isFetching = false;
        state.terpeneDetails = action.payload.terpeneDetails;
      })
      .addCase(fetchSingleTerpenoid.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload;
      });

      builder
      .addCase(updateTerpenoid.pending, (state) => {
        // Set isLoading to true when the updateTerpenoid is pending
        state.isLoading = true;
      })
      .addCase(updateTerpenoid.fulfilled, (state, action) => {
        // Handle fulfilled state, action.payload contains the resolved value
        // Set isLoading back to false after completion
        state.isLoading = false;
      })
      .addCase(updateTerpenoid.rejected, (state, action) => {
        // Handle rejected state, action.payload contains the error value
        // Set isLoading back to false after completion
        state.isLoading = false;
      });
      
      builder
      .addCase(assignTerpenoidToProduct.pending, (state) => {
        // Set isLoading to true when the assignTerpenoidToProduct is pending
        state.isLoading = true;
      })
      .addCase(assignTerpenoidToProduct.fulfilled, (state, action) => {
        // Handle fulfilled state, action.payload contains the resolved value
        // Set isLoading back to false after completion
        state.isLoading = false;
      })
      .addCase(assignTerpenoidToProduct.rejected, (state, action) => {
        // Handle rejected state, action.payload contains the error value
        // Set isLoading back to false after completion
        state.isLoading = false;
      });
      builder
      .addCase(updateTerpenoidToProduct.pending, (state) => {
        // Set isLoading to true when the updateTerpenoidToProduct is pending
        state.isLoading = true;
      })
      .addCase(updateTerpenoidToProduct.fulfilled, (state, action) => {
        // Handle fulfilled state, action.payload contains the resolved value
        // Set isLoading back to false after completion
        state.isLoading = false;
      })
      .addCase(updateTerpenoidToProduct.rejected, (state, action) => {
        // Handle rejected state, action.payload contains the error value
        // Set isLoading back to false after completion
        state.isLoading = false;
      });
  },
});

export default terpenoidsSlice.reducer;