import { Avatar, Box, Card, CardContent, Chip, Divider, IconButton, InputAdornment, Drawer, FormControl, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Radio, RadioGroup, Slide, Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useEffect, useRef, useState } from 'react';
import StaticsOverview from 'common/StaticsOverview';
import ActiveIcon from 'common/customIcons/active';
import InProgressIcon from 'common/customIcons/inprogress';
import AngleRightIcon from 'common/customIcons/angle_right';
import colors from 'assets/theme/base/colors';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPercentage } from 'utilities/common';
import { formatAddress } from 'utilities/common';
import InActiveIcon from 'common/customIcons/inactive';
import { addSpaceBeforeCapital } from 'utilities/common';
import { formatUsPhoneNumber } from 'utilities/common';
import defaultProductImage from '../../../assets/images/placeholderproduct.svg';
import CartIcon from 'common/customIcons/cart';
import StoreIcon from 'common/customIcons/store';
import InventoryIcon from 'common/customIcons/inventory';
import PaymentIcon from 'common/customIcons/payment';
import { LocalShippingOutlined, SupportAgent } from '@mui/icons-material';
import { closeCustomModalDialog, openCustomModalDialog } from '../../../redux/slices/modalSlice';
import { openSnackbar } from '../../../redux/slices/common';
import { useDispatch } from 'react-redux';
import { warrantyStatusUpdate } from '../../../redux/slices/orders';
import { fetchOrderDetailsById } from '../../../redux/slices/hyperwolf/orders';
import { fetchReassignDriverList, reassignDriver } from '../../../redux/slices/hyperwolf/driver/driver';
import ReassignDriverIcon from 'common/customIcons/reassigndriver';
import NearMeIcon from '@mui/icons-material/NearMe';
import MDInput from 'components/MDInput';
import SearchIcon from 'common/customIcons/search';
import driverPic from '../../../assets/images/driver-pic.svg';
import { useDebounce } from 'hooks/useDebounce';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function HyperwolfOrderDetails() {
  const { grey } = colors;
  const { order = {} } = useSelector(state => state.hyperwolfOrderSlice);
  const { userData = {}, status = '', driverDetails = {}, cartData = {}, orderId, emailStatus = '', trackingId = '', serviceName = '' } = order;
  const { items } = cartData?.cart || [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [driverDrawer, setDriverDrawer] = useState(false);
  const [driverList, setDriverList] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const searchTermRef = useRef('');
  const pageRef = useRef(0);
  const [searchTerm, setSearchTerm] = useState('');

  const warrantyStatusHanlder = (status, orderId, reason, returnItems = []) => {
    dispatch(warrantyStatusUpdate({ returnApprovedStatus: status, orderId, approvalComment: reason, products: returnItems })).unwrap().then((res) => {
      showResponseMessage(res?.message, 'success');
      dispatch(fetchOrderDetailsById(orderId));
      dispatch(closeCustomModalDialog());
    }).catch((err) => {
      showResponseMessage(err?.message, 'error');
    });
  };

  const warrantyDialogHandler = (status, orderId, returnItems = []) => {
    dispatch(openCustomModalDialog({ modal: 'WARRANTY_REASON', modalData: { status, orderId, warrantyStatusHanlder, returnItems } }));
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const handleSearch = useDebounce(() => {
    pageRef.current = 0;

    dispatch(fetchReassignDriverList({ start: 0, skip: pageRef.current, limit: 20, driverName: searchTermRef.current })).unwrap().then(res => {
      setDriverList(res?.data);
    });
  }, 500);

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value;
    handleSearch(value);
    // Debounce the search callback
  };

  const handleOpenDrawer = () => {
    setDriverDrawer(true);
  };

  useEffect(() => {
    dispatch(fetchReassignDriverList({ skip: pageRef.current, limit: 100, driverName: searchTermRef.current })).unwrap().then(res => {
      setDriverList(res?.data);
    });
    pageRef.current = pageRef.current + 20;
  }, []);


  const handleReassignDriver = () => {
    dispatch(reassignDriver({ orderId: order.orderId, driverId: selectedDriverId, taskAssignmentMode: 'manual' })).unwrap()
      .then((res) => {
        showResponseMessage(res?.data.message, 'success');
        setDriverDrawer(false);
        window.location.reload();
      }).catch((error) => {
        showResponseMessage(error?.message, 'error');
        setDriverDrawer(false);
        window.location.reload();
      });
  };

  const submithandleChange = (e) => {
    setSelectedDriverId(e.target.value);
  };

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} display="flex" alignItems="center" color='text.main' lineHeight={1} mb={3.5}>
        <AngleRightIcon className="text-14" />
        <Typography variant='h6' fontWeight="semibold" ml={.75}>Orders</Typography>
      </Box>
      <Paper elevation={0} style={{ borderRadius: '12px' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="start" justifyContent="space-between" bgcolor="white" p={{ xs: 1, md: 2 }}>
          <StaticsOverview
            color="warning"
            iconShadow="iconShadowWarning"
            icon={<CartIcon />}
            title="Order ID"
            count={orderId}
          />
          {
            trackingId && <StaticsOverview
              color="primary"
              iconShadow="iconShadowPrimary"
              icon={<LocalShippingOutlined />}
              title="Tracking ID"
              count={trackingId || '-'}
            />
          }
          {
            serviceName && <StaticsOverview
              color="success"
              iconShadow="iconShadowSuccess"
              icon={<SupportAgent />}
              title="Service"
              count={serviceName || '-'}
            />
          }
          <StaticsOverview
            color="info"
            iconShadow="iconShadowInfo"
            icon={<InventoryIcon />}
            title="Inventory"
            count="Safe"
          />
          <StaticsOverview
            color="success"
            iconShadow="iconShadowSuccess"
            icon={<StoreIcon />}
            title="Store"
            count="Hyperwolf L.E."
          />
          <StaticsOverview
            color="primary"
            iconShadow="iconShadowInfo"
            icon={<PaymentIcon />}
            title="Payment Type"
            count={cartData?.cart?.paymentOption}
          />
          <StaticsOverview
            color="#8E0CF5"
            iconShadow="iconShadowViolet"
            icon={<InProgressIcon />}
            title="Status"
            count={status === 'canceledbydispensary' ? 'Canceled By Dispensary' : status}
          />
        </Stack>
      </Paper>

      <Grid container spacing={2.4} my={3}>
        <Grid item xs={12} md={7}>
          <Card variant='detailsCard' sx={{ justifyContent: 'space-between' }}>
            <CardContent sx={{ p: { xs: 2, md: 3 }, pb: 0 }}>
              {
                order?.returnItems?.length > 0 && <>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                    <Typography variant='h4'>Return Items</Typography>
                    {
                      order?.warrantyStatus === 'approved' && <Box color="#24CA49" display="flex" alignItems="center">
                        <ActiveIcon width="16px" height="16px" flex="none" />
                        <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Approved</Typography>
                      </Box>
                    }
                    {
                      order?.warrantyStatus === 'rejected' && <Box color="#FD4438" display="flex" alignItems="center">
                        <InActiveIcon width="16px" height="16px" flex="none" />
                        <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Decline</Typography>
                      </Box>
                    }
                    {
                      !order?.warrantyStatus && <Box display="inline-flex" gap={1} className="invite-action">
                        <MDButton onClick={() => warrantyDialogHandler(true, orderId, order?.returnItems)} variant='outlined' size='small' color='success' sx={{ fontWeight: 500 }}>Approve</MDButton>
                        <MDButton onClick={() => warrantyDialogHandler(false, orderId, order?.returnItems)} variant='outlined' size='small' color='error' sx={{ fontWeight: 500 }}>Decline</MDButton>
                      </Box>
                    }
                  </Stack>
                  <List>
                    {
                      order?.items?.filter((orderProduct => order?.returnItems?.find((returnProduct => returnProduct?.productId === orderProduct?.product?.productId))))?.map((product => {
                        const { productId, productName = '-', category, isSalePrice, salePrice, unitPrice, brandName, customWeight = '' } = product?.product
                          || {};
                        const { quantity } = product;
                        return <ListItem key={productId} alignItems="center" sx={{ mt: 2.5 }}>
                          <ListItemAvatar sx={{ mr: 1.5 }} className="pro-avatar-sec">
                            <Avatar
                              alt="Product Name"
                              src={assets[0]?.thumbURL || defaultProductImage}
                              sx={{ width: 82, height: 82, borderRadius: 4 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography variant='body2' color="secondary">{brandName}</Typography>
                                <Link to={`/products/details/${productId}`}>
                                  <Typography variant="h6" fontWeight="semibold">{productName}</Typography>
                                </Link>
                                <Typography variant='body2' color="secondary">
                                  <span className='mr-3'>Category: <span style={{ color: '#000000' }}>{category?.categoryName}</span></span>
                                  <span>Wt: <span style={{ color: '#000000' }}>{customWeight}</span></span>
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography variant="h6" fontWeight="medium" component="span" mr={1}>${isSalePrice ? salePrice : unitPrice}</Typography>
                                {
                                  isSalePrice && <Typography variant="h6" fontWeight="medium" component="span" color="secondary" mr={1} sx={{ textDecoration: 'line-through' }}>${unitPrice}</Typography>
                                }
                                {
                                  isSalePrice && getPercentage(unitPrice, salePrice) > 0 && <Typography variant="h6" fontWeight="medium" component="span" color="success.main">{getPercentage(unitPrice, salePrice)}% Off</Typography>
                                }
                                {
                                  order?.promotionData?.promoName && order?.promotionData?.promotionType?.toLowerCase() === 'product' && isSalePrice &&
                                  <Box mt={1}>
                                    <Chip label="Promo Applied" variant="outlined" color='warning' className='promo-chip chip-square' disabled />
                                  </Box>
                                }
                              </React.Fragment>
                            }
                          />
                          <Box textAlign="end">
                            {
                              order?.warrantyStatus === 'rejected' ? <Chip label="Rejected" variant="outlined" color='error' className='promo-chip chip-square' /> : order?.warrantyStatus === 'approved' ? <Chip label="Returned" variant="outlined" color='success' className='promo-chip chip-square' /> : null
                            }
                            <Typography variant="h6" fontWeight="medium" mt={5}>x{quantity}</Typography>
                          </Box>
                        </ListItem>;
                      }))
                    }
                  </List>
                  {
                    order?.returnComments && <Box mt={3} border={1} borderColor="#9A9AAF" borderRadius={4}>
                      <CardContent sx={{ px: 3, py: 2 }}>
                        <Typography variant='h6' fontWeight="semibold" mb={0}>Return Request Reason</Typography>
                        <Typography variant='body2'>{order?.returnComments}</Typography>
                      </CardContent>
                    </Box>
                  }
                  <Divider sx={{ my: 3 }} />
                </>
              }
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                <Typography variant='h4'>Order Details</Typography>
                {
                  (emailStatus !== 'delivered' && emailStatus !== 'cancelled') && <Link to={`/orders/${orderId}`}>
                  </Link>
                }
              </Stack>
              <Typography variant='h6' fontWeight="semibold" mb={1}>Product</Typography>
              <List>
                {
                  items?.map((productItem => {
                    const { productId, name = '-', category, isSalePrice, salePrice, unitPrice, brandName, customWeight = '' } = productItem?.product
                      || {};

                    const { quantity = 0 } = productItem;
                    const { product } = productItem;
                    const { assets } = product;
                    return <ListItem key={productId} alignItems="center" sx={{ mt: 2.5 }}>
                      <ListItemAvatar sx={{ mr: 1.5 }} className="pro-avatar-sec">
                        <Avatar
                          alt="Product Name"
                          src={assets?.[0]?.thumbURL || defaultProductImage}
                          sx={{ width: 82, height: 82, borderRadius: 4 }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography variant='body2' color="secondary">{brandName}</Typography>
                            <Link to={`/products/details/${productId}`}>
                              <Typography variant="h6" fontWeight="semibold">{name}</Typography>
                            </Link>
                            <Typography variant='body2' color="secondary">
                              <span className='mr-3'>Category: <span style={{ color: '#000000' }}>{category?.categoryName}</span></span>
                              <span>Wt: <span style={{ color: '#000000' }}>{customWeight}</span></span>
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography variant="h6" fontWeight="medium" component="span" mr={1}>${isSalePrice ? salePrice : unitPrice}</Typography>
                            {
                              isSalePrice && <Typography variant="h6" fontWeight="medium" component="span" color="secondary" mr={1} sx={{ textDecoration: 'line-through' }}>${unitPrice}</Typography>
                            }
                            {
                              isSalePrice && getPercentage(unitPrice, salePrice) > 0 && <Typography variant="h6" fontWeight="medium" component="span" color="success.main">{getPercentage(unitPrice, salePrice)}% Off</Typography>
                            }

                            {
                              order?.promotionData?.promoName && order?.promotionData?.promotionType?.toLowerCase() === 'product' && isSalePrice &&
                              <Box mt={1}>
                                <Chip label="Promo Applied" variant="outlined" color='warning' className='promo-chip chip-square' disabled />
                              </Box>
                            }

                          </React.Fragment>
                        }
                      />
                      <Typography variant="h6" fontWeight="medium" mt={5}>x{quantity}</Typography>
                    </ListItem>;
                  }))
                }
              </List>
              <Divider sx={{ my: 3 }} />
              <Typography variant='h6' fontWeight="semibold" mb={1}>Order Summary</Typography>
            </CardContent>
            <List sx={{ py: 2, background: '#F1F1F1' }}>
              <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography variant="h6" fontWeight="medium" color="secondary">Subtotal</Typography>
                    </React.Fragment>
                  }
                />
                <Typography variant="h5" fontWeight="semibold">${cartData?.cart?.subTotal?.toFixed(2)}</Typography>
              </ListItem>
              <Divider sx={{ my: 1 }} />

              <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography variant="h6" fontWeight="medium" color="secondary">Total</Typography>
                    </React.Fragment>
                  }
                />
                <Typography variant="h5" fontWeight="bold">${(cartData?.cart?.total?.toFixed(2))}</Typography>

              </ListItem>
              {
                order?.promotionData?.promoName && order?.promotionData?.promoDiscount && order?.promotionData?.promotionType !== 'Product' &&
                <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box display='flex' gap={0.5}>
                          <Typography variant="h6" fontWeight="medium" color="success.main">{order?.promotionData?.promoName} </Typography>
                          <Typography variant="h6" fontWeight="medium" color="secondary" >(Promo code)</Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                  <Typography variant="h5" fontWeight="semibold" color="success.main">-${order?.promotionData?.promoDiscount?.toFixed(2)}</Typography>

                </ListItem>
              }
              {
                order?.afterTaxDiscount > 0 && <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography variant="h6" fontWeight="medium" color="secondary">  Rewards Discount</Typography>
                      </React.Fragment>
                    }
                  />
                  <Typography variant="h6" style={{ color: '#28B446' }}>
                    -(${(parseFloat(order && order.afterTaxDiscount).toFixed(2)) || 0})
                  </Typography>
                </ListItem>
              }
              {
                order?.walletPointsUsed > 0 && <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography variant="h6" fontWeight="medium" color="secondary">Wallet deducted amount</Typography>
                      </React.Fragment>
                    }
                  />
                  <Typography variant="h5" fontWeight="semibold" >-${order?.walletPointsUsed?.toFixed(2)}</Typography>
                </ListItem>
              }
              <Divider sx={{ my: 1 }} />

              <ListItem alignItems="center" sx={{ px: 3.5, py: 1.5 }}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography variant="h6" fontWeight="medium">Total Amount Paid</Typography>
                    </React.Fragment>
                  }
                />
                <Typography variant="h5" fontWeight="bold">${cartData?.cart?.total?.toFixed(2)}</Typography>

              </ListItem>
            </List>
            <CardContent sx={{ p: { xs: 2, md: 3 } }}>
              <Typography variant='h6' fontWeight="semibold" mb={1}>Delivery Address</Typography>
              <Typography variant='h6' fontWeight="regular" lineHeight="1.85">
                <b style={{ fontWeight: 600 }}>Address: </b>{cartData.deliveryAddress?.address || '-'} <br />
                <b style={{ fontWeight: 600 }}>City: </b>{cartData.deliveryAddress?.city || '-'} <br />
                <b style={{ fontWeight: 600 }}>State: </b>{cartData.deliveryAddress?.state || '-'} <br />
                <b style={{ fontWeight: 600 }}>Zip Code: </b>{cartData.deliveryAddress?.zipCode || '-'}<br />
              </Typography>
            </CardContent>
            {
              order?.memo && <CardContent sx={{ p: { xs: 2, md: 3 } }}>
                <Typography variant='h6' fontWeight="semibold" mb={1}>Instructions</Typography>
                <Typography variant='body2'>{order?.memo}</Typography>
              </CardContent>
            }
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card variant='detailsCard' sx={{ justifyContent: 'space-between', marginBottom: '10px' }}>
            <CardContent sx={{ p: { xs: 2, md: 3 } }}>
              <Typography variant='h4' mb={2}>Member Details</Typography>
              <Grid container alignItems="center" spacing={3.5}>
                <Grid item xs={12}>
                  <Typography variant='caption' color={grey[600]}>Name</Typography>
                  <Link to={`/hyperwolf/members/detail/${userData?.email}`}>
                    <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{userData?.firstName + ' ' + userData?.lastName}</Typography>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' color={grey[600]}>Member Address</Typography>
                  {
                    cartData?.deliveryAddress ? <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{formatAddress({ ...cartData?.deliveryAddress, zipcode: cartData?.deliveryAddress?.zipcode })}</Typography> : <Typography variant='h6' fontWeight="medium" lineHeight="1.25">-</Typography>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' color={grey[600]}>Phone</Typography>
                  <Typography variant='h6' fontWeight="medium" lineHeight="1.25">+1 {userData?.Phone ? formatUsPhoneNumber(userData?.Phone) : '-'}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' color={grey[600]}>Email</Typography>
                  <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{userData?.email}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='caption' color={grey[600]}>Member Type</Typography>
                  <Typography variant='h6' fontWeight="medium" lineHeight="1.25">{cartData?.cart?.consumerType ? addSpaceBeforeCapital(cartData?.cart?.consumerType) : '-'}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className='driver-details-sec' variant='detailsCard' sx={{ justifyContent: 'space-between' }}>
            <CardContent sx={{ p: { xs: 2, md: 3 } }}>
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Typography variant='h4' mb={2}>Driver Details</Typography>
                {status !== 'canceledbydispensary' && status !== 'completed' &&
                  <MDButton
                    onClick={handleOpenDrawer}
                    className='d-flex gap-2 text-white-900 green-btn'
                    type='button'
                    size="small"
                    style={{ minWidth: '170px' }}
                  >
                    <ReassignDriverIcon />
                    <Typography fontWeight="semibold" className='text-14 text-white-900 text-transform-none'>Reassign Driver</Typography>
                  </MDButton>
                }
              </Stack>
              <Grid item xs={12} sm={12}>
                <div className="drawer-inner-bg">
                  <Stack direction="row" justifyContent="space-between" className='bg-grey-200 border-radius-8 px-4 py-3'>
                    <Typography className='text-grey-800 text-14 font-w-600'>{driverDetails.fleetName}</Typography>
                    {
                      // Find the driver by ID from the driverList
                      (() => {
                        const driver = driverList.find(driver => driver._id === driverDetails._id);
                        if (driver) {
                          return (
                            <Typography className={`d-flex align-item-center gap-1 text-14 font-w-600 ${driver.status ? 'dot-duty' : 'dot-duty dot-off'}`}>
                              {driver.status ? 'On Duty' : 'Off Duty'}
                            </Typography>
                          );
                        }
                        return null; // Handle case where driver is not found
                      })()
                    }
                  </Stack>
                </div>
                <Stack my={4} direction="row" justifyContent="space-between" alignItems="top" className='gap-3'>
                  <div className='d-flex align-item-top gap-2'>
                    <NearMeIcon style={{ color: '#0163FF', marginTop: '5px' }} />
                    <div className='d-flex align-item-top flex-column'>
                      <Typography className='text-14 text-grey-800' fontWeight="semibold">Driver Location at 1:23 AM</Typography>
                      <Typography className='text-14 text-grey-500'>22.872312.-117.5893 at 0.14 miles elevation</Typography>
                    </div>
                  </div>
                  <MDButton
                    type='button'
                    size="small"
                    color="primary"
                    variant='outlined'
                    style={{ minWidth: '160px', height: '40px' }}
                  >
                    Contact Driver
                  </MDButton>
                </Stack>
                <Stack>
                  <div className='w-100 d-flex'>
                    <img className='w-100 d-flex border-radius-8 object-fit-cover' src={cartData?.memo?.split('Street View:')[1]?.trim()} style={{ height: '250px' }} />
                  </div>
                  {
                    Boolean(driverDrawer) &&
                    <Drawer open={Boolean(driverDrawer)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="cus-drawer approval-drawer driver-list-drawer"
                      onClose={() => setDriverDrawer('')}
                      anchor='right'
                    >
                      <Box p={2} className='d-flex align-item-center flex-column jc-s-btwn gap-2 drawer-head' style={{ borderBottom: '1px solid #F1F1F1' }}>
                        <div className='w-100 d-flex flex-row gap-3'>
                          <Typography variant='h6' className='w-100 d-flex text-left text-14 text-grey-500'>Driver List</Typography>
                        </div>
                      </Box>
                      <Box>
                        <div className='p-3 hd-drivers-search'>
                          <MDInput
                            label="Search Drivers / Tasks"
                            value={searchTerm}
                            id=""
                            onChange={searchHandler}
                            className="w-100 search-field"
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton size='small'>
                                    <SearchIcon width='18px' height='18px' />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <Box className='drawer-scroll-sec'>
                          <Box p={2} className='hd-drivers-listing'>
                            <List>
                              <FormControl className='d-flex custom-checkbox-ui'>
                                <RadioGroup
                                  aria-labelledby="driver-status-group"
                                  value={selectedDriverId}
                                  name="driverId"
                                  className="radio-colors"
                                  onChange={submithandleChange}
                                >
                                  {driverList.map(driver => (
                                    <ListItem key={driver._id} className='d-flex align-item-center jc-s-btwn gap-2'>
                                      <div className='w-100 d-flex align-item-center jc-s-btwn flex-row gap-2'>
                                        <div className='d-flex align-item-center radio-select'>
                                          <FormControlLabel className='mr-0' control={<Radio />} value={driver._id} ></FormControlLabel>
                                          <Typography variant='h6' className='d-flex align-item-center gap-2 text-14 text-grey-800'>
                                            <img className='border-radius-50' src={driverPic} alt='' width={28} height={28} />
                                            {driver.driverName}
                                          </Typography>
                                        </div>
                                        <Typography className={`d-flex align-item-center gap-1 font-w-600 ${driver.status ? 'drawer-dot-duty' : 'drawer-dot-duty drawer-dot-off'}`}>
                                          {driver.status ? 'On Duty' : 'Off Duty'}
                                        </Typography>
                                      </div>
                                    </ListItem>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </List>
                          </Box>
                        </Box>
                      </Box>
                      <Box px={2} py={3} className='w-100 d-flex align-item-center flex-column jc-s-btwn gap-2 drawer-foot'>
                        <MDButton
                          fullWidth
                          type='button'
                          color="primary"
                          onClick={() => handleReassignDriver()}
                        >
                          Done
                        </MDButton>
                      </Box>
                    </Drawer>
                  }
                </Stack>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}