import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import StatusText from 'common/component/StatusText';
import { fetchProductActivityLogs } from '../../../redux/slices/products';
import Search from 'examples/Search';
import { formatTimestampToDateView } from 'utilities/common';
import OrderProductLogsActivity from './OrderProductLogsActivity';
import { statusObj } from './Statusobj';

export default function ProductActivityLogs() {
  const dispatch = useDispatch();
  const { loading = false, activityLogs = {} } = useSelector(state => state.products);
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { productId = '' } = useParams();
  const [orderActivityLog, setOrderActivityLog] = useState('batch_logs')

  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  console.log("orderActivityLog", orderActivityLog);

  const columnDefs = [
    {
      headerName: 'Date/Time',
      field: 'createdDate',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        return props?.value ? formatTimestampToDateView(props?.value) : '';
      },
    },
    {
      headerName: 'Updated By',
      field: 'updatedBy',
      key: 'updatedBy',
      suppressMenu: false,
      sortable: false
    },
    {
      headerName: 'Batch',
      field: 'productBatchId',
      key: 'productBatchId',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        if (!props?.data?._id) return;
        return props?.data?.previousChanges?.productBatchId ? props?.data?.previousChanges?.productBatchId : '-';
      },
    },
    {
      headerName: 'Source',
      field: 'source',
      suppressMenu: false,
      sortable: false

    },
    {
      headerName: 'Prev Data (Status/Qty)',
      field: '',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        return props?.data?.source !== 'Product Status Update' ? props?.data?.previousChanges?.currentQuantity?.toString() : props?.data?.source === 'Product Status Update' ? <StatusText status={props?.data?.previousChanges?.oldStatus} /> : '';
      },
    },
    {
      headerName: 'New Data (Status/Qty)',
      field: '',
      suppressMenu: false,
      sortable: false,
      headerClass: 'left-aligned-cell-header',
      cellRenderer: (props) => {
        return props?.data?.source !== 'Product Status Update' ? props?.data?.newChanges?.currentQuantity?.toString() : props?.data?.source === 'Product Status Update' ? <StatusText status={props?.data?.newChanges?.newStatus} /> : '';
      },
    },

  ];

  const getRows = async (params) => {
    gridRefApi.current = params;
    if (orderActivityLog === 'batch_logs') {
      dispatch(fetchProductActivityLogs({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, productId })).unwrap().then(res => {
        const { activityLogs, totalActivityLogs } = res;
        params.successCallback([...activityLogs], totalActivityLogs);
        pageRef.current = pageRef.current + 20;
      });
    }
    else {
      dispatch(fetchProductActivityLogs({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, productId })).unwrap().then(res => {
        const { individualSums
          , totalActivityLogs } = res;
        params.successCallback([...individualSums
        ], totalActivityLogs);
        pageRef.current = pageRef.current + 20;
      });
    }
  };

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value;
    handleSearch(value);
  };

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (activityLogs?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [activityLogs, loading]);

  //  order logs
  const columnOrderDefs = [
    {
      headerName: 'Date/Time',
      field: 'date',
      suppressMenu: false,
      sortable: false,
      cellRenderer: (props) => {
        return props?.value ? formatTimestampToDateView(props?.value) : '';
      },
    },

    {
      headerName: 'Order ID',
      field: 'orderId',
      key: 'orderId',
      suppressMenu: false,
      sortable: false,
    },
    {
      headerName: 'Total Quantity',
      field: 'totalQuantity',
      suppressMenu: false,
      sortable: false

    },
    {
      headerName: 'Status',
      field: 'emailStatus',
      suppressMenu: false,
      sortable: false,
      headerClass: 'left-aligned-cell-header',
      cellRenderer: (props) => {
        return props?.data?.emailStatus ? <>{statusObj[props?.value || 'awaiting_shipment']}</> : null;
      },
    },
  ];

  return (
    <>
      <Box className="cannabinoids-tabs batch-logs-tabs">
        <Stack direction='row' gap={1} sx={{ background: '#ffffff' }}>
          <Button onClick={() => {
            setOrderActivityLog('batch_logs'); pageRef.current = 0;
          }} variant='outlined' sx={{ color: '#2E2E3A' }} className={orderActivityLog === 'batch_logs' ? 'active' : ''} >
            Batch Logs
          </Button>
          <Button className={orderActivityLog === 'order_logs' ? 'active' : ''} onClick={() => {
            setOrderActivityLog('order_logs'); pageRef.current = 0;
          }} variant='outlined' sx={{ color: '#2E2E3A' }}>
            Orders Logs
          </Button>
        </Stack>
      </Box>

      <Box>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>
              {orderActivityLog === 'batch_logs' ? 'Batch Logs' : ' Orders Logs'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
            </Stack>
          </Grid>
        </Grid>
        {
          orderActivityLog === 'batch_logs' ?
            <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: '70vh' }} />
            :
            <OrderProductLogsActivity col={columnOrderDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: '70vh' }} />
        }
      </Box>
    </>
  );
}