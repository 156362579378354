import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";
import axiosClientHyperWolf from "axiosClient/indexHyperWolf";

// Main Setting API
export function fetchDriversApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/list?${getQueryString(filters)}`);
}

export function getDriverAnalyticsInfoApi(driverId, filters) {
    const url = (filters || filters?.analyticType !== "") ? `api/v1/admin/analytics/details/${driverId}?${getQueryString(filters)}` : `api/v1/admin/analytics/details/${driverId}`
    return axiosClientHyperDrive.get(url);
}

export function getDriverAnalyticsExportApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/export?${getQueryString(filters)}`);
}

export function fetchRoutesDetailsApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/view/route?taskId=${data}`);
}

export function fetchRegionListApi(data) {
    return axiosClientHyperWolf.get(`api/v1/regions/get`);
}

export function fetchIdleDriversApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/idleFleetsList?${getQueryString(filters)}`);
}

export function fetchCheckListDetailsApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/analytics/view/checkInDetails?${getQueryString(data)}`);
} 