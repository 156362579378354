import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchDriversRegionsAPI } from '../../../apis/driverRegions'
import { fetchDriverAssignedRegionAPI } from '../../../apis/hyperwolf/Driver/regions'

export const fetchDriversRegions = createAsyncThunk(
    'drivers/fetchDriverRegions',
    async( filters, {rejectWithValue}) => {
        try {
            const res = await fetchDriversRegionsAPI(filters)
            return res
        } catch(error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchDriverAssignedRegion = createAsyncThunk(
    'drivers/fetchDriverRegion',
    async (terminalId, {rejectWithValue}) => {
        try {
            const res = await fetchDriverAssignedRegionAPI(terminalId)
            return res
        } catch(error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)