import { CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';
import { bulkDeleteDriver } from '../../redux/slices/hyperwolf/driver/driver';
import { useLocation } from 'react-router-dom';
import { bulkDeleteDriverHemp } from '../../redux/slices/driverHemp';

export default function BulkDeleteDriver({ closeModal }) {
  const { modalData, isLoading = false } = useSelector(state => state.modal);
  const { isDeleting = false } = useSelector(state => state.common);
  const search = useLocation();

  const { fleetIds, resetData = () => { } } = modalData;
  const dispatch = useDispatch();

  const handleBulkDeleteDriver = () => {
    if (search.pathname === '/drivers') {
      dispatch(bulkDeleteDriverHemp({ fleetIds })).unwrap().then((res => {
        dispatch(openSnackbar({ content: res?.data.message, color: 'success', icon: 'check' }));
        dispatch(closeCustomModalDialog({}));
        resetData();
      })).catch((err => {
        dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }));
      }));
    }
    else {
      dispatch(bulkDeleteDriver({ fleetIds })).unwrap().then((res => {
        dispatch(openSnackbar({ content: res?.data.message, color: 'success', icon: 'check' }));
        dispatch(closeCustomModalDialog({}));
        resetData();
      })).catch((err => {
        dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }));
      }));
    }
  };

  return (
    <>
      <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'>
        Delete Confirmation
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete selected Driver?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={closeModal} size='small' color='secondary'>
          Cancel
        </MDButton>
        <MDButton onClick={handleBulkDeleteDriver} size='small' color='error'>
          {
            isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : ' Delete'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}