import React, { useEffect, useState } from 'react'
import { Card, Grid, Icon, Typography, Stack, TextField, DialogTitle, IconButton, Box } from '@mui/material'
import MDButton from 'components/MDButton'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addCommonReason, editRefundReason, fetchRefundTable, openSnackbar } from '../../redux/slices/common';
import { addFailureReason, editFailureReason, getFailureReasonDetails } from '../../redux/slices/hyperdrive/setting';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

export default function AddRefundReason({ closeModal }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { mainFailureReasonDetailsData, isBtnLoading: isBtnLoading = false } = useSelector(state => state?.common)
    const [errors, setErrors] = useState({})
    const { modalData } = useSelector(state => state.modal);

    const [reason, setReason] = useState({
        refundReason: '',
        reasonStatus: true
    })
    const reasonChangeHandler = (event) => {
        setErrors({ refundReason: "" })
        setReason((prevState) => ({
            ...prevState,  // Spread the previous state
            [event?.target?.name]: event?.target?.value  // Update the specific field
        }));

    }

    const validateReasons = () => {
        let isError = false
        let errors = {}
        if (!reason?.refundReason?.trim()) {
            isError = true
            errors = { ...errors, refundReason: 'Refund reason is required' }
        }
        return { errors, isError }
    }

    const submitHandler = () => {
        const { isError, errors } = validateReasons()
        setErrors(errors)
        if (isError) return;
        setErrors({})
        if (modalData) {
            const reasonId = modalData?.reasonId
            dispatch(editRefundReason({ data: reason, reasonId }))
                .unwrap()
                .then(res => {
                    closeModal()
                    dispatch(fetchRefundTable())
                    showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                    showResponseMessage(err?.message, 'error');
                });
        } else {
            dispatch(addCommonReason({ data: reason }))
                .unwrap()
                .then(res => {
                    closeModal()
                    dispatch(fetchRefundTable())
                    showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                    showResponseMessage(err?.message, 'error');
                });
        }
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    useEffect(() => {
        if (modalData) {
            setReason({
                refundReason: modalData?.refundReason,
                reasonStatus: modalData?.reasonStatus
            })
        }
    }, [modalData])

    // useEffect(() => {
    //     if (mainFailureReasonDetailsData && modalData) {
    //         setReason({ reason: mainFailureReasonDetailsData?.data?.reason ? mainFailureReasonDetailsData?.data?.reason : '' })
    //     }
    // }, [mainFailureReasonDetailsData])

    return (
        <Card sx={{ py: 3, px: 4, borderRadius: 0 }}>
            <DialogTitle id='draggable-dialog-title' sx={{p: 0, mb: 4}} display='flex' alignItems='center' justifyContent='space-between'>
                Add Refund Reason
                <IconButton onClick={closeModal} edge='end' size='small' className='modal-close'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} mb={3}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">Status:</Typography>

                <Box display="flex" alignItems="center" gap={1.25}>
                    <Stack direction='row' alignItems='center' gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor='#F2F3F7'>
                        <MDButton
                            sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                            type='button'
                            variant={reason?.reasonStatus === true ? 'contained' : 'text'}
                            color={reason?.reasonStatus === true ? 'success' : 'dark'}
                            size='small'
                            onClick={() => setReason({ ...reason, reasonStatus: true })}
                        >
                            Active
                        </MDButton>
                        <MDButton
                            sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                            type='button'
                            variant={reason?.reasonStatus !== true ? 'contained' : 'text'}
                            color={reason?.reasonStatus !== true ? 'error' : 'dark'}
                            size='small'
                            onClick={() => setReason({ ...reason, reasonStatus: false })}
                        >
                            Inactive
                        </MDButton>
                    </Stack>
                </Box>
            </Stack>

            <Grid container alignItems='center' spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        autoComplete='off'
                        fullWidth
                        helperText={errors?.refundReason}
                        error={errors?.refundReason}
                        label={!modalData ? 'Add Reason' : 'Edit Reason'}
                        name='refundReason'
                        onChange={(e) => reasonChangeHandler(e)}
                        className='form-input'
                        value={reason?.refundReason || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mb={2} mt={2}>
                        <MDButton sx={{ minWidth: '140px' }} type='button' variant='contained' color='primary' onClick={() => submitHandler()} disabled={isBtnLoading} isBtnLoading={isBtnLoading}>
                            Save
                        </MDButton>
                        <MDButton sx={{ minWidth: '140px' }} type='button' variant='outlined' color='dark' className='dark-outlined-btn' onClick={() => closeModal()}>
                            Cancel
                        </MDButton>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}