import React, { useState } from 'react';
import {  Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import { getData } from 'utilities/common';

export default function CustomDrawer(props) {
  const { children, customFunction = () => { },  className = '' } = props;

  const [open, setModalOpen] = useState(true);
  const adminsMode = getData('adminsMode');

  function handleClose() {
    if (JSON.parse(adminsMode) === 'hyperdrive') { return false; }
    setModalOpen(false);
    customFunction();
  }

  return (
    <Drawer
      className={`cus-drawer ${className}`}
      open={open}
      anchor='right'
      onClose={handleClose}
      aria-labelledby='form-Dr-title'
      fullWidth
    >
      {children}
    </Drawer>
  );
}

CustomDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  customFunction: PropTypes.func,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
};