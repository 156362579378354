import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { getData } from 'utilities/common';

export default function CustomDialog(props) {
    const { children, maxWidth, className = '' } = props;
    const [open, setModalOpen] = useState(true)

    const adminsMode = getData('adminsMode')
    return (
        <Dialog
            open={open}
            aria-labelledby='form-dialog-title'
            maxWidth={maxWidth}
            className={JSON.parse(adminsMode) === 'hyperdrive' ? `bg-black cus-modal ${className}` : ` cus-modal ${className}`}
            fullWidth
        >
            {children}
        </Dialog>
    );
}
CustomDialog.propTypes = {
    children: PropTypes.node.isRequired,
    customFunction: PropTypes.func,
    maxWidth: PropTypes.string,
    className: PropTypes.string,
};