import React, { memo, useEffect, useState } from 'react';
import { Box, Typography, Button, FormControl, FormControlLabel, Menu, RadioGroup, Radio, List, ListItem, ListItemAvatar, Avatar, Tooltip } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { useDispatch } from 'react-redux';
import AssignIcon from 'common/customIcons/assign';
import ReassignDriverIcon from 'common/customIcons/reassigndriver';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RecipientIcon from 'common/customIcons/recipient';
import RedirectIcon from 'common/customIcons/redirect';
import MapPinIcon from 'common/customIcons/mappin';
import TimeIcon from 'common/customIcons/time';
import UserIcon from 'common/customIcons/user';
import ActivityLogIcon from 'common/customIcons/activitylog';
import CheckIcon from 'common/customIcons/check';
import ViewRouteIcon from 'common/customIcons/viewroute';
import { useSelector } from 'react-redux';
import { fetchAutomaticDriver, fetchTaskDetails, reassignmentTask } from '../../../redux/slices/hyperdrive/drivers';
import TaskLogs from './TaskLogs';
import TaskRoute from './TaskRoute';
import MapDriveListDrawer from '../createTask/MapDriveListDrawer';
import { getData } from 'utilities/common';
import StatusTask from 'common/component/StatusTask';
import TaskDurationIcon from 'common/customIcons/duration';
import TravellIcon from 'common/customIcons/travell';
import TaskCreatedIcon from 'common/customIcons/taskcreated';
import TaskAssignedIcon from 'common/customIcons/taskassigned';
import TaskStartedIcon from 'common/customIcons/taskstarted';
import DriverDepartedIcon from 'common/customIcons/driverdeparted';
import DriverArrivedIcon from 'common/customIcons/driverarrived';
import TaskCompletedIcon from 'common/customIcons/taskcompleted';
import { customApiSwitch, openCustomDrawer, openCustomModalDialog } from '../../../redux/slices/modalSlice';
import { convertMinutesToHoursAndMinutes } from 'utilities/common';
import { addCommas } from 'utilities/common';
import { Close } from '@mui/icons-material';
import InActiveIcon from 'common/customIcons/inactive';
import noDataFound from 'assets/images/nothing-found.svg';
import CancelledIcon from 'common/customIcons/cancelled';
import { formatTimestampToDate24Hours } from 'utilities/common';
import { formatPhoneNumber } from 'utilities/common';
import { separateWords } from 'utilities/common';
import { getPercentage } from 'utilities/common';
import Loader from 'common/Loader';
import moment from 'moment';
import { formatTimestampToDate24HoursBreak } from 'utilities/common';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MDButton from 'components/MDButton';

const TaskDetails = memo(({ closeModal }) => {
    const dispatch = useDispatch()
    const { drawerData, drawer } = useSelector(state => state?.modal)
    const { taskLoading = false } = useSelector(state => state.drivers)
    const [taskDetails, setTaskDetails] = useState({})
    const [activityLog, setActivityLog] = useState(false)
    const [loadingDone, setLoadingDone] = useState(false)
    const [routeView, setRouteView] = useState(false)
    const [showManualList, setShowManualList] = useState(false);
    const [errors, setErrors] = useState({})
    const user = getData('login-user-info')
    const parseUser = JSON.parse(user)
    const [formData, setFormData] = useState({
        driverId: '',
        taskAssignmentMode: '',
    })
    const [taxesExpend, setTaxesExpend] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    let roleTab = searchParams.get('roleTab')
    const [drivermemo, setDrivermemo] = useState({})

    const taskDetail = () => {
        dispatch(fetchTaskDetails(drawerData)).unwrap().then(res => {
            setTaskDetails(res);
        })
    }

    useEffect(() => {
        taskDetail()
    }, [])

    const convertTaskDescription = (desc) => {
        if (!desc) return {}; // Return an empty object if the input is falsy (null or empty string)
        const lines = desc.trim().split('\n');  // Split the input string into lines
        const taskObj = {}; // Initialize an empty object to hold key-value pairs

        // Iterate over each line to extract key-value pairs
        lines.forEach(line => {
            if (line.includes(':')) {// Check if the line contains a colon
                const [key, ...valueParts] = line.split(':'); // Split by the first colon
                const value = valueParts.join(':').trim(); // Join the remaining parts and trim whitespace
                taskObj[key.trim()] = value; // Store the key-value pair in the taskObj
            }
        });
        // Return an object with the required properties, extracting values from taskObj
        return {
            DropOff: taskObj['Drop Off'],
            Instructions: taskObj['Instructions'],
            PaymentType: taskObj['Payment Type'],
            AmountPaidOnline: taskObj['Amount Paid Online'],
            CashToCollectInPerson: taskObj['Cash To Collect In Person'],
            CardTipAdded: taskObj['Card Tip Added'],
            StreetView: taskObj['Street View'],
            TotalAfterDiscounts: taskObj['Total After Discounts'],
            StreetView: taskObj['Street View']
        };
    };

    useEffect(() => {
        const deliveryOptions = convertTaskDescription(taskDetails?.data?.taskDescription)
        setDrivermemo(deliveryOptions)
    }, [taskDetails]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setShowManualList(false)
        setFormData({ ...formData, taskAssignmentMode: '', driverId: '' })
        setAnchorEl(event.currentTarget);
    };

    const handleShowLogs = (data) => {
        if (data === 'activity') {
            setActivityLog(true)
            setRouteView(false)
        }
        else if (data === 'route') {
            setRouteView(true)
            setActivityLog(false)
        }
        else {
            if (!formData?.taskAssignmentMode?.trim()) {
                setErrors({ ...errors, taskAssignmentMode: 'Assign Mode is required' })
                return false
            }
            if (formData?.taskAssignmentMode === 'manual') {
                if (!formData?.driverId?.trim()) {
                    setErrors({ ...errors, driverId: 'Driver Id is required' })
                    return false
                }
            }
            const updatedFormData = {
                ...formData,
                taskId: taskDetails?.data?._id,
                updatedBy: parseUser?.firstName
            };

            setFormData(updatedFormData);
            setLoadingDone(true)
            dispatch(openCustomModalDialog({
                modal: 'DRIVER_FETCH',
                modalDataMsg: 'Please wait a few minutes..'
            }));
            dispatch(reassignmentTask({ data: updatedFormData }))
                .unwrap()
                .then(res => {
                    setLoadingDone(false)
                    setFormData({})
                    taskDetail()
                    // showResponseMessage(res?.message, 'success');
                    setShowManualList(false)
                    dispatch(openCustomDrawer({
                        drawer: '',
                        drawerData: '',
                        drawerApi: '',
                        activeScreen: ''
                    }))
                    dispatch(customApiSwitch({
                        dataSwitch: 'API_DATA_ROUTE',
                        routeData: roleTab
                    }));
                    dispatch(openCustomModalDialog({
                        modal: 'DRIVER_FETCH',
                        modalData: res,
                        modalDataMsg: ''
                    }));
                })
                .catch(err => {
                    setLoadingDone(false)
                    // showResponseMessage(err?.message, 'error');
                    dispatch(openCustomModalDialog({
                        modal: 'DRIVER_FETCH',
                        modalData: err,
                        modalError: true,
                        modalDataMsg: ''
                    }))
                });
            setRouteView(false)
            setActivityLog(false)
        }
    }
    function handleDataFromChild() {
        setRouteView(false)
        setActivityLog(false)
        setShowManualList(false)
    }

    const handleAutomaticDriver = () => {
        dispatch(openCustomModalDialog({
            modal: 'DRIVER_FETCH',
            modalData: '',
            modalDataMsg: ''
        }));
        dispatch(fetchAutomaticDriver(taskDetails?.data?._id)).unwrap().then(res => {
            taskDetail()
            dispatch(openCustomModalDialog({
                modal: 'DRIVER_FETCH',
                modalData: res,
                modalDataMsg: ''
            }));
        }).catch((err) =>
            dispatch(openCustomModalDialog({
                modal: 'DRIVER_FETCH',
                modalData: err,
                modalError: true,
                modalDataMsg: ''
            })))
    }

    const startTaskHandler = (event) => {
        setRouteView(false)
        setActivityLog(false)
        const { name, value } = event.target;
        if (name === 'taskAssignmentMode') {
            setAnchorEl(null);
            if (value === 'manual') {
                setShowManualList(true)
            }
            else {
                handleAutomaticDriver()
                setErrors({ driverId: '' })
                setShowManualList(false)
            }
        }
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: value
            }
            return newData;
        })
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            driverId: value
        }));
    };

    const eventIconMap = {
        'created': <TaskCreatedIcon />,
        'assigned': <TaskAssignedIcon />,
        'started': <TaskStartedIcon />,
        'departed': <DriverDepartedIcon />,
        'arrived': <DriverArrivedIcon />,
        'completed': <TaskCompletedIcon />,
        'cancelled': <CancelledIcon />
    };

    let createdDate = moment(taskDetails?.data?.createdDate);

    // Add one hour
    let newDate = createdDate.add(1, 'hours');

    return (
        <>
            <Box className='cus-wrapper-drawer'>
                {taskLoading ? <Loader /> : <>
                    <Box display='flex' alignItems='center' justifyContent='space-between' p={2} style={{ borderBottom: '1px solid  #1E1E24' }} className='drawer-head-sec'>
                        <Typography className='text-14 text-white-700'>{separateWords(taskDetails?.data?.taskType)} {taskDetails?.data?.taskDisplayId}</Typography>
                        <div className='d-flex gap-3'>
                            <Typography className='d-flex align-item-center gap-1 text-green-100 text-12'>
                                {
                                    drawer !== 'TASK_DETAIL_ROUTE' &&
                                    <StatusTask value={taskDetails?.data?.taskStatus} icon={'showIcon'} />
                                }
                            </Typography>
                            <Close onClick={closeModal} style={{ cursor: 'pointer', color: '#ffffff' }} />
                        </div>
                    </Box>
                    <Box className='drawer-scroll-sec'>
                        <Box display='flex' alignItems='center' flexDirection='column' px={2} py={4} style={{ borderBottom: '1px solid  #1E1E24' }} gap={4} className='drawer-listing'>
                            <div className='w-100 d-flex jc-s-btwn gap-2'>
                                <div className='d-flex gap-2'>
                                    <div className='d-flex'>
                                        <TaskCreatedIcon />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <Typography className='text-black-400 text-12 line-height-normal mb-1'>Transaction ID</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.transactionNumber ? taskDetails?.data?.transactionNumber : '-'}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 d-flex jc-s-btwn gap-2'>
                                <div className='d-flex gap-2'>
                                    <div className='d-flex'>
                                        <AssignIcon />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <Typography className='text-black-400 text-12 line-height-normal mb-1'>Assigned to</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.driverDetails?.fleetName ? taskDetails?.data?.driverDetails?.fleetName : '--'}</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.driverDetails?.fleetPhone ? `${formatPhoneNumber(taskDetails?.data?.driverDetails?.fleetPhone)}` : '--'}</Typography>
                                    </div>
                                </div>
                                {
                                    ((taskDetails?.data?.taskStatus !== 'completed') && (drawer !== 'TASK_DETAIL_ROUTE') && (taskDetails?.data?.taskStatus !== 'cancelled') && (taskDetails?.data?.taskType === 'deliveryTask')) &&
                                    <div className='d-flex position-relative'>
                                        <Button
                                            id=''
                                            aria-controls={open ? '' : undefined}
                                            aria-haspopup='true'
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            className='d-flex gap-2 text-white-900'
                                        >
                                            <ReassignDriverIcon />
                                            <Typography fontWeight='semibold' className='text-12 text-white-900 text-transform-none'>Reassign Driver</Typography>
                                            <KeyboardArrowDownIcon />
                                        </Button>
                                        <p className='button-error'>{errors?.driverId || errors?.taskAssignmentMode}</p>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{ className: 'hd-dropdown reassign-driver-dropdown' }}
                                        >
                                            <FormControl>
                                                <RadioGroup
                                                    value={formData?.taskAssignmentMode}
                                                    defaultValue='auto'
                                                    name='taskAssignmentMode'
                                                    className='radio-colors'
                                                    onChange={(e) => startTaskHandler(e)}
                                                >
                                                    <FormControlLabel className='field-off-duty' value='manual' control={<Radio />} label='Manual' />
                                                    <FormControlLabel className='field-idle' value='automatic' control={<Radio />} label='Automatic' />
                                                </RadioGroup>
                                            </FormControl>
                                        </Menu>
                                    </div>
                                }
                            </div>
                            {
                                (taskDetails?.data?.taskType === 'deliveryTask') &&
                                <div className='w-100 d-flex jc-s-btwn'>
                                    <div className='d-flex gap-2'>
                                        <div className='d-flex'>
                                            <RecipientIcon />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography className='text-black-400 text-12 line-height-normal mb-1'>Recipient</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.recipientDetails?.name ? taskDetails?.data?.recipientDetails?.name : '--'}</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.recipientDetails?.phone ? `${formatPhoneNumber(taskDetails?.data?.recipientDetails?.phone)}` : '--'}</Typography>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Box>
                        <Box display='flex' alignItems='center' flexDirection='column' px={2} py={4} gap={4} className='drawer-listing' style={{ borderBottom: '1px solid  #1E1E24' }}>
                            <div className='w-100 d-flex flex-column'>
                                <Typography className='text-white-700 text-24 mb-3' fontWeight='semibold'>Task Details</Typography>
                                <Box display='flex' alignItems='center' flexDirection='column' gap={4}>
                                    {taskDetails?.data?.taskType !== 'breakTask' &&
                                        <div className='w-100 d-flex jc-s-btwn gap-2'>
                                            <div className='d-flex gap-2'>
                                                <div className='d-flex'>
                                                    <MapPinIcon />
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <Typography className='text-black-400 text-12 line-height-normal mb-1'>Destination</Typography>
                                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{(taskDetails?.data?.address?.address || taskDetails?.data?.address?.address1) ? taskDetails?.data?.address?.address1 || taskDetails?.data?.address?.address : '-'}</Typography>
                                                </div>
                                            </div>
                                            {
                                                (taskDetails?.data?.address?.address1 || taskDetails?.data?.address?.address) && <div className='d-flex align-item-top'>
                                                    <Link target='_blank' to={`https://www.google.com/maps/search/?api=1&query=${taskDetails?.data?.address?.address1 || taskDetails?.data?.address?.address}`}>
                                                        <Button
                                                            aria-controls={open ? '' : undefined}
                                                            aria-haspopup='true'
                                                            aria-expanded={open ? 'true' : undefined}
                                                            className='d-flex border-none p-0 min-width-auto h-auto'
                                                        >
                                                            <RedirectIcon />
                                                        </Button>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        (taskDetails?.data?.taskType === 'deliveryTask') &&
                                        <>
                                            <div className='w-100 d-flex jc-s-btwn'>
                                                <div className='d-flex gap-2'>
                                                    <div className='d-flex'>
                                                        <TimeIcon />
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <Typography className='text-black-400 text-12 line-height-normal mb-1'>Task Scheduled</Typography>
                                                        {
                                                            taskDetails?.data?.orderType === 'schedule' ?
                                                                <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>Between {taskDetails?.data?.slotDetails?.start} and {taskDetails?.data?.slotDetails?.end} </Typography>
                                                                :
                                                                <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>Before {formatTimestampToDate24Hours(newDate.toISOString())}</Typography>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 d-flex jc-s-btwn'>
                                                <div className='d-flex gap-2'>
                                                    <div className='d-flex'>
                                                        <TimeIcon />
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <Typography className='text-black-400 text-12 line-height-normal mb-1'>Service Time</Typography>
                                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.taskType === 'breakTask' ? '30 Minute(s)' : taskDetails?.data?.serviceTime ? convertMinutesToHoursAndMinutes(taskDetails?.data?.serviceTime) : '-'}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        taskDetails?.data?.taskType === 'breakTask' &&
                                        <>
                                            <div className='w-100 d-flex jc-s-btwn'>
                                                <div className='d-flex gap-2'>
                                                    <div className='d-flex'>
                                                        <TaskDurationIcon />
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <Typography className='text-black-400 text-12 line-height-normal mb-1'>Start Time</Typography>
                                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.fromTime ? taskDetails?.data?.fromTime : '-'}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 d-flex jc-s-btwn'>
                                                <div className='d-flex gap-2'>
                                                    <div className='d-flex'>
                                                        <TaskDurationIcon />
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <Typography className='text-black-400 text-12 line-height-normal mb-1'>End Time</Typography>
                                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.toTime ? taskDetails?.data?.toTime : '-'}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    }
                                    <div className='w-100 d-flex jc-s-btwn'>
                                        <div className='d-flex gap-2'>
                                            <div className='d-flex'>
                                                <UserIcon />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <Typography className='text-black-400 text-12 line-height-normal mb-1'>Created By</Typography>
                                                <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.createdDetails?.name ? taskDetails?.data?.createdDetails?.name : taskDetails?.data?.taskType === 'deliveryTask' ? 'website' : 'Admin'}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        taskDetails?.data?.taskStatus === 'cancelled' &&
                                        <div className='w-100 d-flex jc-s-btwn'>
                                            <div className='d-flex gap-2'>
                                                <div className='d-flex' style={{ color: '#b5b5b7' }}>
                                                    <InActiveIcon />
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <Typography className='text-black-400 text-12 line-height-normal mb-1'>Failed Reason </Typography>
                                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.failureDetails?.failureReasonNote ? taskDetails?.data?.failureDetails?.failureReasonNote : '--'}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Box>
                            </div>
                        </Box>
                        {
                            (taskDetails?.data?.taskType === 'deliveryTask') &&
                            <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='column' p={2} gap={1}>
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Drop Off</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{drivermemo?.DropOff ? drivermemo?.DropOff === 'meetmydoor' ? 'Meet My Door' : drivermemo?.DropOff === 'discreetcurbside' ? 'Discreet Curbside' : '' : '-'}</Typography>
                                </div>
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Instructions</Typography>
                                    {drivermemo?.Instructions ? <Tooltip title={drivermemo?.Instructions} arrow placement='left-start' >
                                        <Typography className='text-white-700 text-16 line-height-normal ellipsis-one' fontWeight='semibold'>{drivermemo?.Instructions}</Typography>
                                    </Tooltip> : <Typography className='text-white-700 text-16 line-height-normal ellipsis-one' fontWeight='semibold'>-</Typography>}
                                </div>
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Subtotal</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.subTotal ? `$${taskDetails?.data?.subTotal.toFixed(2)}` : '$0'}</Typography>
                                </div>
                                {
                                    (taskDetails?.data?.afterTaxDiscount !== 0 && taskDetails?.data?.totalDiscount) &&
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Rewards Discount</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.afterTaxDiscount ? `$${taskDetails?.data?.afterTaxDiscount.toFixed(2)}` : '$0'}</Typography>
                                    </div>
                                }
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Total after discount</Typography>
                                    {
                                        (taskDetails?.data?.paymentOption === 'Split' && taskDetails?.data?.splitPayment) ?
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{`$${(taskDetails?.data?.total + (taskDetails?.data?.splitPayment?.creditDebitAmt * 0.05)).toFixed(2)}`}</Typography>
                                            :
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{drivermemo?.TotalAfterDiscounts ? drivermemo?.TotalAfterDiscounts : '$0'}</Typography>
                                    }
                                </div>
                                {
                                    (taskDetails?.data?.totalDiscount !== 0 && taskDetails?.data?.totalDiscount) &&
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Discount</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.totalDiscount ? `$${taskDetails?.data?.totalDiscount.toFixed(2)}` : '$0'}</Typography>
                                    </div>
                                }
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Payment type</Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{drivermemo?.PaymentType ? drivermemo?.PaymentType : '-'}</Typography>
                                </div>

                                {
                                    drivermemo?.PaymentType === 'Credit Card' &&
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Credit Card Compliance</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.creditCardFee ? `$${taskDetails?.data?.creditCardFee.toFixed(2)}` : '-'}</Typography>
                                    </div>
                                }
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Taxes <ErrorOutlineRoundedIcon
                                        style={{ color: '#8A8A98', cursor: 'pointer', position: 'relative', top: '-3px' }}
                                        onClick={() => setTaxesExpend(!taxesExpend)} /></Typography>
                                    <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.taxResult?.totalPostCalcTax ? `$${taskDetails?.data?.taxResult?.totalPostCalcTax}` : '$0'}</Typography>
                                </div>
                                {
                                    (taskDetails?.data?.paymentOption === 'Split' && taskDetails?.data?.splitPayment) &&
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Credit Card Compliance</Typography>
                                        <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.splitPayment?.creditDebitAmt ? `$${(((taskDetails?.data?.splitPayment?.creditDebitAmt) * 0.05))?.toFixed(2)}` : '-'}</Typography>
                                    </div>
                                }
                                {
                                    taxesExpend && <div className='w-100 pl-2'>
                                        <div className='w-100 d-flex jc-s-btwn gap-2 pt-1 pb-1'>
                                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>City Tax</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.taxResult?.totalCityTax ? `$${taskDetails?.data?.taxResult?.totalCityTax}` : '$0'}</Typography>
                                        </div>
                                        <div className='w-100 d-flex jc-s-btwn gap-2 pt-1 pb-1'>
                                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>State Tax </Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.taxResult?.totalStateTax ? `$${taskDetails?.data?.taxResult?.totalStateTax}` : '$0'}</Typography>
                                        </div>
                                        <div className='w-100 d-flex jc-s-btwn gap-2 pt-1 pb-1'>
                                            <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Excise Tax </Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.taxResult?.totalExciseTax ? `$${taskDetails?.data?.taxResult?.totalExciseTax}` : '$0'}</Typography>
                                        </div>
                                    </div>
                                }
                            </Box>
                        }
                        <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='column' p={2} gap={1} className='pt-1 pb-0'>
                            {
                                (taskDetails?.data?.taskType === 'deliveryTask') &&
                                <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='column' p={2} gap={1} className='w-100 border-radius-10' style={{ background: 'rgba(1, 99, 255, 0.20)' }}>
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Amount paid online</Typography>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>{drivermemo?.AmountPaidOnline ? drivermemo?.AmountPaidOnline : '$0'}</Typography>
                                    </div>
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Cash to collect in person</Typography>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>{drivermemo?.CashToCollectInPerson ? drivermemo?.CashToCollectInPerson
                                            : '$0'}</Typography>
                                    </div>
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>Card tip added</Typography>
                                        <Typography className='text-white-700 text-14 line-height-normal' fontWeight='semibold'>{drivermemo?.CardTipAdded ? drivermemo?.CardTipAdded : '$0'}</Typography>
                                    </div>
                                </Box>
                            }
                            {
                                taskDetails?.data?.taskType !== 'breakTask' &&
                                <Box display='flex' alignItems='center' gap={2} py={3} className='w-100' style={{ borderBottom: '1px solid  #1E1E24' }}>
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <div className='d-flex gap-2'>
                                            <div className='d-flex'>
                                                <img className='border-radius-8 object-fit-contain' width='60px' height='60px' src={taskDetails?.data?.streetView ? taskDetails?.data?.streetView : noDataFound} />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <Typography className='text-black-400 text-12 line-height-normal mb-1'>Street view</Typography>
                                                <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.address?.address1 || taskDetails?.address?.address}</Typography>
                                            </div>
                                        </div>

                                        <div className='d-flex align-item-top'>
                                            <Link target='_blank' to={`https://www.google.com/maps/search/?api=1&query==${taskDetails?.data?.address?.address1}`}>
                                                <Button className='d-flex border-none p-0 min-width-auto h-auto'>
                                                    <RedirectIcon />
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Box>
                            }
                            <Box display='flex' alignItems='center' flexDirection='column' gap={4} py={3} className='w-100' style={{ borderBottom: '1px solid  #1E1E24' }}>
                                <div className='w-100 d-flex jc-s-btwn gap-2'>
                                    <div className='d-flex gap-2'>
                                        <div className='d-flex'>
                                            <TaskDurationIcon />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <Typography className='text-black-400 text-12 line-height-normal mb-1'>Task Duration</Typography>
                                            <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.fleetDuration ? convertMinutesToHoursAndMinutes(taskDetails?.data?.fleetDuration) : '-'}</Typography>
                                        </div>
                                    </div>
                                </div>
                                {taskDetails?.data?.taskType !== 'breakTask' &&
                                    <div className='w-100 d-flex jc-s-btwn gap-2'>
                                        <div className='d-flex gap-2'>
                                            <div className='d-flex'>
                                                <TravellIcon />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <Typography className='text-black-400 text-12 line-height-normal mb-1'>Total Distance Travelled</Typography>
                                                <Typography className='text-white-700 text-16 line-height-normal' fontWeight='semibold'>{taskDetails?.data?.actualDistance ? addCommas(taskDetails?.data?.actualDistance?.toFixed(2)) : '0'} Miles</Typography>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Box>
                        </Box>
                        {
                            (taskDetails?.data?.taskType === 'deliveryTask') &&
                            <Box display='flex' alignItems='center' flexDirection='column' gap={4} px={2} py={4} className='w-100' style={{ borderBottom: '1px solid  #1E1E24' }}>
                                <div className='w-100 d-flex flex-column'>
                                    <Typography className='text-white-700 text-24 mb-3' fontWeight='semibold'>Order Details</Typography>
                                    <Box display='flex' alignItems='center' flexDirection='column' gap={4}>
                                        <List className='d-flex flex-column align-item-center w-100 gap-4'>
                                            {
                                                taskDetails?.data?.items?.map((data) => {
                                                    const priceBreak = data?.product?.priceBreaks
                                                    const pricing = () => {
                                                        if (!Array.isArray(priceBreak)) return priceBreak;
                                                        return priceBreak?.find(pb => pb.name === '1 each') ?? {};
                                                    }
                                                    const { salePrice: _salePrice = 0, assignedPrice: _assignedPrice = 0, price: _price = 0 } = pricing() ?? {};
                                                    const itemOnSale = _price - (_salePrice || _assignedPrice) > 0 ? true : false

                                                    return (
                                                        <ListItem key={data?._id} className='w-100' alignItems='center' gap={2}>
                                                            <ListItemAvatar sx={{ mr: 1.5 }} className='pro-avatar-sec'>
                                                                <Avatar
                                                                    alt='Product Name'
                                                                    src={data?.product?.assets?.length === 0 ? noDataFound : data?.product?.assets?.[0]?.publicURL}
                                                                    sx={{ width: 82, height: 82, borderRadius: 4 }}
                                                                />
                                                            </ListItemAvatar>
                                                            <Box className='w-100'>
                                                                <Typography variant='body2' color='secondary' className='text-14'>{data?.product?.brandName ? data?.product?.brandName : '-'}</Typography>

                                                                <Typography variant='h6' fontWeight='semibold' className='text-16 lh-normal'>{data?.productName ? data?.productName : '-'}</Typography>

                                                                <div className='d-flex align-item-center jc-s-btwn'>
                                                                    <Typography variant='h6' fontWeight='medium' component='span' mr={1} className='d-flex align-item-center gap-2 text-16'>
                                                                        {
                                                                            itemOnSale ?
                                                                                <>
                                                                                    <span className='text-white-700 text-line-through'>{_price ? `$${_price?.toFixed(1)}` : '$0'}</span>
                                                                                    <span className='text-white-700 '>${_salePrice}</span>
                                                                                    <span className='flower-type' style={{ color: '#24CA49' }}>{getPercentage(_price, _salePrice) || 0}% Off</span>
                                                                                </>
                                                                                : <span className='text-white-700'>{_price ? `$${_price?.toFixed(1)}` : '$0'}</span>
                                                                        }
                                                                    </Typography>
                                                                    <Typography variant='h6' fontWeight='medium' className='text-16 text-white-700'>{data?.quantity ? `x${data?.quantity}` : '0'}</Typography>
                                                                </div>
                                                                <Box mt={1}>
                                                                </Box>
                                                            </Box>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </div>
                            </Box>
                        }
                        <Box display='flex' alignItems='center' flexDirection='column' px={2} py={4} gap={4} className='drawer-listing timeline-sec'>
                            <div className='w-100 d-flex flex-column'>
                                <Typography className='text-white-700 text-24 mb-3' fontWeight='semibold'>Timeline</Typography>
                                <Box display='flex' alignItems='center' flexDirection='column' gap={4}>
                                    <Timeline className='w-100'>
                                        {
                                            taskDetails?.data?.filteredTimeline?.map((event, index) => {
                                                return (
                                                    <TimelineItem key={index}>
                                                        <TimelineSeparator>
                                                            {eventIconMap[event.status] || <div />}
                                                            {index < taskDetails?.data?.filteredTimeline?.length - 1 && <TimelineConnector />}
                                                        </TimelineSeparator>
                                                        <TimelineContent>
                                                            <div className='d-flex flex-column'>
                                                                <Typography className='text-white-700 text-16 line-height-normal mb-1 font-w-500'>
                                                                    {event.event}
                                                                </Typography>
                                                                <Typography className='text-black-400 text-12 line-height-normal'>
                                                                    {
                                                                        taskDetails?.data?.taskType === 'breakTask' ?
                                                                            formatTimestampToDate24HoursBreak(event.time) :
                                                                            formatTimestampToDate24Hours(event.time)
                                                                    }
                                                                </Typography>
                                                            </div>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                )
                                            })
                                        }
                                    </Timeline>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                    {
                        taskDetails?.data?.taskType !== 'breakTask' &&
                        <Box display='flex' alignItems='center' justifyContent='space-between' p={2} gap={2} style={{ borderTop: '1px solid  #1E1E24' }} className='drawer-bottom-btns'>
                            <Button onClick={() => handleShowLogs('activity')} variant='contained' color='primary' className='d-flex align-item-center gap-2'>
                                <ActivityLogIcon />
                                Activity Log
                            </Button>
                            <Button onClick={() => handleShowLogs('route')} variant='contained' color='primary' className='d-flex align-item-center gap-2 purple-btn'>
                                <ViewRouteIcon />
                                View Route
                            </Button>
                            {
                                ((taskDetails?.data?.taskStatus !== 'completed') && (drawer !== 'TASK_DETAIL_ROUTE') && (taskDetails?.data?.taskStatus !== 'cancelled')) && (taskDetails?.data?.taskType === 'deliveryTask') &&
                                <>
                                    {
                                        !formData?.driverId?.trim() ?
                                            <Button style={{ cursor: 'auto' }} variant='contained' color='primary' className={'d-flex align-item-center gap-2 grey-btn'}>
                                                <CheckIcon />
                                                Done
                                            </Button>
                                            :
                                            <MDButton className={'d-flex align-item-center gap-2 green-btn'} type='button' variant='contained' color='primary' onClick={() => handleShowLogs('done')} disabled={loadingDone} loading={loadingDone}>
                                                Done
                                            </MDButton>
                                    }
                                </>
                            }
                        </Box>
                    }
                </>
                }
            </Box>
            {activityLog &&
                <TaskLogs sendDataToParent={handleDataFromChild} taskId={taskDetails?.data?._id} />
            }
            {routeView &&
                <TaskRoute sendDataToParent={handleDataFromChild} taskId={taskDetails?.data?._id} taskAddress={taskDetails?.data?.address || taskDetails?.data?.address?.address1} />
            }
            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} DriverIdType={true} fleetId={taskDetails?.data?.driverDetails?._id} dispatchData={'manual'} taskId={taskDetails?.data?._id} />
            }
        </>
    )
});
export default TaskDetails