import dayjs from 'dayjs';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { HYPERDRIVE } from 'utilities/constants';
import { HYPERWOLF } from 'utilities/constants';
import { HEMP } from 'utilities/constants';
import BreakIcon from 'common/customIcons/break';
import ReturnIcon from 'common/customIcons/return';
import StartIcon from 'common/customIcons/start';
import axios from 'axios';

export function getData(label) {
    if (typeof window !== 'undefined') {
        // Client-side-only code
        const data = window && window?.localStorage?.getItem(label);
        return data;
    }
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function setData(label, data) {
    if (typeof window !== 'undefined') {
        // Client-side-only code
        if (window && window?.localStorage)
            localStorage?.setItem(label, JSON.stringify(data));
    }
}

export function removeData(label) {
    if (typeof window !== 'undefined') {
        // Client-side-only code
        if (window && window.localStorage)
            localStorage.removeItem(label);
    }
}

export const getQueryString = (query = {}) => {
    const queryString = Object.keys(query)
        .reduce((acc, key) => {
            if (acc !== '') {
                acc += '&';
            }
            return acc + `${key}=${encodeURIComponent(query[key])}`;
        }, '');
    return queryString;
};

export const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month}/${day}/${year} ${displayHours}:${minutes} ${ampm}`;
};

export const formattedDate = (dateString) => {
    // Parse the original date string to a Date object
    const date = new Date(dateString);

    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
};

export const convertToTimestamp = (dateString) => {
    const date = new Date(dateString); // Create a Date object from the string
    const timestamp = date.getTime();
    return timestamp;
};

export const convertToTimestampWithMoment = (dateString) => {
    const timestamp = moment(dateString).valueOf();
    return timestamp;
};

export const formatTimestampToDate24Hours = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('MMM DD, YYYY hh:mm a');
    return formattedDate;
};

export const formatTimestampToDate24HoursBreak = (timestamp) => {
    const date = moment.utc(timestamp);
    const formattedDate = date.format('MMM DD, YYYY hh:mm a');
    return formattedDate;
};

export const formatTimestampToDate = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('DD-MM-YYYY HH:mm');
    return formattedDate;
};

export const formatTimestampToDateView = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('MMM DD, YYYY hh:mm a');
    return formattedDate ? formattedDate : '-';
};

export const formatTimestampToTimeWithTimezone = (timestamp, timezone) => {
    return dayjs(timestamp).tz(timezone).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const formatTimestampToTimeTimezone = (timestamp) => {
    return dayjs(timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const formatTimestampToMap = (timestamp) => {
    return dayjs(timestamp).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

export const formatTimestampToMapOld = (timestamp) => {
    return dayjs(timestamp).format('YYYY-MM-DD');
};

export const formatTimestampToWithoutTime = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedDate = dayjs(timestamp).format('MMM DD, YYYY');
    return formattedDate;
};

export const formatTimestampToWithoutTimeToday = (timestamp) => {
    const date = dayjs(timestamp);
    const now = dayjs();

    // Check if the date is today
    if (date.isSame(now, 'day')) {
        return 'Today';
    }

    // Check if the date is yesterday
    if (date.isSame(now.subtract(1, 'day'), 'day')) {
        return 'Yesterday';
    }

    // Format the date if it's neither today nor yesterday
    return date.format('MMM DD, YYYY');
};

export const formatTimestampToWithoutTimeTodayTomorrow = (timestamp) => {
    const date = dayjs(timestamp);
    const now = dayjs();

    // Check if the date is today
    if (date.isSame(now, 'day')) {
        return 'Today 12 am';
    }
    if (date.isSame(now.add(1, 'day'), 'day')) {
        return 'Tomorrow 12 am';
    }

    // Format the date if it's neither today nor yesterday
    return date.format('MMM DD, YYYY');
};

export const formatTimestampToTime = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedTime = dayjs(timestamp).format('HH:mm');
    return formattedTime;
};

export const formatTimestampToTimeAmPm = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedTime = moment(timestamp).format('hh:mm A');
    return formattedTime;
};

export const formatTimestampTo12HourTime = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    const formattedTime = dayjs(timestamp).format('hh:mm a');
    return formattedTime;
};

export const formatTimestampTo12HourTimeUtc = (timestamp) => {
    // Assuming the timestamp is in milliseconds
    // const formattedTime = dayjs(timestamp).format('hh:mm a');
    // return formattedTime;
    const date = moment.utc(timestamp);
    const formattedDate = date.format('hh:mm a');
    return formattedDate;
};

export function isEmptyObject(obj = {}) {
    // Using Object.keys() to get an array of object keys and checking its length
    return Object.keys(obj).length === 0;
}

export function isUnder21(dateOfBirth) {
    const dob = dayjs(dateOfBirth);
    const currentDate = dayjs();
    const age = currentDate.diff(dob, 'year');
    return age < 21;
}

export function formatAddress(deliveryAddress = {}) {
    // const { street, city, state, country } = address;
    const { address = '', city = '', state = '', zipcode = '', zipCode = '', apartment = '', country = '' } = deliveryAddress;

    // Using template literals to concatenate the address elements
    // const formattedAddress = `${street}, ${city}, ${state}, ${zipcode}`;

    // const formattedAddress = apartment ? `${apartment}, ${address}, ${city}, ${state}, ${zipcode || zipCode},${country}` : `${address}, ${city}, ${state}, ${zipcode || zipCode}, ${country}`;
    const formattedAddress = apartment ? `${apartment}, ${address}` : `${address}`;
    return formattedAddress;
}

export function formatDeliveryAddress(deliveryAddress) {
    const keysOrder = ['apartment', 'address', 'city', 'state', 'zipCode', 'country'];
    const result = [];

    for (const key of keysOrder) {
        if (deliveryAddress[key] && deliveryAddress[key].trim() !== '') {
            result.push(deliveryAddress[key].trim());
        }
    }

    return result.join(', ');
}

export function getPercentage(offeredPrice, salePrice) {
    if (salePrice === offeredPrice) return 100;
    return parseFloat(((offeredPrice - salePrice) / offeredPrice) * 100).toFixed(0);
}


export function isLoggedIn() {
    let user = JSON.parse(getData('login-user-info')) || {};
    if (user.access_token) return { isAuthenticated: true, user };
    else return { isAuthenticated: false };
}

export function logOut(userType) {
    return new Promise((res) => {
        localStorage.removeItem(userType);
        res(true);
    });
}

// awaiting_payment, awaiting_shipment, shipped, on_hold, cancelled, pending_fulfillment
export const orderStatus = [
    {
        key: 'awaiting_shipment',
        value: 'In Progress',
    },
    {
        key: 'shipped',
        value: 'On the way',
    },
    {
        key: 'cancelled',
        value: 'Cancelled',
    },
    {
        key: 'awaiting_payment',
        value: 'Payment Pending',
    },
    {
        key: 'on_hold',
        value: 'On Hold'
    },

];

export const orderDetailStatus = [
    {
        key: 'awaiting_shipment',
        value: 'In Progress',
    },
    {
        key: 'shipped',
        value: 'Shipped',
    },
    {
        key: 'cancelled',
        value: 'Cancelled',
    },
    {
        key: 'awaiting_payment',
        value: 'Payment Pending',
    },
    {
        key: 'delivered',
        value: 'Delivered',
    },
    // {
    //     key: 'not_returnable',
    //     value: 'Un-Returnable',
    // },
    {
        value: 'Delivery Failed',
        key: 'delivery_failed'
    },
    {
        value: 'Delivery Claim Filed',
        key: 'delivery_claimed_filed'
    },
];

export const returnRequestStatus = [
    {
        key: 'rejected',
        value: 'Rejected',
    },
    {
        key: 'approved',
        value: 'Approved',
    },
];

export const sortsBy = [
    {
        key: 'Today',
        value: 'today'
    },
    {
        key: '1 Week',
        value: 'week'
    },
    {
        key: '1 Month',
        value: 'month'
    },
    {
        key: '3 Months',
        value: 'threeMonths'
    },
    {
        key: 'All Time',
        value: 'All Time'
    },
    // {
    //     key: 'Custom',
    //     value: 'custom'
    // },
];

export const orderFilterTabs = [
    {
        key: 'All Orders',
        value: ''
    },
    {
        key: 'In-progress',
        value: 'awaiting_shipment'
    },
    {
        key: 'Shipped',
        value: 'shipped'
    },
    {
        key: 'Completed',
        value: 'delivered'
    },
    {
        key: 'Cancelled',
        value: 'cancelled'
    },
    // {
    //     key: 'Un-Returnable',
    //     value: 'not_returnable'
    // },
    {
        key: 'Warranty',
        value: 'warranty'
    },
    {
        key: 'Delivery Failed',
        value: 'delivery_failed'
    },
    {
        key: 'Delivery Claim Filed',
        value: 'delivery_claimed_filed'
    },
];

export const driverFilterTabs = [
    {
        key: 'Drivers',
        value: ''
    },
    {
        key: 'Breaks',
        value: 'breaks'
    },
    {
        key: 'Approvals',
        value: 'approvals'
    }
];

export const strainsTabs = [
    {
        key: 'Strain Homepage',
        value: 'main_strains'
    },
    {
        key: 'Strains',
        value: 'strains'
    }
];

export const brandTabs = [
    {
        key: 'Brand Homepage',
        value: 'main_brands'
    },
    {
        key: 'Brands',
        value: 'brands'
    }
];

export const roleAndPermissionTabs = [
    {
        key: 'Users',
        value: 'users'
    },
    {
        key: 'Roles',
        value: 'roles'
    }
];

export function addCommas(number) {
    let [integerPart, decimalPart] = number?.toString().split('.');

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts with commas
    const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

    return formattedNumber;
}

export function addCommasWithToFixed(number) {
    if (!number) return '';
    number = Number(number)?.toFixed(2);
    // if (typeof number !== 'number') {
    //     throw new Error('Input must be a number.');
    // }

    // Convert the number to a string and split the decimal part
    let [integerPart, decimalPart] = number?.toString().split('.');

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts with commas
    const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

    return formattedNumber;
}

export function capitalizeText(text) {
    if (!text) return '';
    if (typeof text !== 'string') {
        throw new Error('Input should be a string');
    }

    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function addSpaceBeforeCapital(text) {
    return text.replace(/([A-Z])/g, ' $1').trim();
}


export function validatePassword(password) {
    // Minimum length of 8 characters
    const minLength = 8;
    // Require at least one uppercase letter, one lowercase letter, one digit, and one special character
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    // Check length
    if (password.length < minLength) {
        return false;
    }

    // Check for required character types
    if (!uppercaseRegex.test(password) || !lowercaseRegex.test(password) || !digitRegex.test(password) || !specialCharRegex.test(password)) {
        return false;
    }

    // If all checks pass, the password is considered valid
    return true;
}


export function formatUsPhoneNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleanedNumber = phoneNumber.replace(/\D/g, '');



    // Format the phone number
    let formattedNumber;
    if (cleanedNumber.length > 10) {
        formattedNumber = `(${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
    }
    else {
        formattedNumber = `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)}`;
    }

    return formattedNumber;
}

export function formatBlazePhoneNumber(phoneNumber) {

    const cleanedNumber = phoneNumber?.replace(/^\+1/, '');

    const formattedNumber = `(${cleanedNumber?.slice(0, 3)}) ${cleanedNumber?.slice(3, 6)}-${cleanedNumber.slice(6)}`;
    return formattedNumber;

}

export function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export function separateUnitAndNumber(text) {
    // Define a regular expression to match a number followed by optional whitespace, and then an optional percentage sign
    const regex = /^(\d+(\.\d+)?)(\s*%)?(\s*([a-zA-Z]+))?$/;

    // Use the regular expression to match the text
    const match = text.match(regex);

    if (match) {
        // Extract the value and unit from the matched groups
        const value = parseFloat(match[1]);
        const unit = match[5] || null; // Use null if no unit is present

        // Return an object containing the value and unit
        return { value, unit };
    } else {
        // Return null if the text doesn't match the expected pattern
        return null;
    }
}

export function isNumber(value) {
    return /^\d+$/.test(value);
}

export function isNumberWithDot(value) {
    return /^\d+.$/.test(value);
}

export function isValidNumber(input) {
    const regex = /^\d+(\.\d+)?$/;
    return regex.test(+input);
}

export function isValidFileExtension(file, validExtensions) {
    const fileName = file?.name;
    const fileExtension = fileName.split('.').pop();
    return validExtensions.includes(fileExtension.toLowerCase());
}

export function generatePromoCodes(n) {
    const length = 7;
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    const promoCodes = [];

    for (let i = 0; i < n; i++) {
        let promoCode = '';
        for (let j = 0; j < length; j++) {
            promoCode += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        promoCodes.push(promoCode);
    }

    return promoCodes;
}

export const RolesAndPermissions = [
    {
        'Super Admin': {
            'Permissions': [
                'All'
            ]
        },
        'Shop Admin': {
            'Permissions': [
                'Products',
                'Orders',
                'Inventory',
                'Members',
                'Regions',
                'POS',
                'Banners',
                'Promotions'
            ]
        },
        'Shop Manager': {
            'Permissions': [
                'Products',
                'Orders',
                'Inventory',
                'Members',
                'Regions',
                'POS',
                'Banners',
                'Promotions'
            ]
        },
        'Inventory Manager': {
            'Permissions': [
                'Products',
                'Inventory',
                'Sub-categories',
                'Categories'
            ]
        },
        'Sales Associate': {
            'Permissions': [
                'Orders',
                'Members',
                'POS'
            ]
        },
        'Customer Support': {
            'Permissions': [
                'Orders',
                'Members',
                'POS'
            ]
        },
        'Marketing Manager': {
            'Permissions': [
                'Strains',
                'Terpenes (Cannabinoids)',
                'Categories',
                'Subcategories',
                'Brands - all',
                'FAQ - all',
                'Products - all',
                'Banners - all'
            ]
        },
        'Developer': {
            'Permissions': [
                'Admin'
            ]
        }
    }
];

export const downloadFile = (fileLink, fileName) => {
    return new Promise((resolve, reject) => {
        if (fileLink) {
            let link = document.createElement('a');
            link.href = fileLink;
            link.download = `${fileName}.csv`;
            link.click();
            return resolve(true);
        }
        else {
            return reject(false);
        }
    });
};

export const parseDate = (dateString) => {
    const format = 'DD-MM-YY HH:mm:ss';
    const parsed = dayjs(dateString, format);
    return parsed.isValid() ? parsed.format() : 'Invalid date';
};

export function generateEncryptedAuthKey() {
    let encrypted = CryptoJS.AES.encrypt(process.env.REACT_APP_API_AUTH_KEY, process.env.REACT_APP_AUTH_PHRASE_KEY, {
        mode: CryptoJS.mode.ECB,
    }).toString();
    return encrypted;
}


export function getImageExtension(filename) {
    // Check if the filename contains a period
    if (filename.lastIndexOf('.') !== -1) {
        // Extract the substring from the last period to the end of the filename
        return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
    } else {
        // If no period is found, return an empty string
        return '';
    }
}

export const getLocaUserInfo = () => {
    if (typeof window !== 'undefined') {
        // Client-side-only code
        const data = window && window?.localStorage?.getItem('login-user-info');
        if (data) {
            return JSON.parse(data);
        }
        return {};
    }
};

export const getMarginToSale = (unitPrice, margin) => {
    const salePrice = +unitPrice * (100 + +margin) / 100;
    return salePrice;
};

export const getSaleToMargin = (unitPrice, salePrice) => {
    const margin = 100 * (+salePrice - +unitPrice) / +unitPrice;
    return margin;
};

export const calculateHundredYearsFromTimeStamp = (timestamp) => {
    const date = new Date(timestamp);
    date.setFullYear(date.getFullYear() + 150);
    return date;
};

export const categoryTabs = [
    {
        key: 'Categories',
        value: 'categories',
    },
    {
        key: 'Manage Categories',
        value: 'manage_categories',
    }
];

export const cannabinoidsTabs = [
    {
        key: 'Home',
        value: 'main_cannabinoid'
    },
    {
        key: 'Cannabinoids',
        value: 'cannabinoids'
    }
];

export const strainsTab = [
    {
        key: 'Home',
        value: 'main_strain'
    },
    {
        key: 'Strains',
        value: 'strains'
    }
];

export const adminsOptions = [
    {
        key: 'Hyperdrive',
        value: HYPERDRIVE
    },
    {
        key: 'Hyperwolf',
        value: HYPERWOLF
    },
    {
        key: 'Hemp',
        value: HEMP
    }
];

export const hyperdriveTaskOptions = [
    // {
    //     key: 'Pickup Task',
    //     value: 'pick_up_task'
    // },
    // {
    //     key: 'Drop Off Task',
    //     value: 'drop_off_task'
    // },
    {
        icon: <StartIcon />,
        key: 'Start Task',
        value: 'start_task'
    },
    {
        icon: <BreakIcon />,
        key: 'Break Task',
        value: 'break_task'
    },

    {

        icon: <ReturnIcon />,
        key: 'Return to HQ  Task',
        value: 'return_to_hq_task'
    },
];

export function convertMinutesToHoursAndMinutes(minutesInput) {
    const absoluteMinutes = Math.abs(minutesInput);
    const hours = Math.floor(absoluteMinutes / 60);
    const minutes = absoluteMinutes % 60;
    let output = '';

    if (hours > 0) {
        output += `${hours} Hours`;
    }
    if (minutes > 0) {
        if (output) {
            output += ' : ';
        }
        output += `${minutes} Minute(s)`;
    }

    return output.trim() ? output : '';
}

export const formatTime = (props) => {
    const hours = props?.hours;
    const minutes = props?.minutes;
    const seconds = props?.seconds;

    let timeParts = [];

    if (hours) {
        timeParts.push(`${hours} hour${hours === 1 ? '' : 's'}`);
    }
    if (minutes) {
        timeParts.push(`${minutes} min${minutes === 1 ? '' : 's'}`);
    }
    if (seconds) {
        timeParts.push(`${seconds} sec${seconds === 1 ? '' : 's'}`);
    }
    return timeParts.length > 0 ? timeParts.join(' ') : '-';
};

export const getDeliveryOptAndInstructions = (memo) => {
    if (!memo) return {};
    const lines = memo.split('\n').filter(line => line.includes(':'));
    const result = {};
    lines.forEach(line => {
        const [key, value] = line.split(':').map(item => item.trim());
        result[key] = value;
    });
    return result;
};

export function separateWords(input) {
    if (input === 'returnToHeadquarterTask') {
        return 'Return to HQ';
    }
    else {
        const separated = input?.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([a-z]+)([A-Z][a-z])/g, '$1 $2')
            .replace(/(?=[A-Z])|\s+/g, ' ');

        return separated ? separated.charAt(0).toUpperCase() + separated.slice(1) : '';
    }
}

export const formatPhoneNumber = (value) => {
    value = value.replace(/\D/g, '');
    // value = value.trim();

    // Check if the number starts with '+1'
    if (value.startsWith('+1')) {
        // return value; 
        return ` (${value.slice(0, 5)}) ${value.slice(5, 8)}-${value.slice(8)}`;
    }

    // Length check
    if (value.length < 10 || value.length > 11) {
        return 'Invalid number';
    }

    let formattedNumber;
    if (value.length === 10) {
        formattedNumber = `+1(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
    } else {
        formattedNumber = `+1(${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7)}`;
    }

    return formattedNumber;
};

export const handleFileDownload = async () => {
    const user = getData('login-user-info');
    const parseUser = JSON.parse(user);

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL_HYPER_DRIVE}/api/v1/admin/analyze/export`, {
            headers: {
                'Content-Type': 'application/octet-stream',
                Authorization: 'Bearer ' + parseUser?.access_token,
            },
            responseType: 'blob',
        });

        return response.data;

    } catch (error) {
        throw error;
    }
};


export const routeMap = [
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.66866868916643',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.65172933266915',
        'timestamp': 1728432787037
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67168070108572',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.65988066879359',
        'timestamp': 1728432818046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67601826561325',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.66968916562556',
        'timestamp': 1728432849052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68369999376327',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.67569419252455',
        'timestamp': 1728432880051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68957355462828',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.68347507483159',
        'timestamp': 1728432910067
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69532022310299',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.69180891256453',
        'timestamp': 1728432941049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.70015368358048',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.70183787413977',
        'timestamp': 1728432972065
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.70443817754148',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.71069436362208',
        'timestamp': 1728433003045
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.70992132571258',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.71849383470034',
        'timestamp': 1728433034044
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.707795316708015',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.72585556086054',
        'timestamp': 1728433065034
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69981393420139',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7332332878362',
        'timestamp': 1728433096053
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69129953970598',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.74068013050146',
        'timestamp': 1728433127055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.683178123149006',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.74754542923304',
        'timestamp': 1728433158049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67706863954747',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.75437093832797',
        'timestamp': 1728433189051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.681722243222936',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76224013641959',
        'timestamp': 1728433220050
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68578408829504',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76536249425943',
        'timestamp': 1728433251052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6881798235707',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76671729405699',
        'timestamp': 1728433282059
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69057980367256',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76705898785417',
        'timestamp': 1728433313049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69056422500595',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7670289776808',
        'timestamp': 1728433344055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68984102199852',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76857290176642',
        'timestamp': 1728433375057
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.685351463420105',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77306969830376',
        'timestamp': 1728433406052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.681762051257486',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77666135348012',
        'timestamp': 1728433437049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67981771080124',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77860115218394',
        'timestamp': 1728433468054
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6797178600826',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77869969782606',
        'timestamp': 1728433499057
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67971770474624',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77869985054984',
        'timestamp': 1728433530046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.679728709379845',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77868903064825',
        'timestamp': 1728433561048
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68276841504799',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78320900363849',
        'timestamp': 1728433592056
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68562778990608',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78731118194501',
        'timestamp': 1728433623053
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68616597644114',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78808410451374',
        'timestamp': 1728433654037
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.686162277358775',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78807876980117',
        'timestamp': 1728433685046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.686154297847985',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78806726217174',
        'timestamp': 1728433716052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68650159575154',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78856590690765',
        'timestamp': 1728433747056
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.690840051170895',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7948186372932',
        'timestamp': 1728433778043
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69302113749564',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79795067902896',
        'timestamp': 1728433809044
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6931381099649',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79811993254386',
        'timestamp': 1728433840051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.693147274920904',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79813321184974',
        'timestamp': 1728433871049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.693140359628984',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7981231921339',
        'timestamp': 1728433902038
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69338825760209',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79848175001383',
        'timestamp': 1728433933055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69033447252668',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80216063203255',
        'timestamp': 1728433964058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.688536194709705',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80396886265817',
        'timestamp': 1728433995049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68853876464563',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80396632202702',
        'timestamp': 1728434025058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.688536841435784',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8039682233037',
        'timestamp': 1728434056055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.685115129606665',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80741211083908',
        'timestamp': 1728434087041
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.683967643190634',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80856103181043',
        'timestamp': 1728434118058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68146949078836',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81224871038101',
        'timestamp': 1728434149055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68209317382873',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8135049604778',
        'timestamp': 1728434180052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68208957287546',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81349490553364',
        'timestamp': 1728434211047
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68046143865204',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81488025770511',
        'timestamp': 1728434242051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6798712137342',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81478411846972',
        'timestamp': 1728434273054
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67987781375229',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81477665209987',
        'timestamp': 1728434304046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.679862883517394',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81478043338632',
        'timestamp': 1728434335045
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67986383713651',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8147696726389',
        'timestamp': 1728434366039
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67990991962565',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8146823024891',
        'timestamp': 1728434397041
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68082709571407',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81446171083003',
        'timestamp': 1728434428045
    },

    {
        'latitude': '33.66082709571335',
        'longitude': '-117.82348892834324'
    },
    {
        'latitude': '33.65578734544545',
        'longitude': '-117.81554334893434'
    },
    {
        'latitude': '33.73495949554988',
        'longitude': '-117.83255433494238'
    },
    {
        'latitude': '33.76393983790899',
        'longitude': '-117.74949383839348'
    },
    {
        'latitude': '33.78985489343499',
        'longitude': '-117.84374723982398'
    },
    {
        'latitude': '33.789943348347384',
        'longitude': '-117.894774588483867'
    },
    {
        'latitude': '33.7489430348884399',
        'longitude': '-117.895873927723728'
    },
    {
        'latitude': '33.74545783848843347',
        'longitude': '-117.8968483483283238'
    },
    {
        'latitude': '33.74633289887833894',
        'longitude': '-117.898454787584858'
    },
    {
        'latitude': '33.75938348982948598',
        'longitude': '-117.899943793478893'
    },
    {
        'latitude': '33.759999878678438758',
        'longitude': '-117.8124845484584588'
    },
    {
        'latitude': '33.763674367637436789',
        'longitude': '-117.8338243849384984'
    },
    {
        'latitude': '33.7458459999445543434',
        'longitude': '-117.854995443434900954'
    },
    {
        'latitude': '33.7498933793390576589',
        'longitude': '-117.895523785485548958'
    },
    {
        'latitude': '33.79769905095659654534',
        'longitude': '-117.9858859939480659934'
    },
    {
        'latitude': '33.787948787783983882388',
        'longitude': '-117.79084383490384398348'
    },
    {
        'latitude': '33.74545784387883483',
        'longitude': '-117.89684578394895845'
    },
    {
        'latitude': '33.74633289854895845',
        'longitude': '-117.89845658975690589'
    },
    {
        'latitude': '33.75938348982934784',
        'longitude': '-117.89994379347883587'
    },
    {
        'latitude': '33.759999875489834438',
        'longitude': '-117.81248454845444467'
    },
    {
        'latitude': '33.764568798379389999',
        'longitude': '-117.83382438445890874'
    },
    {
        'latitude': '33.7823982838298933299',
        'longitude': '-117.803983889899338999'
    },
    {
        'latitude': '33.7988798478948732889',
        'longitude': '-117.8190250989876668542'
    },
    {
        'latitude': '33.7837789838893798074',
        'longitude': '-117.8278921213553422547'
    },
    {
        'latitude': '33.789872367787823788',
        'longitude': '-117.865755784263644563'
    },
    {
        'latitude': '33.743327798957676445',
        'longitude': '-117.8883238384098430982'
    },
    {
        'latitude': '33.7854634677958577828',
        'longitude': '-117.783479433489439900'
    },
    {
        'latitude': '33.7575342978823529878',
        'longitude': '-117.9898834874589948577'
    },
    {
        'latitude': '33.87554756958905733573',
        'longitude': '-117.8678789778626289789'
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67168070108572',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.65988066879359',
        'timestamp': 1728432818046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67601826561325',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.66968916562556',
        'timestamp': 1728432849052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68369999376327',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.67569419252455',
        'timestamp': 1728432880051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68957355462828',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.68347507483159',
        'timestamp': 1728432910067
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69532022310299',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.69180891256453',
        'timestamp': 1728432941049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.70015368358048',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.70183787413977',
        'timestamp': 1728432972065
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.70443817754148',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.71069436362208',
        'timestamp': 1728433003045
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.70992132571258',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.71849383470034',
        'timestamp': 1728433034044
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.707795316708015',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.72585556086054',
        'timestamp': 1728433065034
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69981393420139',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7332332878362',
        'timestamp': 1728433096053
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69129953970598',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.74068013050146',
        'timestamp': 1728433127055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.683178123149006',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.74754542923304',
        'timestamp': 1728433158049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67706863954747',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.75437093832797',
        'timestamp': 1728433189051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.681722243222936',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76224013641959',
        'timestamp': 1728433220050
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68578408829504',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76536249425943',
        'timestamp': 1728433251052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6881798235707',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76671729405699',
        'timestamp': 1728433282059
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69057980367256',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76705898785417',
        'timestamp': 1728433313049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69056422500595',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7670289776808',
        'timestamp': 1728433344055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68984102199852',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.76857290176642',
        'timestamp': 1728433375057
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.685351463420105',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77306969830376',
        'timestamp': 1728433406052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.681762051257486',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77666135348012',
        'timestamp': 1728433437049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67981771080124',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77860115218394',
        'timestamp': 1728433468054
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6797178600826',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77869969782606',
        'timestamp': 1728433499057
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.67971770474624',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77869985054984',
        'timestamp': 1728433530046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.679728709379845',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.77868903064825',
        'timestamp': 1728433561048
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68276841504799',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78320900363849',
        'timestamp': 1728433592056
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68562778990608',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78731118194501',
        'timestamp': 1728433623053
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68616597644114',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78808410451374',
        'timestamp': 1728433654037
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.686162277358775',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78807876980117',
        'timestamp': 1728433685046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.686154297847985',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78806726217174',
        'timestamp': 1728433716052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68650159575154',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78856590690765',
        'timestamp': 1728433747056
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.690840051170895',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7948186372932',
        'timestamp': 1728433778043
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69302113749564',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79795067902896',
        'timestamp': 1728433809044
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6931381099649',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79811993254386',
        'timestamp': 1728433840051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.693147274920904',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79813321184974',
        'timestamp': 1728433871049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.693140359628984',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7981231921339',
        'timestamp': 1728433902038
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69338825760209',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79848175001383',
        'timestamp': 1728433933055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69033447252668',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80216063203255',
        'timestamp': 1728433964058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.688536194709705',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80396886265817',
        'timestamp': 1728433995049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68853876464563',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80396632202702',
        'timestamp': 1728434025058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.688536841435784',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8039682233037',
        'timestamp': 1728434056055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.685115129606665',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80741211083908',
        'timestamp': 1728434087041
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.683967643190634',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80856103181043',
        'timestamp': 1728434118058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68146949078836',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81224871038101',
        'timestamp': 1728434149055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68209317382873',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8135049604778',
        'timestamp': 1728434180052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68208957287546',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81349490553364',
        'timestamp': 1728434211047
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68046143865204',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81488025770511',
        'timestamp': 1728434242051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.686162277358775',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78807876980117',
        'timestamp': 1728433685046
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.686154297847985',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78806726217174',
        'timestamp': 1728433716052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68650159575154',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.78856590690765',
        'timestamp': 1728433747056
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.690840051170895',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7948186372932',
        'timestamp': 1728433778043
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69302113749564',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79795067902896',
        'timestamp': 1728433809044
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.6931381099649',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79811993254386',
        'timestamp': 1728433840051
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.693147274920904',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79813321184974',
        'timestamp': 1728433871049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.693140359628984',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.7981231921339',
        'timestamp': 1728433902038
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69338825760209',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.79848175001383',
        'timestamp': 1728433933055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.69033447252668',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80216063203255',
        'timestamp': 1728433964058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.688536194709705',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80396886265817',
        'timestamp': 1728433995049
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68853876464563',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80396632202702',
        'timestamp': 1728434025058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.688536841435784',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8039682233037',
        'timestamp': 1728434056055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.685115129606665',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80741211083908',
        'timestamp': 1728434087041
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.683967643190634',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.80856103181043',
        'timestamp': 1728434118058
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68146949078836',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81224871038101',
        'timestamp': 1728434149055
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68209317382873',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.8135049604778',
        'timestamp': 1728434180052
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68208957287546',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81349490553364',
        'timestamp': 1728434211047
    },
    {
        'taskId': '6705b77344469702842d2fe1',
        'latitude': '33.68046143865204',
        'fleetId': '67038fe30d723f4c36d27de6',
        'longitude': '-117.81488025770511',
        'timestamp': 1728434242051
    },
];
export function base64ToBlob(base64, contentType) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export function getUniqueByLatLng(data) {
    return data.filter((item, index, self) => {
        const key = `${item.address.latitude},${item.address.longitude}`;
        return index === self.findIndex(el => `${el.address.latitude},${el.address.longitude}` === key);
    });
}

export const mainAnnouncementsDataaa = [
    {
        _id: "65236627329787823972",
        announcement: "test",
        status: 'Active'
    }
]