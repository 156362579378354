import { Box, Card, CardContent, FormGroup, Grid, ListItem, TextField, Typography, Stack, Table, IconButton, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteReason, editRefundReason, fetchRefundStatus, fetchRefundTable, openSnackbar, updateRefundData } from '../../redux/slices/common';
import MDButton from 'components/MDButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import noDataFound from 'assets/images/nothing-found.svg';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { Riple } from 'react-loading-indicators';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';

export default function ManageRefund() {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})

    const { refundList = {}, tableloading: tableloading = false, isLoading = false } = useSelector(state => state.common);
    const [formData, setFormData] = useState({
        "cardRefund": "",
        "walletRefund": ""
    })

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
        }
    };

    useEffect(() => {
        dispatch(fetchRefundTable())
        fetchData()
    }, []);

    const fetchData = () => {
        dispatch(fetchRefundStatus()).then((res) => {
            setFormData({
                "cardRefund": res?.payload?.cardRefund.toString(),
                "walletRefund": res?.payload?.walletRefund.toString()
            })
        }).catch((err) => {
            showResponseMessage(err?.message, 'error');
        });
    }

    const refundDataChangeHandler = (e) => {
        const { name, value } = e.target;

        const regex = /^[0-9]*\.?[0-9]*$/;

        if (regex.test(value)) {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
            setErrors({
                cardRefund: "",
                walletRefund: ""
            });
        }
    };

    const handleSave = () => {
        // Check if either cardRefund or walletRefund is empty or 0   
        if (!formData.cardRefund || formData.cardRefund === '0' || !formData.walletRefund || formData.walletRefund === '0') {
            setErrors({
                cardRefund: formData.cardRefund && formData.cardRefund !== '0' ? "" : "Card refund is required and cannot be zero",
                walletRefund: formData.walletRefund && formData.walletRefund !== '0' ? "" : "Wallet refund is required and cannot be zero"
            });
            return;
        }
        const payload = {
            cardRefund: Number(formData.cardRefund),
            walletRefund: Number(formData.walletRefund)
        };

        dispatch(updateRefundData(payload)).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success');
            fetchData();
        }).catch((err) => {
            showResponseMessage(err?.message, 'error');
        });
    };

    const deleteHandler = (announcementId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteReason(announcementId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            dispatch(fetchRefundTable())
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const handleRefundAdd = (id) => {
        dispatch(openCustomModalDialog({
            modal: 'REFUND_REASON_ADD',
            modalData: id ? id : ""
        }))
    }

    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={6}>
                        <Typography variant='h4' mb={3}>Manage Refund</Typography>
                        <Card style={{ boxShadow: "none" }} className="mb-4">
                            <CardContent sx={{ px: 4, py: 3 }}>
                                <FormGroup >
                                    <Grid container spacing={2.5} mb={3}>
                                        <Grid item xs={12} sm={12} xl={12}>
                                            <Typography variant='h6' fontWeight="semibold" mb={1}>Refund to Card</Typography>
                                            <TextField label='Days' inputProps={{ min: 1 }} fullWidth error={Boolean(errors?.cardRefund)} helperText={errors?.cardRefund} className='form-input' value={formData?.cardRefund || ''} onChange={(e) => refundDataChangeHandler(e)} name='cardRefund' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} xl={12}>
                                            <Typography variant='h6' fontWeight="semibold" mb={1}>Refund to Wallet</Typography>
                                            <TextField label='Days' inputProps={{ min: 1 }} fullWidth error={Boolean(errors?.walletRefund)} helperText={errors?.walletRefund} className='form-input' value={formData?.walletRefund || ''} onChange={(e) => refundDataChangeHandler(e)} name='walletRefund' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} xl={12}>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={2}>
                                                <MDButton
                                                    sx={{ minWidth: '140px' }}
                                                    type='button'
                                                    variant='contained'
                                                    color='primary'
                                                    disabled={isLoading}
                                                    loading={isLoading}
                                                    onClick={handleSave}
                                                >
                                                    Save
                                                </MDButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={2} mt={4}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='h4'>Refund Reason</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
                                    <MDButton
                                        startIcon={<AddRoundedIcon fontSize='small' />}
                                        variant="contained" color="primary" fontSize="small"
                                        sx={{ minWidth: '175px', padding: "0.5rem 1.5rem", minHeight: "2rem", fontSize: "14px" }} onClick={() => handleRefundAdd()}  >
                                        Add Reason
                                    </MDButton>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Card style={{ boxShadow: "none" }}>
                            <CardContent sx={{ px: 4, py: 3 }}>
                                {
                                    tableloading ?
                                        <Box >
                                            <div className='loading_spin'>
                                                <Riple color='#3135cc' size='medium' text='' textColor='' />
                                            </div>
                                        </Box> :
                                        <>
                                            {
                                                // mainAnnouncementsData?.data?.length === 0 ?
                                                refundList?.refundReasons?.length === 0 ?
                                                    <ListItem>
                                                        {
                                                            !tableloading &&
                                                            <Box textAlign='center' className='w-100 no-data-found mt-5'>
                                                                <img style={{ maxWidth: '120px' }} src={noDataFound} alt='No Results Found' />
                                                                <p className='text-12' ><strong>No Results Found</strong></p>
                                                            </Box>
                                                        }
                                                    </ListItem>
                                                    :
                                                    <TableContainer sx={{ maxHeight: 440 }}>
                                                        <Table aria-label="simple table">
                                                            <TableHead sx={{ display: "table-header-group", backgroundColor: '#F1F1F1' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ color: 'grey.600', fontSize: '0.75rem', py: 1 }} width="60%">Refund Reason</TableCell>
                                                                    <TableCell sx={{ color: 'grey.600', fontSize: '0.75rem', py: 1 }}>Status</TableCell>
                                                                    <TableCell sx={{ color: 'grey.600', fontSize: '0.75rem', py: 1 }} align="right">Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    // mainAnnouncementsData?.data?.map((announceData) => {
                                                                    refundList?.refundReasons?.map((announceData) => {
                                                                        return (
                                                                            <TableRow key={announceData?._id}>
                                                                                <TableCell>
                                                                                    <Typography className='text-14'>{announceData?.refundReason}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography className='text-14'
                                                                                    >{announceData?.reasonStatus ? <Box color='#24CA49' display='flex' alignItems='center'>
                                                                                        <ActiveIcon width='18px' height='18px' flex='none' />
                                                                                        <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Active</Typography>
                                                                                    </Box> : <Box color='#FD4438' display='flex' alignItems='center'>
                                                                                        <InActiveIcon width='18px' height='18px' flex='none' />
                                                                                        <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Inactive</Typography>
                                                                                    </Box>}</Typography>
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    <Box display='flex' alignItems='center' justifyContent='end' className='table-action-btn'>
                                                                                        <IconButton color='primary' size='small' variant='primary' onClick={() => handleRefundAdd(announceData)}>
                                                                                            <EditIcon width='16px' height='16px' />
                                                                                        </IconButton>
                                                                                        <IconButton color='error' size='small' variant='primary' sx={{ marginLeft: 1 }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation()
                                                                                                dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: announceData?.reasonId, deleteType: 'Refund Reason' }, modal: 'DELETE_MODAL' }))
                                                                                            }}  >
                                                                                            <DeleteIcon width='16px' height='16px' />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }</>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </CommonLayout>
    );
}