import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material';
import AngleRightIcon from 'common/customIcons/angle_right';
import EditIcon from 'common/customIcons/edit';
import MDButton from 'components/MDButton';
import colors from 'assets/theme/base/colors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from 'common/customIcons/delete';
import { useDispatch } from 'react-redux';
import { deletePromotion, fetchSinglePromotion } from '../../../redux/slices/promotions';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice';
import { openSnackbar } from '../../../redux/slices/common';
import { CSVLink } from 'react-csv';
import { Download } from '@mui/icons-material';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import { parseDate } from 'utilities/common';

export default function PromotionDetails() {
  const { grey } = colors;
  const { promotionId = '' } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isReadMore, setIsReadMore] = useState(false);
  const [copyPromoId, setCopyPromoId] = useState('');
  const { promotionDetail = {} } = useSelector(state => state.promotion);
  useEffect(() => {
    if (promotionId) {
      dispatch(fetchSinglePromotion(promotionId));
    }
  }, [promotionId]);

  const csvPromo = useMemo(() => {
    if (promotionDetail?.promoCodes?.length > 0) {
      let csvData = [
        ['Coupons Code']
      ];
      csvData = [...csvData, ...promotionDetail?.promoCodes?.map(item => [item])];
      return csvData;
    }

    return [];
  }, [promotionDetail?.promoCodes]);

  const deleteHandler = (promotionId) => {
    dispatch(setModalLoader(true));
    dispatch(deletePromotion(promotionId)).unwrap().then((res) => {
      showResponseMessage(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      navigate('/promotions');
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showResponseMessage(err?.message, 'error');
    });
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };
  const copyPromo = (text, textId) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyPromoId(textId);
      setTimeout(() => {
        setCopyPromoId('');
      }, 1000);
    }, () => {
    });
  };

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} mb={3}>
          <Link to='/promotions'>
            <Box display='flex' alignItems='center' color='text.main' lineHeight={1}>
              <AngleRightIcon fontSize='14px' />
              <Typography variant='h6' fontWeight='semibold' ml={.75}>Promotions</Typography>
            </Box>
          </Link>

          <Box display='flex' alignItems='center' gap={1}>
            <MDButton
              type='button'
              variant='outlined'
              color='dark'
              size='small'
              startIcon={<EditIcon width='15px' height='15px' />}
              onClick={() => navigate(`/promotions/${promotionId}`)}
            >
              Edit
            </MDButton>
            <MDButton
              type='button'
              variant='outlined'
              color='error'
              size='small'
              startIcon={<DeleteIcon width='15px' height='15px' />}
              onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: promotionId, deleteType: 'promotion' }, modal: 'DELETE_MODAL' }))}
            >
              Delete
            </MDButton>
          </Box>
        </Stack>
        <Grid container spacing={2.5} mb={4}>
          <Grid item xs={12} lg={7}>
            <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden' }}>
              <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                <Grid container spacing={3.5} mb={1}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Name</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.promoName || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]} mb={1}>Status</Typography>
                    {promotionDetail?.status?.toString() ? <>
                      {
                        promotionDetail?.status ? <Box color='#24CA49' display='flex' alignItems='center'>
                          <ActiveIcon width='16px' height='16px' flex='none' />
                          <Typography variant='body2' fontWeight='medium' ml={.5} textTransform='capitalize'>Active</Typography>
                        </Box> : <Box color='#FD4438' display='flex' alignItems='center'>
                          <InActiveIcon width='16px' height='16px' flex='none' />
                          <Typography variant='body2' fontWeight='medium' ml={.5} textTransform='capitalize'>Inactive</Typography>
                        </Box>
                      }
                    </> : '-'}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Discount Type</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.discountType || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Discount Amount</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.discountAmount ? <>{promotionDetail?.discountType === 'Cash' && '$'}{promotionDetail?.discountAmount}{promotionDetail?.discountType === 'Percentage' && '%'}</> : '-'}</Typography>
                  </Grid>
                  {
                    promotionDetail?.discountType === 'Percentage' && <Grid item xs={12} md={6}>
                      <Typography variant='body2' color={grey[600]}>Discount Upto</Typography>
                      <Typography variant='h6' fontWeight='medium'>${promotionDetail?.cashUpto || '-'}</Typography>
                    </Grid>
                  }
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Promo Code</Typography>
                    <Typography className={`${isReadMore ? '' : 'truncate'} line-1`} variant='h6' fontWeight='medium'>
                      {
                        promotionDetail?.promoCodes?.map(((promo, idx) => {
                          return <Tooltip key={idx} title={copyPromoId === idx ? 'copied' : 'copy'} arrow placement='top'>
                            <span onClick={() => copyPromo(promo, idx)} className='cur-pointer mr-1'>{promo}{promotionDetail?.promoCodes?.length !== idx + 1 && ', '}</span>
                          </Tooltip>;
                        }))
                      }
                    </Typography>
                    <Box display='flex' gap={1.5} alignItems='center' mt={1} lineHeight={1}>
                      {
                        promotionDetail?.promoCodes?.length > 6 && <Typography onClick={() => setIsReadMore(!isReadMore)} variant='body2' fontWeight='medium' color='primary' className='cur-pointer'>
                          View {isReadMore ? 'Less' : 'More'}
                        </Typography>
                      }
                      <Box borderLeft={1} borderColor='#ccc' pl={1.5} >
                        <CSVLink data={csvPromo} filename='promo codes.csv'>
                          <Typography variant='body2' fontWeight='medium' color='primary' className='cur-pointer' display='inline-flex' alignItems='center' gap={0.5}>
                            Download CSV <Download />
                          </Typography>
                        </CSVLink>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Promotion Type</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.promotionType || '-'}</Typography>
                  </Grid>
                  {
                    promotionDetail?.promotionType === 'Cart' && <Grid item xs={12} md={6}>
                      <Typography variant='body2' color={grey[600]}>Auto Apply</Typography>
                      <Typography variant='h6' fontWeight='medium'>{promotionDetail?.autoApply ? 'Yes' : 'No'}</Typography>
                    </Grid>
                  }
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Available</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.availableUnits || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Typography variant='body2' fontWeight='semibold' mb={1} color={grey[600]}>Promo Description</Typography>
                    <Typography variant='h6' fontWeight='regular' lineHeight='1.85'>
                      {promotionDetail?.promoDescription || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden' }}>
              <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                <Grid container alignItems='center' spacing={3.5} mb={1}>
                  <Grid item xs={12} md={8}>
                    <Typography variant='body2' color={grey[600]}>Date Range</Typography>
                    <Box display='flex'>
                      {
                        (!promotionDetail?.startDate && !promotionDetail?.endDate) ? <Typography variant='h6' fontWeight='medium'>Disabled</Typography> : <>
                          <Typography variant='h6' fontWeight='medium'>{promotionDetail?.startDate ? dayjs(promotionDetail?.startDate)?.format('MM/DD/YYYY') : '-'}</Typography>
                          <Typography variant='h6' fontWeight='medium' color={grey[600]} px={2}>to</Typography>
                          <Typography variant='h6' fontWeight='medium'>{promotionDetail?.endDate ? dayjs(promotionDetail?.endDate)?.format('MM/DD/YYYY') : '-'}</Typography>
                        </>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color={grey[600]}>Available on</Typography>
                    <Typography variant='h6' fontWeight='medium'>
                      {
                        ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']?.filter((item => {
                          if (promotionDetail[item]) {
                            return item;
                          }
                        }))?.length > 0 ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']?.filter((item => {
                          if (promotionDetail[item?.toLocaleLowerCase()]) {
                            return item;
                          }
                        }))?.join(', ') : '-'
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color={grey[600]}>Time</Typography>
                    {
                      !promotionDetail?.startTime && !promotionDetail?.startTime && <Typography variant='h6' fontWeight='medium'>Disabled</Typography>
                    }
                    {
                      promotionDetail?.startTime && promotionDetail?.startTime && <Typography variant='h6' fontWeight='medium'>{promotionDetail?.startTime ? dayjs(parseDate(promotionDetail?.startTime))?.format('hh:mm a') : 'N/A'} - {promotionDetail?.endTime ? dayjs(parseDate(promotionDetail?.endTime))?.format('hh:mm a') : 'N/A'}</Typography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color={grey[600]}>Max Availablity</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.maxAvailableCustomer > 0 ? 'Enabled' : ' Disabled'}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color={grey[600]}>Max Per Customer</Typography>
                    <Typography variant='h6' fontWeight='medium'>{promotionDetail?.maxAvailableCustomer > 0 ? promotionDetail?.maxAvailableCustomer : '-'}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {
          (promotionDetail?.promotionType === 'Cart' && promotionDetail?.criteriaGroups?.length === 0) || (promotionDetail?.promotionType === 'Product' && promotionDetail?.target?.length === 0) || !promotionDetail?.promotionType ? null : <>
            <Typography variant='h5' mb={1}>Promotion Rules</Typography>
            <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden', mb: 2 }}>
              <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                {
                  promotionDetail?.promotionType === 'Cart' ? promotionDetail?.criteriaGroups?.map(((criteriaGroup, index) => {
                    return <>
                      {criteriaGroup?.ruleType ? <Grid container alignItems='center' spacing={3.5} mb={1}>
                        <Grid item xs={12} md={4}>
                          <Typography variant='body2' color={grey[600]}>Criteria Group {index + 1}</Typography>
                          <Typography variant='h6' fontWeight='medium'>{criteriaGroup?.ruleType}</Typography>
                        </Grid>
                        {
                          criteriaGroup?.minAmt && <Grid item xs={12} md={4}>
                            <Typography variant='body2' color={grey[600]}>Minimum {criteriaGroup?.ruleType === 'Cart Order Minimum' ? 'Subtotal' : 'Orders'} </Typography>
                            <Typography variant='h6' fontWeight='medium'>{criteriaGroup?.ruleType === 'Cart Order Minimum' ? '$' : ''}{criteriaGroup?.minAmt}</Typography>
                          </Grid>
                        }

                      </Grid>
                        : null}
                    </>;
                  })) : promotionDetail?.promotionType === 'Product' ? promotionDetail?.target?.map((criteriaGroup => {
                    return <>
                      <Grid container alignItems='center' spacing={3.5} mb={1}>
                        <Grid item xs={12} md={4}>
                          <Typography variant='body2' color={grey[600]}>Criteria Group 1</Typography>
                          <Typography variant='h6' fontWeight='medium'>{criteriaGroup?.ruleType}</Typography>
                        </Grid>
                        {
                          criteriaGroup?.productIds?.length > 0 ? <Grid item xs={12} md={4}>
                            <Typography variant='body2' color={grey[600]}>Products</Typography>
                            <Typography variant='h6' fontWeight='medium'>{criteriaGroup?.productIds?.map((productItem => productItem?.productName))?.join(', ')}</Typography>
                          </Grid> : criteriaGroup?.categoryIds?.length > 0 ? <Grid item xs={12} md={4}>
                            <Typography variant='body2' color={grey[600]}>Categories</Typography>
                            <Typography variant='h6' fontWeight='medium'>{criteriaGroup?.categoryIds?.map((categoryItem => categoryItem?.categoryName))?.join(', ')}</Typography>
                          </Grid> : criteriaGroup?.brandIds?.length > 0 ? <Grid item xs={12} md={4}>
                            <Typography variant='body2' color={grey[600]}>Brands</Typography>
                            <Typography variant='h6' fontWeight='medium'>{criteriaGroup?.brandIds?.map((brandItem => brandItem?.brandName))?.join(', ')}</Typography>
                          </Grid> : null
                        }
                      </Grid>
                    </>;
                  })) : null
                }
              </CardContent>
            </Card>
          </>
        }
      </Box>
    </CommonLayout >
  );
}