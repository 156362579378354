import { CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Grid, Typography, InputAdornment, Switch } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { fetchRefundList, fetchRefundStatus } from '../../../redux/slices/common';
import { useDispatch } from 'react-redux';

export default function DeliveryOrderReason({ closeModal }) {
    const dispatch = useDispatch()
    const { modalData } = useSelector(state => state.modal);
    const { isLoading: submittingOrder = false } = useSelector(state => state.orders);

    const [refundListData, setRefundListData] = useState([]);
    const [errors, setErrors] = useState({});
    const { refundTotal, cancelOrder, cancelType = '', orderDate, orderTotal, isShipped, shippingCharges } = modalData || {};
    const [formData, setFormData] = useState({ refundType: '', refundValue: "", cancellationReason: "", amountType: "fullAmount", cancellationReasonOther: "", isInclude: false });
    const [refundValid, setRefundValid] = useState({
        card: false,
        wallet: false
    })
    const validateAnnouncement = () => {
        let isError = false
        let errors = {}
        if (!formData?.refundType) {
            isError = true
            errors = { ...errors, refundType: 'Refund Type is required' }
        }
        if (formData?.amountType !== 'fullAmount') {
            if (!formData?.refundValue) {
                isError = true
                errors = { ...errors, refundValue: 'Refund Amount is required' }
            }
        }
        if (formData?.cancellationReason === 'Other Reason') {
            if (!formData?.cancellationReasonOther) {
                isError = true
                errors = { ...errors, cancellationReasonOther: 'Other Cancel Reason is required' }
            }
        }
        if (!formData?.cancellationReason) {
            isError = true
            errors = { ...errors, cancellationReason: 'Cancel Reason is required' }
        }
        // if (formData?.refundValue > refundTotal) {
        //     isError = true
        //     errors = { ...errors, refundValue: `Custom amount can't be greater than the total amount` }
        // }

        return { errors, isError }
    }
    const submitHandler = () => {
        const { isError, errors } = validateAnnouncement()
        setErrors(errors)
        if (isError) return;
        setErrors({})

        const payload = {
            cancellationReason: formData?.cancellationReason === 'Other Reason' ? formData?.cancellationReasonOther : formData?.cancellationReason
        }
        if (formData?.refundType !== 'norefund') {
            payload.refundType = formData?.refundType;
            payload.refundValue = formData?.amountType === 'fullAmount' ? refundTotal
                : (formData?.isInclude === true ? (Number(formData?.refundValue) + shippingCharges) : formData?.refundValue);
        }
        cancelOrder(payload, cancelType);
    };


    const startTaskHandler = (e, type) => {
        const { name, value, checked } = e.target;
        if (type === 'refundValue') {
            if (value > refundTotal) {
                setErrors({
                    ...errors,
                    refundValue: `Custom amount can't be greater than the total amount`
                })
                return false
            }
        }

        if (type === 'toggle') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
        setErrors({
            ...errors,
            [name]: ""
        });
    };

    useEffect(() => {
        dispatch(fetchRefundList()).unwrap().then((res) => {
            setRefundListData(res?.refundReasons)
        });
        dispatch(fetchRefundStatus()).then((res) => {
            const date = orderDate
            // Get the current date (in milliseconds)
            const currentDate = Date.now(); // This gives the current timestamp
            // Calculate the time difference between the current date and the given date
            const timeDifference = currentDate - date;

            const checkRefundWithinDays = (days) => {
                const daysInMilliseconds = days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
                return timeDifference <= daysInMilliseconds;
            };
            const stateCardForDay = checkRefundWithinDays(res?.payload?.cardRefund);
            const stateWalletForDay = checkRefundWithinDays(res?.payload?.walletRefund);
            setRefundValid({
                card: stateCardForDay,
                wallet: stateWalletForDay
            })
        })
    }, [])

    const amount = Number(formData?.refundValue) + shippingCharges;

    useEffect(() => {
        if (formData.amountType === 'fullAmount') {
            setFormData({
                ...formData,
                refundValue: "",
                isInclude: false
            })
        }
    }, [formData])

    return (
        <div className="refund-process-inner">
            <DialogTitle id='draggable-dialog-title' display='flex' alignItems='center' justifyContent='space-between'>
                {cancelType === 'delivery_failed' ? 'Delivery Failed Process' : cancelType === 'delivery_claimed_filed' ? 'Delivery Claimed Filed Process' : 'Cancellation Process'}
                <IconButton onClick={closeModal} edge='end' size='small'>
                    <CloseIcon fontSize='medium' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} xl={12} className="select-refound-label">
                        <FormControl fullWidth>
                            <InputLabel>Select Method</InputLabel>
                            <Select
                                size='large'
                                value={formData?.refundType} onChange={(e) => { startTaskHandler(e) }} fullWidth
                                className='form-select'
                                name='refundType'
                                label='Product Status'
                                MenuProps={{ className: 'refund-popover' }}
                            >
                                <MenuItem disabled={(!refundValid?.card || orderTotal === 0) ? true : false} value='originalMethod'>Refund to Card</MenuItem>
                                <MenuItem disabled={!refundValid?.wallet ? true : false} value='wallet'>Refund to Wallet</MenuItem>
                                <MenuItem value='norefund'>No Refund</MenuItem>
                            </Select>
                            <p className="error">
                                {errors?.refundType}
                            </p>
                        </FormControl>
                    </Grid>
                    {
                        formData?.refundType !== 'norefund' &&
                        <Grid item xs={12} sm={12} xl={12}>
                            <FormControl fullWidth>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='amountType'
                                    value={formData?.amountType}
                                    onChange={(e) => startTaskHandler(e)}
                                    className='modal-radio text-black-600'
                                >
                                    <FormControlLabel value='fullAmount' className='text-black-600' control={<Radio />} label='Full Amount' />
                                    <FormControlLabel value='customAmount' className='text-black-600' control={<Radio />} label='Custom Amount' />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    {
                        formData?.refundType !== 'norefund' &&
                        <>
                            {
                                formData?.amountType === 'customAmount' ?
                                    <Grid item xs={12} sm={12} xl={12}>
                                        <FormControl fullWidth>
                                            {/* <TextField
                                                fullWidth
                                                error={Boolean(errors?.refundValue)}
                                                helperText={errors?.refundValue}
                                                className='form-input'
                                                value={formData?.refundValue || ''}
                                                onChange={(e) => startTaskHandler(e)}
                                          
                                                label='Amount'
                                                name='refundValue'
                                                type='number'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,  // Adding the "Days" after the number input
                                                }}
                                            /> */}
                                            <TextField
                                                fullWidth
                                                error={Boolean(errors?.refundValue)}
                                                helperText={errors?.refundValue}
                                                className='form-input'
                                                value={formData?.refundValue || ''}
                                                onChange={(e) => {
                                                    // Ensure only numeric input
                                                    const value = e.target.value;
                                                    if (/^\d*\.?\d*$/.test(value)) {  // Regex to allow digits and decimals
                                                        startTaskHandler(e, "refundValue");
                                                    }
                                                }}
                                                label='Amount'
                                                name='refundValue'
                                                type='text'  // Use text so you can apply the regex validation
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,  // Adding the "$" symbol
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric',  // This will help mobile keyboards show only numeric input
                                                    pattern: '[0-9]*',  // This ensures the input is only numbers in case of mobile
                                                }}
                                            />
                                            {
                                                isShipped !== 'awaiting_shipment' &&
                                                <>
                                                    <div className='d-flex align-item-center jc-s-btwn customToggle'>
                                                        <Typography variant='h6' fontWeight="semibold" >Shipping :</Typography>
                                                        <Typography variant='inherit' fontWeight="semibold" > {`$${shippingCharges}`}</Typography>
                                                    </div>
                                                    <div className='d-flex align-item-center jc-s-btwn customToggle' >
                                                        <div></div>
                                                        <div className='d-flex align-item-center jc-s-btwn customToggle'>
                                                            <Typography style={{ color: "#656575" }} variant='h6' className='my-0 fs-small' fontWeight="semibold" mb={1}>Exclude</Typography>&nbsp;&nbsp;&nbsp;
                                                            <FormControlLabel control={<Switch onChange={(e) => startTaskHandler(e, "toggle")} name='isInclude' />} />
                                                            <Typography variant='h6' style={{ color: "#656575" }} className='my-0 fs-small' fontWeight="semibold" mb={1} >Include</Typography>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: "1rem", border: '1px solid #adb5bd', borderRadius: '10px', padding: "0.5rem" }}>
                                                        <div className='d-flex align-item-center jc-s-btwn customToggle'>
                                                            <Typography variant='h6' fontWeight="semibold" >Total :</Typography>
                                                            <Typography variant='h6' fontWeight="semibold" >{(formData?.isInclude === true ? `$${amount.toFixed(2)}` : `$${formData?.refundValue ? formData?.refundValue : 0}`)}</Typography>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </FormControl>
                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={12} xl={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                className='form-input'
                                                value={refundTotal} onChange={(e) => startTaskHandler(e)}
                                                label='Amount'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                name='refundValue'
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                            }
                        </>
                    }

                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant='h6' fontWeight="semibold" mb={1}>Add Reason</Typography>
                        <FormControl fullWidth>
                            <InputLabel>Add Reason</InputLabel>
                            <Select
                                size='large'
                                fullWidth
                                className='form-select'
                                name='cancellationReason'
                                sx={{
                                    '& .MuiSelect-select': {
                                        paddingRight: "2rem !important",
                                        width: "calc(100% - 90px)"
                                    }
                                }}
                                value={formData?.cancellationReason} onChange={(e) => { startTaskHandler(e) }}
                                label='Add Reason'
                                MenuProps={{ className: 'refund-popover' }}
                            >
                                {
                                    refundListData?.map((data, idx) => {
                                        return (
                                            <MenuItem key={idx} value={data?.refundReason}>{data?.refundReason}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <p className="error">
                                {errors?.cancellationReason}
                            </p>
                        </FormControl>
                    </Grid>
                    {
                        formData?.cancellationReason === 'Other Reason' &&
                        <Grid item xs={12} sm={12} xl={12}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    error={Boolean(errors?.cancellationReasonOther)}
                                    helperText={errors?.cancellationReasonOther}
                                    className='form-input'
                                    label='Add other Reason'
                                    name='cancellationReasonOther'
                                    onChange={(e) => { startTaskHandler(e) }}
                                    multiline
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton
                    onClick={submitHandler}
                    size='large'
                    color='primary'
                    disabled={submittingOrder}
                    sx={{ minWidth: '150px', padding: '0.25rem 1.5rem' }}
                >
                    {submittingOrder ? <CircularProgress size={16} color='light' /> : 'Save'}
                </MDButton>
                <MDButton
                    onClick={closeModal}
                    size='large'
                    variant='outlined'
                    color='dark'
                    sx={{ minWidth: '150px', padding: '0.25rem 1.5rem' }}
                >
                    Cancel
                </MDButton>
            </DialogActions>
        </div>
    );
}