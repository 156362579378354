import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import MDButton from 'components/MDButton';
import { useLocation, useNavigate } from 'react-router-dom';

function hasAnyValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (
      (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
      (typeof obj[key] !== 'object' && obj[key] !== '')
    )
    ) {
      return true;
    }
  }
  return false;
}

export default function FilterRetailers(props = {}) {

  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ activeRetailers: '', subscription: '' });
  const { applyRetailerFilter } = props;

  const clearAllHandler = () => {
    setFormData({ activeRetailers: '', subscription: '' });
    navigate('/retailers');
    applyRetailerFilter({});
  };

  const showApplyButton = useMemo(() => {
    return hasAnyValue(formData);
  }, [formData]);

  const applyFiltersHandler = () => {
    const { activeRetailers, subscription } = formData || {};
    let queryPayload = {
      status: activeRetailers,
      subscription: subscription
    };
    if (queryPayload.status === 'all') {
      queryPayload = { ...queryPayload, status: '' };
    }
    if (queryPayload.subscription === 'all') {
      queryPayload = { ...queryPayload, subscription: '' };
    }
    let url = `${location.pathname}?`;
    Object.keys(queryPayload).forEach((key => {
      if (typeof queryPayload[key] === 'object') {
        url = url + `&${key}=${JSON.stringify(queryPayload[key])}`;
      } else {
        url = url + `&${key}=${queryPayload[key]}`;
      }
    }));
    const params = new URLSearchParams(url);
    const urlWithParams = `${location.pathname}?${params}`;
    navigate(urlWithParams);
    applyRetailerFilter({ queryPayload });
  };

  return (
    <Box className='cus-filter-sec' mb={1}>
      <Grid className='filter-box' container alignItems='center' spacing={1.5}>
        <Grid item xs={12} sm={12} md={12} xxl={12}>
          <Box className='filter-price-unit'>

            <Stack direction='row' justifyContent='flex-end' width='100%' gap={2}  >
              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className='filter-pro-status'>
                  <FormControl fullWidth>
                    <InputLabel>Retailer Status</InputLabel>
                    <Select size='small' value={formData?.activeRetailers} onChange={(e) => setFormData({ ...formData, activeRetailers: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='active'>Active</MenuItem>
                      <MenuItem value='inactive'>Inactive</MenuItem>
                      <MenuItem value='pending'>Pending</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className='filter-pro-status'>
                  <FormControl fullWidth>
                    <InputLabel>Subscription</InputLabel>
                    <Select size='small' value={formData?.subscription} onChange={(e) => setFormData({ ...formData, subscription: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='Paid'>Paid</MenuItem>
                      <MenuItem value='Unpaid'>Unpaid</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4} xxl={1.5}>
                <Box className='filter-btns'>
                  <Stack direction='row' alignItems='center' gap={1} className='w-100'>
                    <Button className='filter-clear-all' onClick={clearAllHandler} size='small' variant='text' color='secondary' style={{ width: '100%' }}>
                      Clear All
                    </Button>
                    <MDButton disabled={!showApplyButton} className='filter-apply' onClick={applyFiltersHandler} size='small' variant='contained' color='primary' style={{ width: '100%' }}>
                      Apply
                    </MDButton>
                  </Stack>
                </Box>
              </Grid>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}