import { isEmpty } from 'lodash';

export function validateEmployeeData(data = {}) {
    let errors = {};
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!data.name) {
        errors.name = 'Name is required!';
    }

    if (!data.email) {
        errors.email = 'Email is required!';
    }

    if (data.email && !data.email.match(regexEmail)) {
        errors.email = 'Invalid Email';
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}