import { CloseRounded } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatTimestampToDate } from 'utilities/common';
import MDBox from 'components/MDBox';
import { useDebounce } from 'hooks/useDebounce';
import defaultProductImage from '../../../assets/images/placeholderproduct.svg';
import { capitalizeText } from 'utilities/common';
import SearchIcon from 'common/customIcons/search';
import { getAllProducts, syncProductAdmin } from '../../../redux/slices/hyperwolf/products';
import { withStyles } from '@mui/styles';
import { getLastSyncDate } from '../../../redux/slices/hyperwolf/auth';
import { setData } from 'utilities/common';
import { getData } from 'utilities/common';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function HyperwolfProducts() {
  const dispatch = useDispatch();
  const { loading = false, products = {}, syncProductLoader = false } = useSelector(state => state.hyperwolfProducts);
  const { lastSyncDate } = useSelector(state => state.hyperwolfAuth);
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const productFiltersPayload = useRef({});
  const [waitingTime, setWaitingTime] = useState('');
  const [isActiveTab, setIsActiveTab] = useState(true);

  let waitingTimeInterval = useRef(null);

  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  const onSyncBlazeProducts = () => {
    if (!syncProductLoader && !waitingTime) {
      dispatch(syncProductAdmin()).then(() => {
        setData('adminLastSync', Date.now());
        setWaitingTime('Wait for 2 min');
        runWaitingTimeInterval();
      });
    }
  };

  const runWaitingTimeInterval = () => {
    if (getData('adminLastSync')) {
      const value = JSON.parse(getData('adminLastSync'));
      waitingTimeInterval.current = setInterval(() => {
        const diffInMin = Math.floor(((Date.now() - value) / 1000 / 60) << 0);
        if (diffInMin > 2) {
          setWaitingTime('');
          clearInterval(waitingTimeInterval.current);
        }
        let waitingMsg = '';
        if (diffInMin === 0 || diffInMin === 1) waitingMsg = 'Wait for 2 min';
        if (diffInMin === 2) waitingMsg = 'Wait for 1 min';
        setWaitingTime(waitingMsg);
      }, 2000);
    }
  };

  const columnDefs= [
    {
      headerName: 'Product Name/SKU',
      field: 'Product Name/SKU',
      suppressMenu: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (props) => {
        return <Tooltip title={capitalizeText(props?.data?.name)} arrow placement="top">
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
            <Avatar
              alt="Admin"
              src={props?.data?.assets?.[0]?.mediumURL || defaultProductImage}
              sx={{ width: 32, height: 32 }}
            />
            <MDBox textAlign="left" lineHeight={1.75} ml={1.25} mt={.125}>
              <Typography variant="h6" color="dark" lineHeight={1.25} fontWeight="semibold" className="text-14 truncate line-1">{capitalizeText(props?.data?.name)}</Typography>
              <Typography className='text-12' variant="p" color="secondary" lineHeight={1.25}>{props?.data?.sku}</Typography>
            </MDBox>
          </Box>
        </Tooltip>;
      },
      minWidth: 280
    },
    // {
    //   headerName: 'Website Product Name/SKU',
    //   field: 'Website Product Name/SKU',
    //   suppressMenu: true,
    //   wrapText: true,
    //   autoHeight: true,
    //   cellRenderer: (props) => {
    //     return <Tooltip title={capitalizeText(props?.data?.websiteProductName)} arrow placement="top">
    //       <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
    //         <Avatar
    //           alt="Admin"
    //           src={props?.data?.assets?.[0]?.mediumURL || defaultProductImage}
    //           sx={{ width: 32, height: 32 }}
    //         />
    //         <MDBox textAlign="left" lineHeight={1.75} ml={1.25} mt={.125}>
    //           <Typography variant="h6" color="dark" lineHeight={1.25} fontWeight="semibold" className="text-14 truncate line-1">{capitalizeText(props?.data?.websiteProductName)}</Typography>
    //           <Typography className="text-12" variant="p" color="secondary" lineHeight={1.25}>{props?.data?.sku}</Typography>
    //         </MDBox>
    //       </Box>
    //     </Tooltip>;
    //   },
    //   minWidth: 280
    // },
    {
      headerName: 'Brand',
      field: 'brandName',
      suppressMenu: false,
      minWidth: 140,
      cellRenderer: (props) => {
        return props?.data?.brand?.name;
      },
    },
    {
      headerName: 'Strain Type',
      field: 'strainType',
      suppressMenu: false,
      minWidth: 140,
      cellRenderer: (props) => {
        return props?.data?.category?.name;
      },
    },
    {
      headerName: 'Category',
      field: 'categoryName',
      key: 'fullName',
      suppressMenu: false,
      minWidth: 180,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.5
      },
      cellRenderer: (props) => {
        return props?.data?.category?.name;
      },
    },
    {
      headerName: 'Sub Category',
      field: 'bmwCategoryName',
      key: 'subcategory',
      suppressMenu: false,
      minWidth: 180,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.5
      },
    },
    {
      headerName: 'Type',
      field: 'strainType',
      key: 'strainType',
      suppressMenu: false,
      minWidth: 180,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.5
      },
    },
    {
      headerName: 'Price',
      field: 'unitPrice',
      suppressMenu: true,
      cellRenderer: (props) => {
        if (!props?.value?.toString()) return null;
        if (props?.value?.toString()) {
          return <Typography variant='body2' fontWeight="medium" lineHeight={1}>${props?.value || '0'}</Typography>;
        }
      },
      minWidth: 120
    },
    {
      headerName: 'Actions',
      field: 'unitPrice',
      suppressMenu: true,
      cellRenderer: (props) => {
        if (!props?.value?.toString()) return null;
        return <Box>
          <IconButton color='primary' variant="primary">
            <Link style={{ minWidth: '50px', color: '#0163FF' }} className='w-100 text-12' target='_blank' to={`${process.env.REACT_APP_PUBLIC_HYPERWOLF_REDIRECT_URL}/shop/product/${props?.data?.productSlug}`}>View</Link>
          </IconButton>
        </Box>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120
    },
  ];

  const getRows = useCallback(async (params) => {
    gridRefApi.current = params;
    const sortModel = params?.sortModel;
    let sortedColumnName = '';
    let sortDirection = '';
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high';
    }
    dispatch(getAllProducts({ skip: pageRef.current, limit: 1000, userType: 'admin', searchTerm: searchTermRef.current, [sortedColumnName]: sortDirection, status: '', ...productFiltersPayload?.current })).unwrap().then(res => {
      const { values, total } = res;
      const productsArray = values?.map((item => {
        return { ...item, id: item._id, createdDate: formatTimestampToDate(item?.created) };
      }));
      params.successCallback(productsArray, total);
    });
  }, [productFiltersPayload.current]);

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch(searchValue);
  };

  const onChangeSelectedTab = (status) => {
    setIsActiveTab(status);
    productFiltersPayload.current = { active:status };
    const dataSource = {
      getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };
    
  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (products?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [products, loading]);

  useEffect(() => {
    dispatch(getLastSyncDate());
  }, []);

  let dt = (lastSyncDate && new Date(lastSyncDate)) || '';
  let la = dt.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={1}>
          <Grid item xs={12} >
            <div className="last-sync">
              <div>
                                Last Sync : {la || 'Syncing Now'}
              </div>
              <LightTooltip TransitionComponent={Zoom} title={`${syncProductLoader ? 'Syncing is in Progress' : waitingTime ? waitingTime + ' for next sync' : 'Sync products from blaze'}`}>
                <div className={`product-sync-div ${waitingTime ? 'disabled-text' : ''}`} onClick={onSyncBlazeProducts}>
                  {syncProductLoader ? <CircularProgress size={18} /> : waitingTime ? waitingTime : 'Sync Products'}
                </div>
              </LightTooltip>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }} >
            <Grid container>
              <Grid item xs={12}>
                <div className="tabs-section-div">
                  <div className={isActiveTab ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab(true)}>
                    <span>Active</span>
                  </div>
                  <div className={!isActiveTab ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab(false)}>
                    <span>In-Active</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>Products</Typography>
          </Grid>

          <Grid item xs={12} sm={10}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <TextField className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <IconButton size='small'>
                      <SearchIcon width='18px' height='18px' />
                    </IconButton>
                  </InputAdornment>,
                  endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                    <IconButton onClick={() => searchHandler('')} size='small'>
                      <CloseRounded />
                    </IconButton>
                  </InputAdornment> : null
                }}
              />
            </Stack>
          </Grid>

        </Grid>
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={loading}
          style={{ height: '56vh' }}
          gridOptions={{
            rowHeight: 64,
          }}
          maxLimit={2000}
        />
      </Box>

    </CommonLayout>
  );

}