import { Autocomplete, Avatar, Box, Card, Chip, CircularProgress, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MDButton from 'components/MDButton';
import ActiveIcon from 'common/customIcons/active';
import { useDispatch, useSelector } from 'react-redux';
import { getPercentage } from 'utilities/common';
import { fetchProducts } from '../../redux/slices/products';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { createOrder, fetchSingleOrder } from '../../redux/slices/orders';
import { formatTimestampToDateView } from 'utilities/common';
import { validateOrderData } from 'validations/order';
import { openSnackbar } from '../../redux/slices/common';
import { isEmptyObject } from 'utilities/common';
import DeleteIcon from '../../common/customIcons/delete';
import PlusIcon from 'common/customIcons/plus';
import { orderDetailStatus } from 'utilities/common';
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion';
import { capitalizeText } from 'utilities/common';
import { formatUsPhoneNumber } from 'utilities/common';
import { closeCustomModalDialog, openCustomModalDialog } from '../../redux/slices/modalSlice';
import defaultProductImage from '../../assets/images/placeholderproduct.svg';
import { addCommasWithToFixed } from 'utilities/common';
import OrderProof from './OrderProof';
import OrderLogs from './OrderLogs';
import { getData } from 'utilities/common';

export default function AddOrderForm() {
  const { order = {} } = useSelector(state => state.orders);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading = false, products = {} } = useSelector(state => state.products);
  const { isLoading = false } = useSelector(state => state.orders);
  const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const { user = {} } = useSelector(state => state.auth);
  const userDataStore = JSON.parse(getData('login-user-info'))
  const inititalCountRef = useRef({})
  const [showDisplayCount, setShowDisplayCount] = useState({})
  const { filteredProducts = [] } = products || {};
  const { items, userData, orderId, memo = '', memberId = '', emailStatus = 'awaiting_shipment', productPromo = {} } = order;
  const [errors, setErrors] = useState([]);
  const addressError = useRef(false);

  const [orderData, setOrderData] = useState({
    memo: '',
    email: '',
    product: {
      productId: '',
      quantity: ''
    },
    products: [
      {
        productId: '',
        quantity: ''
      }
    ],
    shippingType: ''
  })
  const [cartInfo, setCartInfo] = useState({})
  const [searchParams] = useSearchParams();
  let orderDetailsTabFilter = searchParams.get('orderDetailsTab')

  const productDataChangeHandler = (event, orderIdx = 0, product = {}, ord) => {
    // Check if initial count has been set already
    if (!inititalCountRef.current[product.productId] && inititalCountRef.current[product.productId] !== 0) {
      inititalCountRef.current[product.productId] = ord?.quantity || 0; // Set the initial count when the handler runs
    }
    const inititalCount = inititalCountRef.current[product.productId];
    const totalProductCount = inititalCount + product.totalQuantity
    let displayValue = 0;
    if (Number(event.target.value) > totalProductCount) return;
    if (event.target.value && event.target.name === 'quantity' && !/^[0-9]*$/.test(event.target.value)) return;
    displayValue = Math.max(0, totalProductCount - Number(event.target.value));
    setShowDisplayCount((prev) => ({
      ...prev,
      [product.productId]: displayValue
    }));
    let order = [...orderData?.products]?.find(((order, idx) => orderIdx === idx))
    const orderCurrentQty = items?.find((orderItem => orderItem?.product?.productId === order?.productId))
    if (order) {
      if (event?.target.value > (product.totalQuantity + (orderCurrentQty?.quantity || 0))) return;
      const orderIdx = orderData?.products?.indexOf(order);
      order = {
        ...order,
        [event.target.name]: event.target.name === 'quantity' ? event.target.value ? +event.target.value : 0 : event.target.value,

      }
      let updatedOrderData = [...orderData?.products];
      updatedOrderData[orderIdx] = order;
      updatedOrderData = updatedOrderData.map(item => {
        if (!item.productId)
          return { ...item, quantity: '' }

        return item;
      })
      let qtyClearForId = event.target.name === "productId" && event.target.value
      if (qtyClearForId && (orderData.products.find(item => item.productId === qtyClearForId)) === undefined) {
        updatedOrderData[orderIdx] = {
          ...updatedOrderData[orderIdx], // Preserve other properties of the product
          quantity: '' // Reset the quantity to empty string
        };
      }
      setOrderData({ ...orderData, products: updatedOrderData });
    }
  }

  const addMoreProduct = () => {
    setOrderData({
      ...orderData, products: [{
        productId: '',
        quantity: ''
      }, ...orderData?.products]
    });
  };


  const cancelOrder = useCallback((reason, reasonStatus = 'cancelled') => {
    const payload = {
      email: orderData?.email,
      updatedBy: user?.name ? user?.name : userDataStore?.name,
      memo: orderData?.memo,
      'inventoryId': 'odkEgmqfW3MDJJedc3QJ',
      items: orderData?.products,
      emailStatus: reasonStatus,
      deliveryAddress: {
        address: orderData?.address,
        state: orderData?.state,
        city: orderData?.city,
        zipcode: orderData?.zipcode,
        lat: orderData?.lat,
        long: orderData?.long,
        apartment: orderData?.apartment
      },
      cancellationReason: (reasonStatus === 'cancelled' || reasonStatus === 'delivery_failed' || reasonStatus === 'delivery_claimed_filed') ? reason?.cancellationReason : "",
      // nonReturnableReason: reasonStatus === 'not_returnable' ? reason : "",
      shippingType: orderData?.shippingType,
      // refundType:reason?.refundType,
      // refundValue:Number(reason?.refundValue),
    };
    if (reason?.refundValue) {
      payload.refundValue = Number(reason?.refundValue)
    }
    if (reason?.refundType) {
      payload.refundType = reason?.refundType
    }
    // if (reason?.isShippingIncluded) {
    //   payload.isShippingIncluded = reason?.isShippingIncluded
    // }
    dispatch(createOrder({ data: payload, orderId: orderId || '' })).unwrap().then((res => {
      if (res?.orderData?.orderId) {
        showError(res?.message, 'success');
        // dispatch(fetchSingleOrder(res?.orderData?.orderId));
        dispatch(closeCustomModalDialog());
        navigate(`/orders/details/${orderId}?orderDetailsTab=orderDetails`);
      }
    })).catch((err => {
      showError(err?.message, 'error');
    }));
  }, [orderData, orderId]);

  // const cancelOrderDialogHandler = (newOrderorderStatus = '') => {
  //   dispatch(openCustomModalDialog({ modal: 'CANCEL_ORDER_REASON', modalData: { cancelOrder, cancelType: newOrderorderStatus } }));
  // };

  const removeProductHandler = (productIdx) => {
    setOrderData({
      ...orderData, products: [...orderData?.products?.filter(((product, _productIdx) => _productIdx !== productIdx))]
    });
  };

  const createOrderHandler = () => {
    const { isValid, fieldError = {}, orderError } = validateOrderData(orderData);
    if (addressError.current) {
      return;
    }
    if (!isValid) {
      setErrors({ errors: orderError, fieldError });
      return;
    }
    setErrors([]);
    addressError.current = false;
    const payload = {
      email: orderData?.email,
      updatedBy: user?.name,
      memo: orderData?.memo,
      'inventoryId': 'odkEgmqfW3MDJJedc3QJ',
      items: orderData?.products,
      emailStatus,
      shippingType: orderData?.shippingType,
      deliveryAddress: {
        address: orderData?.address,
        state: orderData?.state,
        city: orderData?.city,
        zipcode: orderData?.zipcode,
        lat: orderData?.lat,
        long: orderData?.long,
        apartment: orderData?.apartment
      },
      ...cartInfo,
      shippingType: cartInfo?.shippingType === 'free' ? '' : cartInfo?.shippingType
    };
    dispatch(createOrder({ data: payload, orderId: orderId || '' })).unwrap().then((res => {
      if (res?.orderData?.orderId) {
        showError(res?.message, 'success');
        dispatch(fetchSingleOrder(res?.orderData?.orderId));
        navigate(`/orders/details/${orderId}?orderDetailsTab=orderDetails`);
      }
    })).catch((err => {
      showError(err?.message, 'error');
    }));
  };

  const orderStatusChangeHandler = (e) => {
    const payload = {
      email: orderData?.email,
      updatedBy: user?.name,
      memo: orderData?.memo,
      'inventoryId': 'odkEgmqfW3MDJJedc3QJ',
      items: orderData?.products,
      emailStatus: e.target.value,
      deliveryAddress: {
        address: orderData?.address,
        state: orderData?.state,
        city: orderData?.city,
        zipcode: orderData?.zipcode,
        lat: orderData?.lat,
        long: orderData?.long,
        apartment: orderData?.apartment
      },
      shippingType: orderData?.shippingType,
    };
    dispatch(createOrder({ data: payload, orderId: orderId || '' })).unwrap().then((res => {
      if (res?.orderData?.orderId) {
        showError(res?.message, 'success');
        dispatch(fetchSingleOrder(res?.orderData?.orderId));
        dispatch(closeCustomModalDialog());
        navigate(`/orders/details/${orderId}?orderDetailsTab=orderDetails`);
      }
    })).catch((err => {
      showError(err?.message, 'error');
    }));
  };
  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const googleAddressErrorHandler = (status = false) => {
    addressError.current = status;
    if (status) {
      setErrors({ ...errors, fieldError: { ...errors?.fieldError, address: 'Address is required' } });
    }
  };

  useEffect(() => {
    dispatch(fetchProducts({ filters: { skip: 0, limit: 1000, userType: 'admin' } }))
  }, [])

  useEffect(() => {
    window.initMap = () => setGmapsLoaded(true)
    const gmapScriptEl = document.createElement(`script`)
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places&callback=initMap`
    document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
  }, [])

  const cartDetails = useMemo(() => {
    const subTotal = orderData?.products?.reduce(((acc, product) => {
      let singleProduct = filteredProducts?.find((_product => _product.productId === product?.productId));

      if (singleProduct) {
        if (singleProduct?.productId === productPromo?.productId) {
          return acc += (singleProduct?.salePrice * +product?.quantity) - (productPromo?.price || +singleProduct?.salePrice);
        }
        return acc += +(singleProduct?.isSalePrice ? singleProduct?.salePrice : singleProduct?.unitPrice) * +product?.quantity;
      }
      return acc;
    }), 0);

    const shippingType = (orderData?.products?.length > order?.items?.length) || !orderId ? subTotal < 50 || (subTotal >= 50 && subTotal < 100) ? 'standard' : 'expedited' : order?.shippingType

    const shippingCharges = (orderData?.products?.length > order?.items?.length) || !orderId ? subTotal < 50 ? 6.99 : subTotal >= 100 ? 0 : shippingType === 'standard' && subTotal < 50 ? 6.99 : shippingType === 'expedited' ? 20 : 0 : order?.shippingCharges;


    let total = (subTotal + shippingCharges) - (order?.walletPointsUsed || 0) - (order?.afterTaxDiscount || 0);

    if (order?.promotionData?.promoDiscount) {
      total = total - order?.promotionData?.promoDiscount;
    }

    return {
      subTotal,
      shippingType,
      shippingCharges,
      total
    };

  }, [[...orderData?.products]?.length, orderData?.products, filteredProducts?.length, productPromo, order?.afterTaxDiscount]);

  useEffect(() => {
    setOrderData({ ...orderData, shippingType: cartDetails?.shippingType });
  }, [cartDetails?.shippingType]);

  useEffect(() => {
    if (!isEmptyObject(cartDetails) && JSON.stringify(cartInfo) !== JSON.stringify(cartDetails)) {
      setCartInfo({ ...cartInfo, ...cartDetails });
    }
  }, [cartDetails]);


  useEffect(() => {
    if (items?.length > 0) {
      const orderProducts = items?.map((product => {
        return { productId: product.product.productId, quantity: product?.quantity };
      }));
      setOrderData({ ...orderData, products: orderProducts, email: userData.email, memo: memo, ...order?.deliveryAddress, shippingType: order?.shippingType });
    }
  }, [items, userData, order]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      {
        orderDetailsTabFilter === 'orderProof' ? <>
          <OrderProof orderStatus={emailStatus} />
        </> :
          orderDetailsTabFilter === 'logs' ? <OrderLogs /> : <>
            <Card sx={{ p: { xs: 2, md: 3 }, mb: 3, borderRadius: 2.5, boxShadow: 'none' }}>
              <Stack direction={{ sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent='space-between' gap={2} mb={{ xs: 2, md: 4 }}>
                {!orderId && <Typography variant='h4' fontWeight='semibold' color='secondary' flex={1}>{orderId ? 'Update' : 'Add'} Order</Typography>}
                {orderId && <Typography variant='h4' fontWeight='semibold' color='secondary' flex={1}>#{orderId}</Typography>}

                <Stack direction='row' justifyContent={{ sm: 'end' }} gap={2} flex={1}>
                  <FormControl fullWidth sx={{ width: '150px' }}>
                    <InputLabel>Shipping Type</InputLabel>
                    <Select
                      className='form-select form-dropdown'
                      value={orderData?.shippingType}
                      label='Shipping Type'
                      onChange={(e) => {
                        setOrderData({ ...orderData, shippingType: cartInfo?.subTotal > 50 && e.target.value === 'standard' ? 'free' : e.target.value })
                        setCartInfo({ ...cartInfo, shippingType: cartInfo?.subTotal > 50 && e.target.value === 'standard' ? 'free' : e.target.value, shippingCharges: e.target.value === 'standard' ? 6.99 : e.target.value === 'expedited' ? 20 : 0, total: (e.target.value === 'standard' ? 6.99 : e.target.value === 'expedited' ? 20 : 0) + cartInfo?.subTotal })
                      }}
                    >
                      <MenuItem disabled value='free'>Free</MenuItem>
                      <MenuItem value='expedited'>Expedited</MenuItem>
                      <MenuItem disabled={cartInfo?.subTotal >= 100 ? true : false} value='standard'>Standard</MenuItem>
                    </Select>
                  </FormControl>
                  {
                    orderId && <FormControl fullWidth sx={{ width: '180px' }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        className='form-select form-dropdown'
                        value={emailStatus || 'awaiting_shipment'}
                        label='Status'
                        onChange={(e) => {
                          const { isValid, fieldError = {}, orderError } = validateOrderData(orderData)
                          if (addressError.current) {
                            return;
                          }
                          if (!isValid) {
                            setErrors({ errors: orderError, fieldError })
                            return;
                          }
                          setErrors([])
                          // if (e.target.value === 'cancelled' || e.target.value === 'not_returnable') {
                          //   cancelOrderDialogHandler(e.target.value)
                          //   return;
                          // }

                          if (e.target.value === 'not_returnable') {
                            cancelOrderDialogHandler(e.target.value)
                            return;
                          }
                          // if (e.target.value === 'delivery_failed' || e.target.value === 'delivery_claimed_filed') {
                          //   return;
                          // }
                          // e.target.value === 'delivery_failed' || e.target.value === 'delivery_claimed_filed' 
                          if (e.target.value === 'cancelled' || e.target.value === 'delivery_failed' || e.target.value === 'delivery_claimed_filed') {
                            dispatch(openCustomModalDialog({ modal: 'DELIVERY_ORDER_REASON', modalData: { cancelOrder, cancelType: e.target.value, refundTotal: order?.refundTotal, orderId: order?.orderId, orderDate: order?.createdDate, orderTotal: order?.total, isShipped: order?.emailStatus, shippingCharges: order?.shippingCharges } }));
                            return
                          }
                          dispatch(openCustomModalDialog({ modal: 'CHANGE_ORDER_STATUS', modalData: { updateStatusHandler: () => orderStatusChangeHandler(e) } }))
                        }}
                      >
                        {
                          orderDetailStatus?.map((order => {
                            if (order.value === 'Payment Pending') return;
                            return <MenuItem disabled={emailStatus === 'delivered' || order?.key === emailStatus} value={order.key} key={order.key}>{order.value}</MenuItem>
                          }))
                        }
                      </Select>
                    </FormControl>
                  }
                </Stack>
              </Stack>
              {
                orderId && <Paper elevation={0} style={{ borderRadius: '6px', backgroundColor: '#F2F3F7' }}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} flexWrap='wrap' alignItems={{ xs: 'start', sm: 'center' }} justifyContent='space-between' bgcolor='white' p={2}>
                    <Link to={`/members/detail/${memberId}`}>
                      <Typography variant='body2' fontSize='12px'>{userData?.fullName}</Typography>
                    </Link>
                    <Typography variant='body2' fontSize='12px'>{userData?.email}</Typography>
                    <Typography variant='body2' fontSize='12px'>{userData?.phone ? formatUsPhoneNumber(userData?.phone) : '-'}</Typography>
                    <Typography variant='body2' fontSize='12px' textTransform='capitalize'>{userData?.gender || '-'}</Typography>
                    <Box color='#24CA49' display='flex' alignItems='center'>
                      <ActiveIcon width='18px' height='18px' />
                      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Active</Typography>
                    </Box>
                    <Typography variant='body2' fontSize='12px'>{userData?.memberType}</Typography>
                    <Typography variant='body2' fontSize='12px'>{formatTimestampToDateView(userData?.createdDate)}</Typography>
                  </Stack>
                </Paper>
              }

              <Grid container spacing={4} mt={0}>
                <Grid item xs={12} xl={8}>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={9}>
                      <Typography variant='h6' mb={2}>Products</Typography>
                      <List sx={{ maxWidth: '600px', mb: 4 }}>
                        {
                          items?.map((productItem => {
                            const { productId, productImages, productName = '-', category, salePrice, unitPrice, isSalePrice = false, brandName, customWeight = '' } = productItem?.product
                              || {}
                            const { quantity = 0 } = productItem
                            return <ListItem key={productId} alignItems='center' sx={{ mb: 2.5 }}>
                              <ListItemAvatar sx={{ mr: 1.5 }} className='pro-avatar-sec'>
                                <Avatar
                                  alt='Product Name'
                                  src={productImages?.[0] || defaultProductImage}
                                  sx={{ width: 82, height: 82, borderRadius: 4 }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography variant='body2' color='secondary'>{brandName}</Typography>
                                    <Typography variant='h6' fontWeight='semibold'>{productName}</Typography>
                                    <Typography variant='body2' color='secondary'>
                                      <span className='mr-3'>Category: <span style={{ color: '#000000' }}>{category?.categoryName}</span></span>
                                      <span>Wt: <span style={{ color: '#000000' }}>{customWeight}</span></span>
                                    </Typography>
                                  </React.Fragment>
                                }
                                secondary={
                                  <React.Fragment>
                                    {
                                      !isEmptyObject(productPromo) && productPromo?.productId === productId ? <>
                                        <Typography variant='h6' fontWeight='medium' component='span' mr={1}>${(salePrice * quantity) - productPromo?.price}</Typography>

                                        <Typography variant='h6' fontWeight='medium' component='span' color='secondary' mr={1} sx={{ textDecoration: 'line-through' }}>${((salePrice * quantity) || (unitPrice * quantity))}</Typography>

                                        <Typography variant='h6' fontWeight='medium' component='span' color='success.main'>{getPercentage(salePrice * quantity, salePrice)}% Off</Typography>
                                      </> : <>
                                        <Typography variant='h6' fontWeight='medium' component='span' mr={1}>${isSalePrice ? salePrice : unitPrice}</Typography>
                                        {
                                          isSalePrice && <Typography variant='h6' fontWeight='medium' component='span' color='secondary' mr={1} sx={{ textDecoration: 'line-through' }}>${unitPrice}</Typography>
                                        }
                                        {
                                          isSalePrice && getPercentage(unitPrice, salePrice) > 0 && <Typography variant='h6' fontWeight='medium' component='span' color='success.main'>{getPercentage(unitPrice, salePrice)}% Off</Typography>
                                        }
                                      </>
                                    }
                                    {
                                      (order?.promotionData?.promoName && order?.promotionData?.promotionType?.toLowerCase() === 'product' && isSalePrice) || (!isEmptyObject(productPromo) && productPromo?.productId === productId) &&
                                      <Box mt={1}>
                                        <Chip label='Promo Applied' variant='outlined' color='warning' className='promo-chip chip-square' disabled />
                                      </Box>
                                    }
                                  </React.Fragment>
                                }
                              />
                              <Typography variant='h6' fontWeight='medium' mt={5}>x{quantity}</Typography>
                            </ListItem>
                          }))
                        }
                      </List>
                    </Grid>
                  </Grid>
                  {
                    loading && <CircularProgress size={25} />
                  }
                  {
                    !loading && orderData?.products?.map(((order, orderIdx) => {
                      let productObj = { productName: '' };
                      if (order?.productId) {
                        let product = filteredProducts?.find((_item => _item?.productId === order?.productId)) || '';
                        if (product) {
                          productObj = product
                        }
                      }
                      return <Grid key={orderIdx} container spacing={1.75} mb={3.5}>
                        <Grid item xs={12} sm={4}>
                          <Autocomplete
                            id='tags-outlined'
                            options={filteredProducts?.length > 0 ? filteredProducts?.filter((productItem => productItem?.status === 'active' 
                               && !orderData.products.some(item => item.productId === productItem.productId)
                            )) : []}
                            getOptionLabel={(option) => option?.productName}
                            filterSelectedOptions
                            onChange={(val, selectedOption) => {
                              productDataChangeHandler({ target: { name: 'productId', value: selectedOption?.productId } }, orderIdx)
                            }}
                            value={productObj || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(errors?.errors?.[orderIdx]?.productId)}
                                helperText={errors?.errors?.[orderIdx]?.productId}
                                label='Select Product'
                              />
                            )}
                          />
                        </Grid>
                        {
                          <Grid item xs={12} sm={2.25}>
                            <TextField fullWidth className='form-input' label='Price' disabled value={`$${productObj?.isSalePrice ? productObj?.salePrice : productObj?.unitPrice || '0'}`} />
                          </Grid>
                        }
                        <Grid item xs={12} sm={2.25}>
                          <TextField fullWidth error={Boolean(errors?.errors?.[orderIdx]?.quantity)} helperText={errors?.errors?.[orderIdx]?.quantity} className="form-input" value={productObj?.hasOwnProperty('totalQuantity') ? order?.quantity || '' : ''} onChange={(e) => { if ((productObj?.totalQuantity > 0 || order?.quantity !== 0 || showDisplayCount[productObj.productId] > 0)) { productDataChangeHandler(e, orderIdx, productObj, order) } }} label="Quantity" name="quantity" />
                          {
                            productObj.hasOwnProperty('totalQuantity') && <Typography fontSize={{ xs: 11, xxl: 12 }} mt={0.25} pl={1} fontWeight="medium" color="secondary" display="flex" gap="2px" whiteSpace="nowrap">
                              {orderId ? "Remaining" : ""}   Quantity: <Typography fontSize={{ xs: 11, xxl: 12 }} fontWeight="medium" color="#2E2E3A"> {showDisplayCount[productObj.productId] || showDisplayCount[productObj.productId] === 0 ? showDisplayCount[productObj.productId] || 0 : +productObj?.totalQuantity}</Typography>
                            </Typography>
                          }
                        </Grid>
                        {
                          <Grid item xs={12} sm={2.25}>
                            <TextField fullWidth className='form-input' label='Total Price' disabled value={`$${productObj?.isSalePrice ? addCommasWithToFixed(productObj?.salePrice * order?.quantity || 0) : addCommasWithToFixed(productObj?.unitPrice * order?.quantity || '')}`} />
                          </Grid>
                        }
                        {orderIdx === 0 ? <Grid item xs={12} sm={1.25} textAlign='end'>
                          {
                            orderData?.products?.length === 1 ? <IconButton onClick={addMoreProduct} color='primary' size='small' sx={{ mt: 0.5 }}>
                              <PlusIcon width='22px' height='22px' />
                            </IconButton> : <Box display='inline-flex' gap={.5} sx={{ mt: 0.5 }}>
                              <IconButton onClick={addMoreProduct} color='primary' size='small'>
                                <PlusIcon width='22px' height='22px' />
                              </IconButton>
                              <IconButton onClick={() => removeProductHandler(orderIdx)} color='error' size='small'>
                                <DeleteIcon width='22px' height='22px' />
                              </IconButton>
                            </Box>
                          }
                        </Grid> : <Grid item xs={12} sm={1.25} textAlign='end'>
                          <IconButton onClick={() => removeProductHandler(orderIdx)} color='error' size='small' sx={{ mt: 0.5 }}>
                            <DeleteIcon width='22px' height='22px' />
                          </IconButton>
                        </Grid>}
                      </Grid>
                    }))
                  }
                  <Typography variant='h6' mt={4} mb={2}>Enter Email</Typography>
                  <TextField className='form-input' name='memo' value={orderData?.email} error={Boolean(errors?.fieldError?.email)} helperText={errors?.fieldError?.email} onChange={(e) => setOrderData({ ...orderData, email: e.target.value })} fullWidth label='Enter email' />

                  <Typography variant='h6' mt={4} mb={2}>Apartment Number</Typography>
                  <TextField className='form-input' name='memo' value={orderData?.apartment || ''} error={Boolean(errors?.fieldError?.apartment)} helperText={errors?.fieldError?.apartment} onChange={(e) => setOrderData({ ...orderData, apartment: e.target.value })} fullWidth label='Apartment Number' />

                  <Typography variant='h5' fontWeight='semibold' mt={4} mb={2}>Address</Typography>
                  <Grid container spacing={2.5} mb={4.5} className='address-field'>
                    <Grid item xs={12}>
                      {gmapsLoaded && <GoogleSuggestion addressValue={orderData?.address} googleAddressErrorHandler={googleAddressErrorHandler} onClearAddress={(address) => setOrderData({ ...orderData, ...address })} addressChangeHandler={(address) => setOrderData({ ...orderData, ...address })} />}
                      {errors?.fieldError?.address && <FormHelperText error>{errors?.fieldError?.address}</FormHelperText>}
                    </Grid>
                  </Grid>
                  <Typography variant='h6' mt={4} mb={2}>Add Notes</Typography>
                  <TextField className='form-input' name='memo' value={orderData?.memo} onChange={(e) => setOrderData({ ...orderData, memo: e.target.value })} fullWidth label='Write a note' multiline rows={4} />

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={1}>
                    <MDButton
                      sx={{ minWidth: '140px' }}
                      type='button'
                      variant='contained'
                      color='primary'
                      onClick={createOrderHandler}
                      loading={isLoading || loading}
                    >
                      Save
                    </MDButton>
                    <MDButton
                      sx={{ minWidth: '140px' }}
                      type='button'
                      variant='outlined'
                      color='dark'
                      onClick={() => navigate('/orders')}
                    >
                      Cancel
                    </MDButton>
                  </Stack>
                </Grid>

                <Grid item xs={12} xl={4}>
                  <Card sx={{ px: 3, py: 2.5, borderRadius: 2.5, boxShadow: 'none', background: '#F2F3F7' }}>
                    <Typography variant='h4' mb={2}>Cart Details</Typography>
                    <List>
                      <ListItem alignItems='center' sx={{ py: 1 }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography variant='h6' fontWeight='medium' color='secondary'>Subtotal</Typography>
                            </React.Fragment>
                          }
                        />
                        <Typography variant='h5' fontWeight='semibold'>${cartDetails?.subTotal?.toFixed(2) || 0}</Typography>

                      </ListItem>

                      <ListItem alignItems='center' sx={{ py: 1 }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography variant='h6' fontWeight='medium' color='secondary'>Shipping Type</Typography>
                            </React.Fragment>
                          }
                        />
                        <Typography variant='h5' fontWeight='semibold'>{cartInfo?.shippingType ? capitalizeText(cartInfo?.shippingType) : 'Free'}</Typography>
                      </ListItem>

                      <ListItem alignItems='center' sx={{ py: 1 }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography variant='h6' fontWeight='medium' color='secondary'>Shipping Charges</Typography>
                            </React.Fragment>
                          }
                        />
                        <Typography variant='h5' fontWeight='semibold'>${cartInfo?.shippingCharges?.toFixed(2)}</Typography>
                      </ListItem>

                      <Divider sx={{ my: 1 }} />

                      <ListItem alignItems='center' sx={{ py: 1 }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography variant='h6' fontWeight='medium' color='secondary'>Total</Typography>
                            </React.Fragment>
                          }
                        />
                        <Typography variant='h5' fontWeight='bold'>${cartInfo?.subTotal + cartDetails?.shippingCharges > 0 ? (cartInfo?.subTotal + cartDetails?.shippingCharges)?.toFixed() : 0}</Typography>
                      </ListItem>
                      {
                        order?.promotionData?.promoName && order?.promotionData?.promoDiscount && order?.promotionData?.promotionType !== 'Product' &&
                        <ListItem alignItems='center' sx={{ py: 1 }}>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Box display='flex' gap={0.5}>
                                  <Typography variant='h6' fontWeight='medium' color='success.main'>{order?.promotionData?.promoName} </Typography>
                                  <Typography variant='h6' fontWeight='medium' color='secondary' >(Promo code)</Typography>
                                </Box>
                              </React.Fragment>
                            }
                          />
                          <Typography variant='h5' fontWeight='semibold' color='success.main'>-${order?.promotionData?.promoDiscount?.toFixed(2)}</Typography>

                        </ListItem>
                      }

                      {
                        order?.afterTaxDiscount > 0 && <ListItem alignItems='center' sx={{ py: 1 }}>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography variant='h6' fontWeight='medium' color='secondary'>Rewards Discount</Typography>
                              </React.Fragment>
                            }
                          />
                          <Typography variant='h6' style={{ color: '#28B446' }}>
                            -(${(parseFloat(order && order.afterTaxDiscount).toFixed(2)) || 0})
                          </Typography>
                        </ListItem>
                      }

                      {
                        order?.walletPointsUsed > 0 && <ListItem alignItems='center' sx={{ py: 1 }}>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography variant='h6' fontWeight='medium' color='secondary'>Wallet deducted amount</Typography>
                              </React.Fragment>
                            }
                          />
                          <Typography variant='h5' fontWeight='semibold' >-${order?.walletPointsUsed?.toFixed(2)}</Typography>
                        </ListItem>
                      }
                      <Divider sx={{ my: 1 }} />

                      <ListItem alignItems='center' sx={{ py: 1 }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography variant='h6' fontWeight='medium'>Total Amount Paid</Typography>
                            </React.Fragment>
                          }
                        />
                        <Typography variant='h5' fontWeight='bold'>${cartInfo?.total > 0 ? cartInfo?.total?.toFixed(2) : 0}</Typography>
                      </ListItem>
                    </List>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </>
      }

    </Box>
  );
}