import { createOrderAPI, deleteOrderAPI, fetchOrderDetailsAPI, fetchOrdersAPI, fetchMemberOrdersAPI, fetchWarrantyOrdersAPI, warrantyOrderAPI, fetchOrderProofsAPI, uploadOrderProofsAPI, removeOrderProofAPI, handleRefundDataAPI, fetchOrdersLogsAPI } from "../../redux/apis/order";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Your fetchOrders async thunk
export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchOrdersAPI(filters);
            return res;
        } catch (error) {

            return rejectWithValue(error.response?.data);
        }
    }
);

export const fechWarrantyOrders = createAsyncThunk(
    'orders/fechWarrantyOrders',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchWarrantyOrdersAPI(filters);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchMemberOrderList = createAsyncThunk(
    'orders/fetchMemberOrderList',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchMemberOrdersAPI(filters);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchSingleOrder = createAsyncThunk(
    'orders/fetchSingleOrder',
    async (orderId, { rejectWithValue }) => {
        try {
            const res = await fetchOrderDetailsAPI(orderId);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);
export const handleRefundData = createAsyncThunk(
    'orders/handleRefundData',
    async ({ data, orderId }, { rejectWithValue }) => {
        try {
            const res = await handleRefundDataAPI(data, orderId);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);
export const warrantyStatusUpdate = createAsyncThunk(
    'orders/warrantyStatusUpdate',
    async (data, { rejectWithValue }) => {
        try {
            const res = await warrantyOrderAPI(data);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const createOrder = createAsyncThunk(
    'orders/createOrder',
    async ({ data, orderId }, { rejectWithValue }) => {
        try {
            const res = await createOrderAPI(data, orderId);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const deleteOrder = createAsyncThunk(
    'orders/deleteOrder',
    async (orderId, { rejectWithValue }) => {
        try {
            const res = await deleteOrderAPI(orderId);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchOrderProofs = createAsyncThunk(
    'orders/fetchOrderProofs',
    async (orderId, { rejectWithValue }) => {
        try {
            const res = await fetchOrderProofsAPI(orderId);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const uploadOrderProofs = createAsyncThunk(
    'orders/uploadOrderProofs',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await uploadOrderProofsAPI(payload);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const deleteOrderProof = createAsyncThunk(
    'orders/deleteOrderProof',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await removeOrderProofAPI(payload);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchOrdersLogs = createAsyncThunk(
    'orders/fetchOrdersLogs',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await fetchOrdersLogsAPI(payload);
            return res;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

// Create a slice for orders
const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        isLoading: false, // Flag to track loading status,
        uploadingProof: false,
        deletingProof: false,
        error: null,
        order: {},
        orderProofs: {},
        isUpdatingStatus: false,
        isAddLoading: false,
        loading: false
    },
    reducers: {
        // You can define additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
                state.order = {}
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.orders = action.payload; // Update orders with the fetched data
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Update error in case of failure
            });
        builder
            .addCase(fechWarrantyOrders.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
                state.order = {}
            })
            .addCase(fechWarrantyOrders.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.orders = action.payload; // Update orders with the fetched data
            })
            .addCase(fechWarrantyOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Update error in case of failure
            });

        builder
            .addCase(warrantyStatusUpdate.pending, (state) => {
                state.isUpdatingStatus = true; // Set isLoading to true when fetching starts
            })
            .addCase(warrantyStatusUpdate.fulfilled, (state, action) => {
                state.isUpdatingStatus = false;
            })
            .addCase(warrantyStatusUpdate.rejected, (state, action) => {
                state.isUpdatingStatus = false;

            });
        builder
            .addCase(createOrder.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.isLoading = false;
                // Handle updating state after successful batch creation if needed
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteOrder.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteOrder.fulfilled, (state, action) => {
                state.isLoading = false;
                // Handle updating state after successful deletion if needed
            })
            .addCase(deleteOrder.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        builder.addCase(fetchSingleOrder.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });

        // When the fetchSingleOrder is fulfilled (success)
        builder.addCase(fetchSingleOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.order = action.payload.orderDetails;
        });

        // When the fetchSingleOrder is rejected (failure)
        builder.addCase(fetchSingleOrder.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(fetchOrderProofs.pending, (state) => {
            state.isLoading = true;
            state.error = null;
            state.fetchOrderProofs = []
        });

        // When the fetchOrderProofs is fulfilled (success)
        builder.addCase(fetchOrderProofs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orderProofs = action.payload?.orderPackageProof;
        });

        // When the fetchOrderProofs is rejected (failure)
        builder.addCase(fetchOrderProofs.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder
            .addCase(uploadOrderProofs.pending, (state) => {
                state.uploadingProof = true; // Set uploadingProof to true when fetching starts
            })
            .addCase(uploadOrderProofs.fulfilled, (state, action) => {
                state.uploadingProof = false; // Set uploadingProof to false when fetching completes
            })
            .addCase(uploadOrderProofs.rejected, (state, action) => {
                state.uploadingProof = false;
            });

        builder
            .addCase(deleteOrderProof.pending, (state) => {
                state.deletingProof = true; // Set deletingProof to true when fetching starts

            })
            .addCase(deleteOrderProof.fulfilled, (state, action) => {
                state.deletingProof = false; // Set deletingProof to false when fetching completes
            })
            .addCase(deleteOrderProof.rejected, (state, action) => {
                state.deletingProof = false;
            });
        builder
            .addCase(handleRefundData.pending, (state) => {
                state.isAddLoading = true; // Set deletingProof to true when fetching starts

            })
            .addCase(handleRefundData.fulfilled, (state, action) => {
                state.isAddLoading = false; // Set deletingProof to false when fetching completes
            })
            .addCase(handleRefundData.rejected, (state, action) => {
                state.isAddLoading = false;
            });
        builder
            .addCase(fetchOrdersLogs.pending, (state) => {
                state.loading = true; // Set deletingProof to true when fetching starts

            })
            .addCase(fetchOrdersLogs.fulfilled, (state, action) => {
                state.loading = false; // Set deletingProof to false when fetching completes
                state.logsDetails = action.payload;

            })
            .addCase(fetchOrdersLogs.rejected, (state, action) => {
                state.loading = false;
            });

    },
});

export const { /* any additional actions */ } = ordersSlice.actions;
export default ordersSlice.reducer;
