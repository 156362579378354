import { Box, Typography } from "@mui/material";
import ActiveIcon from "common/customIcons/active";
import ClaimIcon from "common/customIcons/claimfiled";
import FailedIcon from "common/customIcons/failed";
import InActiveIcon from "common/customIcons/inactive";
import InProgressIcon from "common/customIcons/inprogress";

export const statusObj = {
    delivered: <Box color='#24CA49' display='flex' alignItems='center'>
      <ActiveIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Delivered</Typography>
    </Box>,
    shipped: <Box color='#24CA49' display='flex' alignItems='center'>
      <ActiveIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Shipped</Typography>
    </Box>,
    cancelled: <Box color='#FD4438' display='flex' alignItems='center'>
      <InActiveIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Cancelled</Typography>
    </Box>,
    not_returnable: <Box color='#FD4438' display='flex' alignItems='center'>
      <InActiveIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Un-Returnable</Typography>
    </Box>,
    awaiting_shipment: <Box color='#8E0CF5' display='flex' alignItems='center'>
      <InProgressIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>In Progress</Typography>
    </Box>,
    on_hold: <Box color='#FF7A2F' display='flex' alignItems='center'>
      <InProgressIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>On Hold</Typography>
    </Box>,
    delivery_failed: <Box color='#FD4438' display='flex' alignItems='center'>
      <FailedIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Delivery Failed</Typography>
    </Box>,
    delivery_claimed_filed: <Box color='#FF7A2F' display='flex' alignItems='center'>
      <ClaimIcon width='18px' height='18px' flex='none' />
      <Typography variant='caption' fontWeight='regular' ml={.5} textTransform='capitalize'>Delivery Claimed Filed</Typography>
    </Box>,
  
  };