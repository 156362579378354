import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMember, fetchMembers } from '../../redux/slices/member';
import { Badge, Box, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Paper, Slide, Stack, Tooltip, Typography } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { formatDate } from 'utilities/common';
import { RemoveRedEyeRounded } from '@mui/icons-material';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search';
import { useDebounce } from 'hooks/useDebounce';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import StatusText from 'common/component/StatusText';
import { openSnackbar } from '../../redux/slices/common';
import StaticsOverview from 'common/StaticsOverview';
import InActiveIcon from 'common/customIcons/inactive';
import ActiveIcon from 'common/customIcons/active';
import { addSpaceBeforeCapital } from 'utilities/common';
import { formatUsPhoneNumber } from 'utilities/common';
import VerifyIcon from 'common/customIcons/verify';
import NonVerifyIcon from 'common/customIcons/non_verify';
import MembersIcon from 'common/customIcons/members';
import UserGuestIcon from 'common/customIcons/user_guest';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import Zoom from 'react-medium-image-zoom';
import { formatTimestampTo12HourTime } from 'utilities/common';
import { getImageExtension } from 'utilities/common';
// import { MemberBirthday } from 'common/memberBadges';
// import { NewMember } from 'common/memberBadges';
// import { VipMember } from 'common/memberBadges';


const uploadThumbnailImage = {
  borderRadius: '20px',
  width: '100px',
  height: '100px',
  objectFit: 'cover',
  boxShadow: '0 0 1px rgb(0 0 0 / 20%)',
  marginRight: '10px'
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function Members() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [notesData, setNotesData] = useState('');

  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  const { members = {}, loading = false } = useSelector(state => state.members);

  const { activeMembersCount = 0, inactiveMembersCount = 0, totalMembers = 0 } = members || {};

  const deleteHandler = (memberId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteMember(memberId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      pageRef.current = 0;
      getRows(gridRefApi.current);
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const columnDefs = [
    {
      headerName: 'Full Name',
      field: 'fullName',
      key: 'fullName',
      suppressMenu: false,
      cellRenderer: (props) => {
        // const { isBirthdayToday = false, newMember = false, vipMember = false } = props?.data?.tags || {}
        return <>
          <Link style={{ color: 'black' }} to={`/members/detail/${props?.data?.memberId}`}>
            <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: 'pointer', whiteSpace: 'normal', textTransform: 'capitalize', color: 'black' }}>{props?.data?.fullName}</Typography>
          </Link>
          {/* {
            props?.data?.fullName && <div className="ml-2 d-flex align-items-center justify-content-start gap-1 cus-badge">
              {isBirthdayToday && <MemberBirthday />} {newMember && <NewMember />} {vipMember && <VipMember />}
            </div>
          } */}
        </>
      },
      minWidth: 240,
    },
    {
      headerName: 'Email',
      field: 'email',
      suppressMenu: false,
      minWidth: 340,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      suppressMenu: false,
      minWidth: 130,
      cellRenderer: (props) => {
        return props?.value ? formatUsPhoneNumber(props?.value) : '-';
      },
    },
    {
      headerName: 'Gender',
      field: 'gender',
      suppressMenu: false,
      minWidth: 80,
      cellRenderer: (props) => {
        return props?.value ? <span style={{ textTransform: 'capitalize' }}>{props?.value?.toLowerCase()}</span> : '-';
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />;
      },
      minWidth: 100
    },
    {
      headerName: 'Member Type',
      field: 'memberType',
      suppressMenu: false,
      minWidth: 120,
      cellRenderer: (props) => {
        return props?.value ? addSpaceBeforeCapital(props?.value) : '-';
      },
      minWidth: 120
    },


    {
      headerName: 'Guest User',
      field: 'guestCheckout',
      suppressMenu: false,
      minWidth: 100,
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      headerClass: 'center-aligned-cell-header',
      cellRenderer: (props) => {
        if (!props?.data?.id) return;
        return props?.value ? <Box display="flex" justifyContent="center"><Icon fontSize="small" color='success'>
          <UserGuestIcon width="20px" height="20px" />
        </Icon></Box> : <Box display="flex" justifyContent="center">-</Box>;
      },
    },
    {
      headerName: 'User Verified',
      field: 'isVerified',
      suppressMenu: false,
      minWidth: 110,
      cellRenderer: (props) => {
        if (!props?.data?.id) return;
        return props?.value ? <Box display="flex" justifyContent="center"><Icon sx={{ display: 'inline-flex' }} fontSize="small" color='primary'>
          <VerifyIcon />
        </Icon></Box> : <Box display="flex" justifyContent="center"><Icon fontSize="small" color='error' sx={{ opacity: .75 }}>
          <NonVerifyIcon />
        </Icon></Box>;
      },
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      headerClass: 'center-aligned-cell-header',
    },
    {
      headerName: 'Notes',
      field: 'notes',
      suppressMenu: false,

      // autoHeight: true,
      cellRenderer: (props) => {
        return props?.data?.memberId && props?.data?.memberNotes ? <MDButton onClick={() => {
          setNotesData(props?.data);
        }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
          View Notes
        </MDButton> : '-';
      },
      minWidth: 140,
    },
    {
      headerName: 'Joined Date',
      field: 'joinedDate',
      suppressMenu: false,
      minWidth: 150
    },
    {
      headerName: 'Actions',
      cellRenderer: (props) => {
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onEditClicked(props);
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.memberId, deleteType: 'member' }, modal: 'DELETE_MODAL' }));
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
      pinned: 'right'
    },

  ];

  function getSortInfo(data) {
    const result = {};
    data.forEach(item => {
      if (item?.colId === 'memberType') {
        result[item.colId] = item.sort === 'asc' ? 'AdultUse' : 'MedicinalUser';
      } else {
        result[item.colId] = item.sort === 'asc';
      }

    });

    return result;
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    let filter = {};
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) };
    }
    dispatch(fetchMembers({ skip: pageRef.current, limit: 20, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
      const { members, total } = res;
      const membersArray = members?.map((item => {
        const date = item?.createdDate ? new Date(item?.createdDate) : '';
        return { ...item, status: item?.status ? 'Active' : 'Inactive', joinedDate: date ? formatDate(date) : '-', id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` };
      }));
      params.successCallback([...membersArray], total);
      pageRef.current = pageRef.current + 20;
    });
  };

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  };

  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== 'Actions' && row?.colDef?.headerName !== 'Notes') {
      navigate(`/members/detail/${row?.data.memberId}`);
    }
  };

  const onEditClicked = (row) => {
    navigate(`/members/${row?.data.memberId}`);
  };

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value;
    handleSearch(value);

    // Debounce the search callback
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (members?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [members, loading]);

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Paper elevation={0} style={{ borderRadius: '12px' }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="start" justifyContent="space-between" bgcolor="white" p={{ xs: 1, md: 2 }}>
            <StaticsOverview
              color="warning"
              iconShadow="iconShadowWarning"
              icon={<MembersIcon />}
              title="Total Members"
              count={totalMembers}
            />
            <StaticsOverview
              color="success"
              iconShadow="iconShadowSuccess"
              icon={<ActiveIcon />}
              title="Active Members"
              count={activeMembersCount}
            />
            <StaticsOverview
              color="error"
              iconShadow="iconShadowDanger"
              icon={<InActiveIcon />}
              title="Inactive Members"
              count={inactiveMembersCount}
            />
          </Stack>
        </Paper>

        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Members</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
              <MDButton
                startIcon={<AddRoundedIcon fontSize='large' />}
                variant="contained" color="primary"
                onClick={() => navigate('/members/add')} >
                Add Member
              </MDButton>
            </Stack>
          </Grid>
        </Grid>

        <AgGridTable maxLimit={20} col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} onRowClicked={onRowClicked} style={{ height: '58vh' }} />
      </Box>
      <Dialog open={Boolean(notesData)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="notes-modal"
        onClose={() => setNotesData('')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
          Ban Member
          <IconButton onClick={() => setNotesData('')} edge='end' size='small'>
            <CloseIcon fontSize='medium' />
          </IconButton>
        </DialogTitle>

        <DialogContent className="notes-modal-body">
          <Typography color={grey[600]} sx={{ wordBreak: 'break-word' }} fontSize='14px'>{notesData?.memberNotes || '-'}</Typography>
          <Box mt={2}>
            <Typography variant='h6' fontWeight="semibold" mb={1.5}>Attachments</Typography>
            {notesData?.notesAttachment?.length > 0 ?
              <>
                {
                  notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) !== 'pdf'))?.length > 0 && <>
                    <Typography sx={{ wordBreak: 'break-word' }} fontSize='14px' fontWeight='semibold' mb={0.5}>Image</Typography>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      {notesData?.notesAttachment && notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) !== 'pdf'))?.map(((image, index) => <Zoom key={index} classDialog='custom-zoom'><img key={index} style={uploadThumbnailImage} src={image} alt="notesAttachment" /></Zoom>))}

                    </Badge>
                  </>
                }
                {
                  notesData?.notesAttachment && notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) === 'pdf'))?.length > 0 && <Box mt={2}>
                    <Typography sx={{ wordBreak: 'break-word' }} fontSize='14px' fontWeight='semibold'>PDF Links</Typography>
                    {
                      notesData?.notesAttachment && notesData?.notesAttachment?.filter((noteImage => getImageExtension(noteImage) === 'pdf'))?.map((pdfUrl => {
                        return <Tooltip key={pdfUrl} title={pdfUrl} arrow placement="top"><Box mr={2} className='text-truncate'>{pdfUrl}</Box></Tooltip>;
                      }))
                    }
                  </Box>
                }

              </>
              : '-'
            }
          </Box>
          <Stack direction='row' justifyContent='space-between' gap={4} alignItems='center' mt={2}>
            <Box>
              <Typography fontSize='14px' fontWeight="semibold" >Time of Ban</Typography>
              <Typography fontWeight='semibold' fontSize='18px' color='#656575'>{notesData?.notesTime ? formatTimestampTo12HourTime(notesData?.notesTime) : ''}</Typography>
            </Box>
            <Box>
              <Typography fontSize='14px' fontWeight="semibold" >Employee Name</Typography>
              <Typography fontWeight='semibold' fontSize='18px' color='#656575'>{notesData?.memberNotesEmployee}</Typography>
            </Box>
          </Stack>

        </DialogContent>
      </Dialog>
    </CommonLayout>
  );
}