export const validateCannabinoids = (data) => {
  const errors = {};
  // Validation for terpeneName
  if (!data?.cannabinoidName?.trim()) {
    errors.cannabinoidName = 'Cannabinoid name is required';
  } else if (data.cannabinoidName.length > 255) {
    errors.cannabinoidName = 'Cannabinoid name should be at most 255 characters';
  }

  // Validation for createdBy
  if (!data.createdBy?.trim()) {
    errors.createdBy = 'Created by is required';
  } else if (data.createdBy.length > 255) {
    errors.createdBy = 'Created by should be at most 255 characters';
  }

  // Validation for image (assuming it's a file object)
  if (!data.listImage) {
    errors.listImage = 'List image is required';
  }
  if (!data.detailImage) {
    errors.detailImage = 'Detail image is required';
  }

  if (!data.title) {
    errors.title = 'Title is required';
  }

  if (!data?.metaDescription || data?.metaDescription.trim() === '') {
    errors.metaDescription = 'Meta description is required.';
  }
  if (!data?.metaTitle || data?.metaTitle.trim() === '') {
    errors.metaTitle = 'Meta title is required.';
  }

  // Check if any errors occurred
  const isValid = Object.keys(errors).length === 0;

  return { isValid, errors };
};

export default validateCannabinoids;


function validateTerpene(terpeneObject) {
  const errors = {};
  let isValid = true;

  // Validate terpeneName
  if (!terpeneObject.terpeneName || terpeneObject.terpeneName?.trim() === '') {
    errors.terpeneName = 'Terpene name is required.';
    isValid = false;
  }

  // Validate terpeneValue
  if (!terpeneObject.terpeneValue || terpeneObject.terpeneValue?.trim() === '') {
    errors.terpeneValue = 'Terpene value is required.';
    isValid = false;
  }

  return { errors, isValid };
}

export function validateTerpeneArray(terpeneArray) {
  let errors = {};
  let isValid = true;

  for (let i = 0; i < terpeneArray.length; i++) {
    const terpeneObject = terpeneArray[i];
    const { errors: objectErrors, isValid: objectIsValid } = validateTerpene(terpeneObject, i);

    if (!objectIsValid) {
      errors = {
        ...errors,
        [i]: objectErrors
      }
      isValid = false;
    }
  }
  return { errors, isValid };
}

export function validateMainCannabinoid(cannabinoidData) {
  const {
    title,
    metaTitle,
    metaDescription,
    description,
  } = cannabinoidData;

  const errors = {};

  if (!metaDescription || metaDescription.trim() === '') {
    errors.metaDescription = 'Meta description is required.';
  }
  if (!metaTitle || metaTitle.trim() === '') {
    errors.metaTitle = 'Meta title is required.';
  }
  if (!title || title.trim() === '') {
    errors.title = 'Title is required.';
  }

  if (!description || description.trim() === '') {
    errors.description = 'Description is required.';
  }

  // Check if there are any errors
  const isValid = Object.keys(errors).length === 0;

  // Return an object with isValid and errors
  return {
    isValid,
    errors,
  };
}