const { isEmptyObject } = require('utilities/common');

export function productValidations(product) {
    const errors = {};


    // Validate productName
    if (!product.productName) {
        errors.productName = 'Product name is required';
    }
   
    if (product?.ingredientStatus && !product?.ingredients) {
        errors.ingredients = 'Product Ingredients is required';
    }

    if (product?.instructionStatus && !product?.instructions) {
        errors.instructions = 'Product Instructions is required';
    }

      if (product?.salePrice === '0') {
        errors.salePrice = 'Sale price cannot be zero';
    }

    if ((product?.unitPrice && product?.salePrice) && +product?.salePrice > +product?.unitPrice) {
        errors.salePrice = 'Sale price cannot be greater than unit price';
    }

     return { errors, isValid: isEmptyObject(errors) };
}
