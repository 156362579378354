import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DoughnutChart({ data, chartInnerContent = '' }) {
  return <>
    <div style={{ position: 'relative' }}>
      <Doughnut style={{ position: 'relative', zIndex: 1 }} data={data} options={{
        cutout: 20,
        spacing: 2,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#fdfdfd',
            bodyColor: '#16161E',
            titleColor: '#16161E',
            borderWidth: 1,
            borderColor: '#F1F1F1',
            padding: 8
          },
        },
        elements: {
          arc: {
            borderWidth: 2,
            borderColor: 'rgba(255, 255, 255, 0.8)',
            hoverBorderColor: 'rgba(255, 255, 255, 1)',
            hoverBorderWidth: 3,
            // Customize the box shadow effect
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowOffsetX: 0,
            shadowOffsetY: 4,
            shadowBlur: 8,
            // Customize the inner shadow effect
            innerShadowColor: 'rgba(0, 0, 0, 0.1)',
            innerShadowOffsetX: 0,
            innerShadowOffsetY: 2,
          }
        }
      }} />
      {
        chartInnerContent && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          {
            chartInnerContent
          }
        </div>
      }
    </div>
  </>;
}